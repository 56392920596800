.o-split-content {
	&,
	&.slick-slide {
		position: relative;
		width: 100%;

		@include breakpoint($bp-small) {
			align-items: stretch;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-start;
		}
	}

	&__image {
		display: block;
		margin-left: -$spacing-base;
		margin-right: -$spacing-base;
		position: relative;
		width: 100%;
		width: calc(100% + #{$spacing-base * 2});

		@include breakpoint($bp-small) {
			margin-left: -$spacing-base;
			margin-right: 0;
			position: static;
			width: calc(
				50% - (2.7027% / 2) + #{$spacing-base}
			); // Half width minus half the gutter, plus the extra overlap on left
		}

		@include breakpoint($bp-medium) {
			width: calc(50% - (2.7027% / 2) + #{$spacing-medium});
		}

		@include breakpoint($bp-large) {
			margin-left: -8.55856%; // One column plus gutter
			width: calc(50% - (2.7027% / 2) + 8.55856%);
		}
	}

	&__text {
		position: relative;

		@include breakpoint($bp-small) {
			height: inherit;
			padding-left: 2.7027%; // Gutter width
			width: 50% + (2.7027% / 2); // Half plus half the gutter
		}
	}

	&__text-container {
		@include breakpoint($bp-small) {
			display: table;
			height: 100%;
			table-layout: fixed;
			width: 100%;
		}
	}

	&__text-wrapper {
		@include breakpoint($bp-small) {
			display: table-cell;
			overflow: hidden;
			vertical-align: middle;
			width: 100%;
		}
	}

	&__pagination {
		display: block;
		left: $spacing-base;
		position: absolute;
		top: $spacing-small;

		@include breakpoint($bp-small) {
			bottom: 0;
			top: auto;
		}

		@include breakpoint($bp-large) {
			bottom: toRems(50px);
			left: (2.30769% * 2);
		}
	}

	/**
	 * Split Content Carousel
	 * Adjust some padding to make the slide transitions look nicer.
	 */

	&--carousel {
		margin-bottom: 0;

		@include breakpoint($bp-small) {
			margin-bottom: toRems(80px);
		}

		@include breakpoint($bp-large) {
			margin-bottom: toRems(30px);
		}

		.o-split-content__text {
			margin-left: -$spacing-base;
			margin-right: -$spacing-base;

			@include breakpoint($bp-small) {
				margin-left: 0;
				margin-right: 0;
				padding-left: 0;
			}

			@include breakpoint($bp-large) {
				padding-right: 0; // Two columns plus gutter
			}
		}

		.o-split-content__text-container {
			@include breakpoint($bp-small) {
				padding-bottom: 0;
			}

			@include breakpoint($bp-medium) {
				padding-bottom: toRems(80px);
			}

			@include breakpoint($bp-large) {
				padding-bottom: toRems(50px);
			}
		}

		.o-split-content__text-wrapper {
			@include breakpoint($bp-small) {
				padding-bottom: $spacing-medlarge;
			}
		}

		.o-split-content__pagination {
			@include breakpoint($bp-small) {
				bottom: toRems(15px);
			}

			@include breakpoint($bp-medium) {
				bottom: $spacing-large;
			}

			@include breakpoint($bp-large) {
				bottom: $spacing-medlarge;
			}
		}
	}
}
