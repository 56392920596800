/*
Style buttons inside TNEW7 and its popups
*/

.modal,
.s-tnew-v7-content {
  a,
  button,
  .tn-account-login-forgot-login-link {
    color: $c-black;
    text-decoration: underline;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
  }

  .btn:not(.glyphicon) {
    @include button-base;
    text-decoration: none;
    z-index: 0; // So fancy background is visible

    .no-touchevents & {
      // Fix hover bug
      &:hover,
      &.tn-syos-screen-container__button--seat-map-hover-active {
        background-color: transparent;
        &:after {
          height: 100%;
        }
      }
      &.tn-syos-screen-container__button--seat-map-hover-active {
        color: $c-black;
      }
    }

    // White background version
    &.btn-info,
    &.btn-default {
      &,
      &:link,
      &:visited {
        background-color: $c-white;
        border-color: $c-black;
        color: $c-black;
        z-index: 0; // So fancy background is visible in popups

        // Only apply fancy styling if on a non-touch device
        .no-touchevents & {
          &:hover {
            background-color: transparent;
          }

          &:before {
            background-color: $c-white;
            content: "";
          }

          &:after {
            background-color: $c-black;
          }
        }
      }

      &:hover,
      &:active,
      &:focus {
        background-color: $c-black;
        color: $c-white;

        // Only apply fancy styling if on a non-touch device
        .no-touchevents & {
          background-color: transparent;
          color: $c-white;
        }
      }
    }

    // Small version
    &.tn-toggle-btn,
    &.tn-flex-seating-notes__btn-edit,
    &.btn-sm {
      font-size: $font-small;
      min-height: 0;
      padding: toRems(5px);
    }

    // Tottle button
    &.tn-toggle-btn {
      padding-right: toRems(50px);

      .tn-toggle-btn__icon {
        border: none;
        color: inherit;
      }
    }
  }

  .tn-cart__btn-remove,
  .modal-header button.close,
  .tn-modal__btn-close {
    background: none;
    border: none;
  }

  .tn-cart__btn-remove-icon,
  .modal-header button.close,
  .tn-modal__btn-close {
    &:before,
    &:after {
      height: toRems(30px);
    }
  }
}
