.c-icon-expand {
	display: block;
	height: toRems(24px);
	overflow: hidden;
	pointer-events: none;
	text-indent: -100%;
	width: toRems(24px);

	svg {
		display: block;
		fill: $c-font;
		height: toRems(24px);
		width: toRems(24px);
	}


	&--light {
		svg {
			fill: $c-white;
		}
	}

	&--right {
		position: absolute;
		right: $spacing-base;
		top: 50%;
		transform: translateY(-50%);
	}

	&--inline {
		display: inline-block;
		font-size: 0;
		height: toRems(26px);
		line-height: 0;
		pointer-events: all;
		text-indent: 0;
		vertical-align: middle;
		width: auto;

		svg {
			display: inline-block;
			height: toRems(26px);
			vertical-align: middle;
		}
	}
}
