/*
Modal TNEW7 popups
*/

.modal {
  .modal-content {
    border-radius: 0;
    border: none;
    box-shadow: none;
  }

  .modal-header {
    border-bottom: toRems(4px) solid $c-black;
  }

  .modal-footer {
    border-top: none;
    justify-content: space-between;

    .btn + .btn {
      margin-left: 0;
    }
  }

  .modal-backdrop.in {
    opacity: 0.9;
  }
}