/*
  TNEW7 toggle preview mode banner
*/

.tn-admin-preview-banner {
  z-index: 9999;
  position: absolute;
  right: 50%;

  &__heading {
    display: none;
  }

  &__pencil-toggle > .btn {
    font-size: 12px !important;
    padding: toRems(5px) !important;
    min-height: 0 !important;
    margin-right: toRems(5px) !important;
  }
}
