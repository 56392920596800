@mixin width-wrapper() {
	margin: 0 auto;
	max-width: $max-site-width;
	width: 100%;
}

@mixin width-wrapper-padding() {
	@include breakpoint($bp-xxlarge) {
		padding-left: $spacing-base;
		padding-right: $spacing-base;
	}
}

.o-width-wrapper {
	@include width-wrapper;

	&--padding {
		@include width-wrapper-padding;
	}
}
