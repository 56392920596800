.list {

    .list-title {
        border-top-style: solid;
        border-top-width: 2px;
        border-bottom-style: solid;
        border-bottom-width: 2px;
        width: 100%;
        margin-top: -2px; // To prevent overlapping borders
        cursor: pointer;
        position: relative;

        .list-arrow {
            background-color: white;
            height: 1.5rem;
            width: 2rem;
            display: block;
            position: absolute;
            border-left: 2px solid black;
            border-bottom: 2px solid black;
            border-right: 2px solid black;
            top: 0px;
            right: 0px;
            align-items: center;

            svg {
                fill: black;
                width: 1.5rem;
                height: 1rem;
                display: block;
                margin: 0.25rem auto 0.25rem auto;
                vertical-align: middle;
            }
        }
    }

    .list-items {
        column-count: 3;
        padding-top: 10px;
        padding-bottom: 20px;
        line-height: normal;

        br {
            display: block;
            margin: 15px 0;
            content: " ";
        }
    }

    .list-description,  .list-intro {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    h3 {
        font-weight: 700;
        font-size: 1.25rem;
        width: 80%;
    }

    &.is-visible {
        .list-title {
            border-bottom-style: none;

            .list-arrow {
                background-color: black;

                svg {
                    fill: white;
                    transform: rotate(180deg);
                }
            }
        }
    }
}