.c-search {
	background-color: $c-black;
	height: 100%;
	opacity: 0;
	pointer-events: none;
	position: fixed;
	top: 0;
	transition: opacity 600ms $global-easing 0s;
	width: 100%;
	z-index: $z-header-menu;

	.no-flexbox & {
		display: none; // Required for IE10
	}

	@include breakpoint($bp-desktop-nav) {
		background-color: transparentize($c-black, .1);
		z-index: $z-search;
	}

	.has-active-search & {
		opacity: 1;
		pointer-events: all;
	}

	.no-flexbox.has-active-search & {
		display: block; // Required for IE10
	}

	&__container {
		@include width-wrapper;
		height: 100%;
		height: 100vh;
		margin: 0 auto;
		position: relative;
	}

	&__field {
		left: 50%;
		margin: 0;
		max-width: $max-content-width;
		position: absolute;
		top: toRems(125px);
		transform: translate(-50%, -50%);
		transform-origin: center center;
		width: 100%;
		width: calc(100% - #{$spacing-base * 2});

		@include breakpoint($bp-desktop-nav) {
			top: 50%;
		}

		&:before {
			background-color: palette(blue-grey);
			content: "";
			display: block;
			height: toRems(2px);
			left: 50%;
			position: absolute;
			top: toRems(67px);
			transform-origin: left center;
			transform: translate(-50%, -50%) scaleX(0);
			transition: transform 600ms $global-easing 0s;
			width: 100%;

			@include breakpoint($bp-desktop-nav) {
				height: toRems(8px);
				top: toRems(240px);
			}

			.has-active-search & {
				transform: translate(-50%, -50%) scaleX(1);
			}
		}

		&:after {
			@include description;
			background-color: transparent;
			content: "Hit enter to search or ESC to close";
			color: palette(blue-grey);
			display: block;
			height: toRems(35px);
			opacity: 0;
			position: absolute;
			right: 0;
			text-align: right;
			top: toRems(260px);
			transform-origin: left center;
			transition: opacity 600ms $global-easing 250ms;
			width: 100%;

			.has-active-search & {
				@include breakpoint($bp-desktop-nav) {
					opacity: 1;
				}
			}
		}

		label {
			@include visually-hidden;
		}

		input {
			background-color: transparent;
			border-width: 0;
			color: palette(blue-grey);
			font-size: $font-size-7;
			font-weight: $font-weight-medium;
			opacity: 0;
			padding: $spacing-small 0;

			@include breakpoint($bp-desktop-nav) {
				font-size: $font-size-1;
			}

			.has-active-search & {
				opacity: 1;
				transition: opacity 600ms $global-easing 0s;

				@include breakpoint($bp-desktop-nav) {
					transition-delay: 300ms;
					transition-duration: 850ms;
				}
			}

			@include placeholder {
				color: palette(blue-grey);
				font-size: $font-size-7;
				font-weight: $font-weight-medium;

				@include breakpoint($bp-desktop-nav) {
					font-size: $font-size-1;
				}
			}
		}
	}

	&__submit {
		right: -$spacing-base;
		margin: 0;
		max-width: $max-content-width;
		position: absolute;
		top: toRems(125px);
		transform: translate(-50%, -50%);
		transform-origin: center center;

		@include breakpoint($bp-desktop-nav) {
			@include visually-hidden;
			right: 0;
			top: 50%;
		}
	}

	&__close {
		position: absolute;
		right: toRems(5px);
		top: toRems(7px);

		@include breakpoint($bp-desktop-nav) {
			right: 0;
			top: 0;
		}

		svg {
			fill: palette(blue-grey);
		}
	}
}

.has-active-search {
	#main {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;

		@include breakpoint($bp-desktop-nav) {
			position: static;
			left: auto;
			top: auto;
		}
	}
}
