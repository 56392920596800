.whats-on-filter {
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  transition: top .35s cubic-bezier(.11,.74,.46,.96);
  z-index: 30;

  &.nav-open {
    top: 70px;
  }

  button {
    white-space: nowrap;
  }

  .filter-container {
    margin-right: 0;
    padding: 0;
    transition: width .35s cubic-bezier(.11,.74,.46,.96);
    width: 165px;
  }

  .icon {
    margin-right: 0.625rem;

    svg {
      fill: $c-white;
      height: 15px;
      width: 15px;
      position: relative;
      top: 1px;

      &.settings-icon {
        top: 2px;
        height: 24px;
        width: 24px;
      }
    }
  }


  .c-accordion__trigger {
    align-items: center;
    align-self: flex-end;
    color: $c-white;
    display: flex;
    font-size: .875rem;
    justify-content: center;
    text-align: right;
    width: 165px;
  }

  .c-accordion__drawer {
    opacity: 0;
    padding: $spacing-base;
    transition: opacity .35s cubic-bezier(.11,.74,.46,.96);
  }

  .filter-container.is-visible .c-accordion__drawer {
    opacity: 1;
  }

  .close-icon {
    display: none;
  }

  .settings-icon {
    display: inline-block;
  }

  .filter-container.is-visible {
    width: 100%;

    .close-icon {
      display: inline-block;
    }

    .settings-icon {
      display: none;
    }
  }

  .c-accordion {
    display: flex;
    flex-direction: column;
  }

  .o-split-grid {
    min-height: 0;
  }

  @include breakpoint($bp-medium) {
    .filter-container {
      margin-right: $spacing-base;
    }

    .c-accordion__drawer {
      max-height: 197px;
      padding-bottom: $spacing-large;
    }
  }

  @include breakpoint($bp-desktop-nav) {
    &.nav-open {
      top: 120px;
    }
  }
}