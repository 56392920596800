.c-audio-player {
	margin-bottom: $spacing-large;
	margin-top: $spacing-large;

	@include breakpoint($bp-medium) {
		margin-bottom: $spacing-xlarge;
		margin-top: $spacing-xlarge;
	}

	iframe {
		display: block;
		width: 100%;
	}
}
