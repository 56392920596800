// Colour palletes
$palettes: (
	yellow: (
		base: #f6dd8f,
	),
	pink: (
		base: #f8c0d5,
	),
	blue-grey: (
		base: #dcdde6,
	),
	bright-blue: (
		base: #aaf0ff,
	),
	red: (
		base: #ee3030,
	),
	grey: (
		light: #b0b0b0,
		mid-light: #9e9e9e,
		mid-mid-light: #888888,
		base: #3b3b3b,
	),
	errors: (
		base: #e00026,
	),
	border: (
		light: #d6d8da,
		base: #000,
	),
);

/**
 * Alpha colour palletes
 *
 * Used with the following:
 * color: alpha-palette(white, 50%);
 * Default tone is 100%, always include this value.
 */

$alpha-palettes: (
	white: (
		0%: rgba(255, 255, 255, 0),
		20%: rgba(255, 255, 255, .2),
		30%: rgba(255, 255, 255, .3),
		50%: rgba(255, 255, 255, .5),
		95%: rgba(255, 255, 255, .95),
		100%: rgba(255, 255, 255, 1),
	),
	black: (
		0%: rgba(0, 0, 0, 0),
		15%: rgba(0, 0, 0, .15),
		25%: rgba(0, 0, 0, .25),
		30%: rgba(0, 0, 0, .3),
		50%: rgba(0, 0, 0, .5),
		60%: rgba(0, 0, 0, .6),
		75%: rgba(0, 0, 0, .75),
		80%: rgba(0, 0, 0, .80),
		90%: rgba(0, 0, 0, .9),
		100%: rgba(0, 0, 0, 1),
	),
);

// Global colors
$c-white: #fff;
$c-black: #000;
$c-dark-grey: #444;
$c-link: $c-black;
$c-link-hover: $c-black;
$c-link-visited: $c-black;
$c-font: $c-black;
$c-placeholder: palette(grey, mid-light);
$c-border: $c-black;

// Text highlight colors
$text-highlight-color: $c-font;
$text-highlight-bg-color: palette(blue-grey);
$tap-highlight-color: rgba(255, 255, 255, 0);

// Social colors
$c-facebook: #3b5998;
$c-google-plus: #dd4b39;
$c-instagram: #125688;
$c-linked-in: #007bb5;
$c-messenger: #0084ff;
$c-pinterest: #cb2027;
$c-twitter: #00aced;
$c-youtube: #bb0000;
