/*
TNEW7 subnavigation menu
*/

.s-tnew-v7-content {
  .tn-subnav-component {
    bottom: toRems(5px);
    justify-content: flex-end;
    position: absolute;
    right: toRems(5px);
    top: toRems(5px);

    .tn-login-link,
    .tn-icon,
    .tn-logout-link:before,
    .tn-logged-in-text,
    .tn-cart-link .tn-link {
      display: none !important;
    }

    .tn-logout-link {
      display: inline-block !important;
    }

    .tn-login-link.tn-logged-in {
      display: inline-block !important;
      margin-left: 0;
    }

    .tn-account-info-link {
      text-decoration: none !important;
    }

    .tn-cart-link {
      flex: 0;
      order: -1;
    }

    .tn-cart-link,
    .tn-login-link {
      align-self: start;
      opacity: 0.5;

      &:hover,
      &:focus {
        opacity: 1;
      }
    }
    
    .tn-login-link.tn-logged-in .tn-logged-in-email {
      margin-left: 8px;
    }

    .tn-cart-link .tn-timer, 
    .tn-icon, 
    .tn-login-link.tn-logged-in .tn-logged-in-email, 
    .tn-login-link.tn-logged-in .tn-logged-in-text, 
    .tn-text,
    #tn-subnav-promo-button,
    #tn-subnav-promo-code {
      font-size: inherit;
    }

    .tn-promo-box {
      margin-right: 0;
    }

    #tn-apply-promo {
      bottom: toRems(-20px);
      display: flex;
      position: absolute;
      right: toRems(-20px);
    }

    .tn-subnav-promo-code {
      max-width: toRems(150px);

      &:focus,
      &:active {
        &,
        &:placeholder-shown {
          background: $c-white !important;
          border-color: $c-black !important;
          text-align: left !important;
        }
      }

      &:placeholder-shown + button {
        display: none;
      }
    }
  }
}
