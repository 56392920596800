.o-flexible-card-grid {
	align-content: flex-start;
	align-items: flex-start;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	overflow: visible;
	width: auto;

	@include breakpoint($bp-small) {
		margin: 0 (-1.35135%);
	}

	&__item {
		display: inline-block;
		flex: 0 0 100%;

		@include breakpoint($bp-small) {
			flex-basis: 50%;
			width: 50%;
		}

		@include breakpoint($bp-medium) {
			flex-basis: 33.33333%;
			width: 33.33333%;
		}

		// Every first item in a row of three
		&:nth-child(1) {
			@include breakpoint($bp-medium) {
				margin-top: toRems(60px);
			}
		}

		// Every second item in a row of three
		&:nth-child(2) {
			@include breakpoint($bp-small) {
				margin-top: toRems(60px);
			}

			@include breakpoint($bp-medium) {
				margin-top: toRems(120px);
			}
		}

		// Hide third item between small-medium view.
		&:last-child {
			.o-flexible-card-grid__content {
				padding-bottom: 0;
			}

			@include breakpoint($bp-small) {
				display: none;
			}

			@include breakpoint($bp-medium) {
				display: inline-block;
			}
		}
	}

	&__content {
		height: 100%;
		padding: 0 0 $spacing-medium;

		@include breakpoint($bp-small) {
			padding: 0 2.7027% (2.7027% * 2);
		}

		@include breakpoint($bp-medium) {
			padding-bottom: 4.05405% * 2; // Gutter margin multiplied by 3 (as the item is 33% width) divided by two (to split on left and right)
			padding-left: 4.05405%;
			padding-right: 4.05405%;
		}
	}

	/**
	 * Masonry Grid - Three columns
	 */

	&--masonry {
		margin-bottom: toRems(50px);

		.o-flexible-card-grid__item {
			@include breakpoint($bp-small) {
				flex-basis: 50%;
				width: 50%;
			}

			@include breakpoint($bp-medium) {
				flex-basis: 33.33333%;
				width: 33.33333%;
			}

			// First item in first row of three
			&:nth-child(1) {
				@include breakpoint($bp-small) {
					margin-top: toRems(0);
				}
			}

			// Second item in first row of three
			&:nth-child(2) {
				@include breakpoint($bp-small) {
					margin-top: toRems(50px);
				}

				@include breakpoint($bp-medium) {
					margin-top: toRems(150px);
				}
			}

			// Third item in first row of three
			&:nth-child(3) {
				display: inline-block;

				@include breakpoint($bp-small) {
					margin-top: 0;
				}

				@include breakpoint($bp-medium) {
					margin-top: toRems(50px);
				}
			}

			// Hide third item between small-medium view.
			&:last-child {
				@include breakpoint($bp-small) {
					display: inline-block;
				}
			}
		}
	}

	/**
	 * Masonry Grid - Two columns
	 */

	&--two-col-masonry {
		.o-flexible-card-grid__item {
			@include breakpoint($bp-small) {
				flex-basis: 50%;
				width: 50%;
			}

			@include breakpoint($bp-medium) {
				flex-basis: 50%;
				width: 50%;
			}

			// First item in first row of two
			&:nth-child(1) {
				@include breakpoint($bp-small) {
					margin-top: toRems(0);
				}
			}

			// Second item in first row of two
			&:nth-child(2) {
				@include breakpoint($bp-small) {
					margin-top: toRems(175px);
				}
			}

			// Third item in first row of three
			&:nth-child(3) {
				display: inline-block;
			}

			&:nth-child(2n) {
				@include breakpoint($bp-small) {
					padding-right: 0;
				}
			}
		}

		.o-flexible-card-grid__content {
			@include breakpoint($bp-medium) {
				padding-bottom: $spacing-medlarge;
				padding-left: 2.7027%;
				padding-right: 2.7027%;
			}
		}
	}
}
