@mixin flexible-column {
	@include breakpoint($bp-medium) {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		height: 100%;
		justify-content: center;
	}
}

@mixin flexible-column-item {
	@include breakpoint($bp-medium) {
		flex: 0 0 auto;
	}
}

.o-flexible-column {
	@include flexible-column;

	&__item {
		@include flexible-column-item;
	}
}
