.u-1\/1 {
	@include grid-full;
}

.u-1\/2 {
	@include grid-half;
}

.u-1\/4 {
	@include grid-quarter;
}

.u-1\/12 {
	@include span-columns(1);
}

/**
 * @bp-medium
 */

.u-1\/2\@medium {
	@include breakpoint($bp-medium) {
        @include span-columns(6);
    }
}

.u-1\/4\@medium {
	@include breakpoint($bp-medium) {
        @include span-columns(3);
    }
}

.u-3\/4\@medium {
	@include breakpoint($bp-medium) {
        @include span-columns(9);
    }
}

.u-1\/3\@medium {
	@include breakpoint($bp-medium) {
        @include span-columns(4);
    }
}

.u-2\/3\@medium {
	@include breakpoint($bp-medium) {
        @include span-columns(4);
    }
}

/**
 * @bp-large
 */

.u-1\/2\@large {
    @include breakpoint($bp-large) {
        @include span-columns(6);
    }
}

.u-1\/4\@large {
    @include breakpoint($bp-large) {
        @include span-columns(3);
    }
}

.u-3\/4\@large {
    @include breakpoint($bp-large) {
        @include span-columns(9);
    }
}

.u-1\/3\@large {
    @include breakpoint($bp-large) {
        @include span-columns(4);
    }
}

.u-2\/3\@large {
    @include breakpoint($bp-large) {
        @include span-columns(4);
    }
}

.u-grid-last.u-grid-last {
	@include omega;
}
