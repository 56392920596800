/*
TNEW7 busy indicator
*/

.s-tnew-v7-content {
  // Loading state
  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
  }

  &:before {
    background: $c-white;
    box-sizing: content-box;
    height: 100%;
    left: -100%;
    padding-top: toRems(50px);
    top: toRems(-50px);
    width: 200%;
    z-index: 99;
  }

  &:after {
    height: toRems(60px);
    left: 50%;
    margin-left: toRems(-30px);
    top: toRems(100px);
    width: toRems(60px);
    z-index: 100;
  }

  & > * {
    opacity: 0;
  }

  &.s-tnew-v7-initialised {
    &:before,
    &:after {
      display: none;
    }
    & > * {
      opacity: 1;
    }
  }

  .tn-flex-busy-indicator__background {
    background: $c-white;
    opacity: 0.9;
  }

  .tn-flex-performance__busy-indicator {
    background: $c-white;
  }

  .tn-syos-busy-indicator__content,
  .tn-flex-busy-indicator__content,
  .tn-flex-performance__busy-indicator__content,
  & {
    background: none;
    border: none;
    box-shadow: none;

    &:after {
      animation: loader 1.1s infinite linear;
      background: none;
      border-color: $c-black $c-black $c-black $c-white;
      border-radius: 50%;
      border-style: solid;
      border-width: toRems(4px);
      font-size: toRems(10px);
    }
  }

  .tn-syos-busy-indicator__message {
    display: none;
  }
}
