.c-table {
	margin-left: -$spacing-base;
	margin-right: -$spacing-base;
	overflow: hidden;
	padding: 0;
	position: relative;
	width: auto;
	z-index: $z-tile-content;

	@include breakpoint($bp-small) {
		position: static;
	}

	@include breakpoint($bp-medium) {
		width: 100%;
		width: calc(100% + #{$spacing-base * 2});
	}

	&:after {
		box-shadow: 0 0 25px 0 rgba(0, 0, 0, .5);
		content: "";
		display: block;
		height: 100%;
		position: absolute;
		right: toRems(-50px);
		top: 0;
		width: toRems(50px);
		z-index: $z-overlay;

		@include breakpoint($bp-small) {
			display: none;
		}
	}

	// Break out of grid (one column on left and right wider than grid) if in narrow wrapper
	.o-narrow &,
	.o-content-sidebar__main & {
		margin: $spacing-medium (-$spacing-base);

		@include breakpoint($bp-medium) {
			margin: toRems(60px) (-(((5.85586% + 2.7027%) * 2) / .657657658)); // column width + column gutter width / divided by ratio of narrow grid to normal grid size
			padding-left: 0;
			padding-right: 0;
			width: calc(100% + #{(((5.85586% + 2.7027%) * 2) / .657657658) * 2});
		}
	}

	&__container {
		background-color: $c-white;
		overflow: auto;
		padding-left: $spacing-base;
		padding-right: $spacing-base;
		width: 100%;
	}

	table {
		min-width: 100%;
		min-width: calc(100% + #{toRems(20px)});

		@include breakpoint($bp-small) {
			min-width: 100%;
		}

		@include breakpoint($bp-medium) {
			min-width: 100%;
			width: 100%;
		}
	}

	th,
	td {
		@include body-copy;
		border: toRems(2px) solid palette(pink);
		min-width: toRems(130px);
		padding: $spacing-small $spacing-small;
		text-align: left;

		@include breakpoint($bp-medium) {
			padding: $spacing-small $spacing-base;
		}
	}

	thead {
		background-color: palette(pink);
	}

	tbody {
		td {

		}
	}
}

table {
	td, th {
		padding: 10px;
	}
}