.s-tnew-content {
	opacity: 1;
	transition: opacity 300ms $global-easing 0s;

	@include breakpoint($bp-medium) {
		transition-duration: 500ms;
	}

	/**
	 * Colours
	 * TNEW uses classes to change colours on elements in some cases. Here
	 * we're just overriding them to match the site's existing colours.
	 */

	.c1 {
		color: $c-font;
	}
	.c2 {
		color: #253c6a;
	}
	.c3 {
		color: $c-font;
	}
	.c4 {
		color: #e2e3e3;
	}
	.w {
		color: $c-white;
	}
	.b {
		color: $c-black;
	}

	/**
	 * Buttons
	 */

	.btnStyle {
		@include button-base;

		// These styles are overriding inline styles applied to some elements
		&,
		&:link,
		&:visited {
			background-color: $c-link !important;
			color: $c-white !important;
			font-size: $font-size-7 !important;
		}

		&:hover,
		&:active,
		&:focus {
			background-color: $c-white !important;
			color: $c-black !important;

			&:disabled {
				background-color: $c-link !important;
				color: $c-white !important;
			}
		}
	}

	/**
	* Table
	*/

	#cartTable,
	#cart-table {
		@include body-copy;
		font-size: 14px;
		background-color: $c-white;

		th,
		td {
			@include body-copy;
			font-size: 14px;
			color: $c-font;
		}

		thead {
			background-color: palette(pink);
		}

		// WARNING! The worst selectors ever seen are coming your way

		> tbody > tr > td,
		> tbody > tr > td > table > tbody > tr > td {
			padding: 0;
		}

		> tbody > tr > td > table > tbody > tr > td > table {
			width: 100%;

			th,
			td {
				border: toRems(2px) solid palette(pink);
				min-width: toRems(130px);
				padding: $spacing-small $spacing-small;
				text-align: left;

				@include breakpoint($bp-medium) {
					padding: $spacing-small $spacing-base;
				}
			}
		}

		> tbody > tr > td {
			// Wrapping table (why)

			> table {
				width: 100%;

				> tbody > tr > td {
					// This seems to just be used for a grouping / heading

					> table > tbody > tr {
						// The actual cart table

						// Header row that just uses regular <td>s instead of <th>
						&:first-of-type {
							> td {
								@include body-copy;
								font-size: 14px;
								background-color: palette(pink) !important;
							}
						}

						> td > table > tbody > tr > td {
							// Table within a cell of the cart table. Sigh
							border-width: 0;
							padding: 0;
						}
					}
				}
			}
		}
	}

	// Cart table in SYOS flow
	#cart-table {
		> tbody > tr,
		> head > tr {
			> th,
			> td {
				border: toRems(2px) solid palette(pink);
				min-width: toRems(130px);
				padding: $spacing-small $spacing-small;
				text-align: left;

				@include breakpoint($bp-medium) {
					padding: $spacing-small $spacing-base;
				}
			}
		}
	}
}

.s-tnew-content {
	/**
	 * Colours
	 * TNEW uses classes to change colours on elements in some cases. Here
	 * we're just overriding them to match the site's existing colours.
	 */

	.c1 {
		color: #f15e48;
	}
	.c2 {
		color: $c-black;
	}
	.c3 {
		color: $c-font;
	}
	.c4 {
		color: #e2e3e3;
	}
	.w {
		color: $c-white;
	}
	.b {
		color: $c-black;
	}

	font[size='4'] {
		@include body-copy;
	}

	font[color='#6F6F6F'] {
		color: $c-font;
	}

	.bgC1 {
		background-color: palette(pink);
		color: $c-black;
	}
	.bgC2 {
		background-color: #253c6a;
	}
	.bgC3 {
		background-color: $c-black;
	}
	.bgC4 {
		background-color: #e2e3e3;
	}
	.bgW {
		background-color: $c-white;
	}
	.bgB {
		background-color: $c-black;
	}

	/**
	 * Fonts
	 * TNEW specifically sets these to 'Arial, Helvetica, sans-serif'
	 * rather than having them inherit the page's styling.
	 */

	.smaller,
	.small,
	.medium,
	.large,
	.larger,
	.huge,
	.syos_facility_desc,
	.titleofevent,
	#detailed-events,
	#detailed-events li.display-date,
	#performance-detail {
		-moz-osx-font-smoothing: auto;
		-moz-osx-font-smoothing: grayscale;
		-webkit-backface-visibility: hidden;
		-webkit-font-smoothing: antialiased !important;
		color: $c-font;
		font-family: $base-font-family;
		font-size: $base-font-size;
		font-weight: $base-font-weight;
		line-height: $base-line-height;
	}

	.small {
		@include small-copy;
	}

	.medium {
		@include body-copy;
	}

	.large {
		@include heading-4;
	}

	.larger {
		@include heading-3;
	}
	.huge {
		@include heading-2;
	}

	.syos_facility_desc {
		font-size: 16px;
		font-weight: 700;
	}

	.titleofevent {
		font-size: 16px;
		font-weight: 700;
	}

	#detailed-events {
		font-size: 12px;
	}

	#detailed-events li.display-date {
		font-size: 12px;
	}

	#performance-detail {
		font-size: 16px;
		font-weight: 700;
	}

	/**
	 * General Overrides
	 */

	table td {
		color: $c-font;
	}

	hr {
		background-color: transparent !important;
		border-width: 0;
		border-bottom: toRems(4px) solid $c-black;
	}

	p {
		color: $c-font;
	}

	/**
	 * Text Links
	 */

	a {
		@include text-link;
	}

	/**
	 * Borders
	 */

	.bLeft {
		border-left: toRems(4px) solid $c-border;
	}

	.bRight {
		border-right: toRems(4px) solid $c-border;
	}

	.bTop {
		border-top: toRems(4px) solid $c-border;
	}

	.bBottom {
		border-bottom: toRems(4px) solid $c-border;
	}

	.bAll {
		border: 1px solid $c-border;
	}

	/**
	 * Body text
	 */

	.TNEW_page_block {
		@include body-copy;
		color: $c-font;
	}

	/**
	 * Buttons
	 */

	.btnStyle {
		@include button-base;

		// These styles are overriding inline styles applied to some elements
		&,
		&:link,
		&:visited {
			background-color: $c-link !important;
			color: $c-white !important;
			font-size: $font-size-7 !important;
		}

		&:hover,
		&:active,
		&:focus {
			background-color: $c-white !important;
			color: $c-black !important;

			&:disabled {
				background-color: $c-link !important;
				color: $c-white !important;
			}
		}
	}

	.btnStyleInactive {
		@include button-base;
		opacity: 0.7;

		&:hover,
		&:active,
		&:focus {
			background-color: $c-link !important;
			color: $c-white !important;
		}
	}

	/**
	 * Text input, drop down fields
	 */

	.txtBox {
		@include form-text-input;
		display: inline-block;
		width: auto;
	}

	.txtBoxDisabled {
		@include form-text-input;
		background-color: alpha-palette(black, 15%);
		cursor: not-allowed;
		display: inline-block;
		opacity: 0.5;
	}

	/**
	 * Select (Dropdown)
	 */

	select,
	select.txtBox {
		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: none;
		background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MDAgNTAwIj48cGF0aCBkPSJNMjUwIDUwMGwyNy44LTI3LjggMTk2LjgtMTk2LjgtMjcuOS0yNy44LTE3NyAxNzcuMVYwaC0zOS40djQyNC43bC0xNzctMTc3LjEtMjcuOSAyNy44IDE5Ni44IDE5Ni44eiIvPjwvc3ZnPg==')
			no-repeat right $spacing-xsmall center scroll $c-white; // White-filled chevron-down icon converted to base-64 using http://b64.io/
		background-size: toRems(24px);
		border: toRems(4px) solid $c-black;
		border-radius: $global-radius;
		box-shadow: none;
		color: $c-font;
		cursor: pointer;
		display: inline-block;
		font-family: $circular;
		font-size: 16px;
		font-weight: $font-weight-normal;
		height: toRems(60px);
		line-height: 1;
		min-width: toRems(85px);
		padding: toRems(14px) $spacing-large toRems(14px) $spacing-small;
		position: relative;

		&:focus {
			outline: none;
		}

		// Hide native arrow in IE
		&::-ms-expand {
			display: none;
		}

		option {
			color: $c-font;

			&:disabled {
				color: palette(grey, mid-light);
			}
		}
	}

	select.dropDown {
		@include breakpoint($bp-large) {
			background: $c-white;
			height: auto;
			min-height: toRems(300px);
			padding: 0;

			option {
				padding: $spacing-xxsmall $spacing-xsmall;
			}
		}
	}

	/**
	 * Checkboxes
	 */

	input[type='checkbox'] {
		display: inline-block;
		height: toRems(30px);
		margin: 0 $spacing-xsmall 0 0;
		position: relative;
		vertical-align: middle;
		width: toRems(30px);

		&:before {
			background: $c-white;
			border-radius: 0;
			border: toRems(4px) solid $c-black;
			content: '.';
			cursor: pointer;
			height: toRems(30px);
			left: 0;
			transform: translateY(-50%);
			position: absolute;
			text-indent: -999em;
			top: 50%;
			width: toRems(30px);
		}

		&:after {
			background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MDAgNTAwIj48cGF0aCBkPSJNNDg3LjkgODMuNGMtMTYuMi0xNi4yLTQyLjUtMTYuMi01OC43IDBMMTg0IDMyOC42IDcwLjggMjE1LjNjLTcuOC03LjgtMTguMy0xMi4yLTI5LjMtMTIuMi0xMS4xIDAtMjEuNSA0LjMtMjkuMyAxMi4yQy00IDIzMS41LTQgMjU3LjggMTIuMSAyNzRsMTQyLjYgMTQyLjZjNy44IDcuOCAxOC4zIDEyLjIgMjkuMyAxMi4yIDExLjEgMCAyMS41LTQuMyAyOS4zLTEyLjJsMjc0LjUtMjc0LjVjMTYuMi0xNi4yIDE2LjItNDIuNS4xLTU4Ljd6Ii8+PC9zdmc+')
				no-repeat center center scroll transparent;
			background-size: toRems(16px);
			color: $c-black;
			content: '';
			display: block;
			height: toRems(16px);
			left: toRems(7px);
			opacity: 0;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			transition: opacity 350ms $global-easing 0s;
			width: toRems(16px);
		}

		&:checked {
			&:before {
				// background-color: $c-white;
			}

			&:after {
				color: $c-white;
				opacity: 1;
			}
		}
	}

	/**
	 * Required indicator
	 */

	.TNEW_error_required,
	.TNEW_required {
		color: palette(errors);
	}

	/**
	 * Wrappers
	 */

	#tnew-site-cell-wrapper {
		color: $c-font;
		padding: 0;
	}

	/**
	 * TNEW Login links bar
	 */

	#tnew-site-loginwidget-wrapper {
		display: block;
		font-size: 0 !important;
		text-align: center;

		// Nav links
		.login-link-container {
			// background-color: palette(pink);
			display: inline-block;
			margin-bottom: $spacing-large;
			margin-left: auto;
			padding: $spacing-base;

			a {
				&,
				&:link,
				&:visited {
					display: inline-block;
					font-size: $font-size-7 !important;
					margin: 0 $spacing-small;
				}
			}
		}

		// Hide the promo code form
		.promoctrl-form-container {
			display: inline-block;
			margin-bottom: $spacing-large;
			padding: $spacing-base;

			input[type='text'] {
				display: inline-block;
				font-size: $font-size-8 !important;
				min-width: 140px !important;
				padding: toRems(5px) toRems(15px) !important;
				vertical-align: middle;
				width: 100% !important;

				@include breakpoint($bp-small) {
					width: 140px !important;
				}
			}

			.promoctrl-form-imgbtn {
				@include button-base;
				display: inline-block;
				font-size: $font-size-8 !important;
				min-height: 48px !important;
				padding-bottom: 10px !important;
				padding-top: 10px !important;
				vertical-align: middle;

				&:hover,
				&:focus,
				&:active {
					// Only apply fancy styling if on a non-touch device
					.no-touchevents & {
						background-color: transparent;

						&:after {
							height: 100%;
						}

						&:disabled {
							&:after {
								height: 0;
							}
						}
					}
				}
			}
		}
	}

	/**
	* Table
	*/

	#cartTable {
		@include body-copy;
		background-color: $c-white;

		th,
		td {
			@include body-copy;
			color: $c-font;
		}

		thead {
			background-color: palette(pink);
		}

		// WARNING! The worst selectors ever seen are coming your way

		> tbody > tr > td,
		> tbody > tr > td > table > tbody > tr > td {
			padding: 0;
		}

		> tbody > tr > td > table > tbody > tr > td > table {
			width: 100%;

			th,
			td {
				border: toRems(2px) solid palette(pink);
				min-width: toRems(130px);
				padding: $spacing-small $spacing-small;
				text-align: left;

				@include breakpoint($bp-medium) {
					padding: $spacing-small $spacing-base;
				}
			}
		}

		> tbody > tr > td {
			// Wrapping table (why)

			> table {
				width: 100%;

				> tbody > tr > td {
					// This seems to just be used for a grouping / heading

					> table > tbody > tr {
						// The actual cart table

						// Header row that just uses regular <td>s instead of <th>
						&:first-of-type {
							> td {
								@include body-copy;
								background-color: palette(pink) !important;
							}
						}

						> td > table > tbody > tr > td {
							// Table within a cell of the cart table. Sigh
							border-width: 0;
							padding: 0;
						}
					}
				}
			}
		}
	}

	/**
	 * Tabs
	 * First bunch of selectors is from jQuery UI
	 */

	.ui-tabs {
		float: none;
		list-style-type: none;
		overflow: auto;
		padding: 0.2em;
		zoom: 1;
	}

	.ui-tabs .ui-tabs-nav {
		font-size: 0;
		list-style: none;
		position: relative;
		padding: 0;
		text-align: center;
	}

	.ui-tabs .ui-tabs-nav li {
		// float: left;
		border-bottom-width: 0 !important;
		display: inline-block;
		font-size: $font-size-7;
		margin: 0;
		padding: 0;
		position: relative;
	}

	.ui-tabs .ui-tabs-nav li a {
		float: left;
		text-decoration: none;
		padding: 0.5em 1em;
	}

	.ui-tabs .ui-tabs-nav li.ui-tabs-selected {
		padding-bottom: 0;
		border-width: 0 !important;
	}

	.ui-tabs .ui-tabs-nav li.ui-tabs-selected a,
	.ui-tabs .ui-tabs-nav li.ui-state-disabled a,
	.ui-tabs .ui-tabs-nav li.ui-state-processing a {
		cursor: default;
	}

	.ui-tabs .ui-tabs-nav li a,
	.ui-tabs.ui-tabs-collapsible .ui-tabs-nav li.ui-tabs-selected a {
		cursor: pointer;
	}

	.ui-tabs .ui-tabs-panel {
		padding: 1em 1.4em;
		display: block;
		border-width: 0;
		background: none;
	}

	.ui-tabs .ui-tabs-hide {
		display: none !important;
	}

	// Overrides
	.tab,
	.ui-tabs .ui-tabs-nav li {
		background: transparent;
		border-width: 0;
		margin: 0 $spacing-small;
		padding: 0;

		a {
			margin: 0;
			padding: 0;
		}

		&.ui-tabs-selected a {
			&,
			&:link,
			&:visited {
				background-image: none;
				border-width: 0 !important;
				text-decoration: none;
			}
		}
	}

	// Tab Title
	.tabDivTitle {
		@include heading-2;
		border-bottom-width: 0;
		margin: $spacing-large 0 $spacing-medium;
	}

	// Account field containers
	.tnew-register-field-container-top,
	.tnew-register-field-container-bottom,
	.ainer,
	.tnew-shortreg-field-container-bottom {
		width: 100%;

		.tdFormLabel {
			min-width: toRems(250px);
			width: 25%;

			+ td {
				input {
					display: inline-block;
					width: 90% !important;
				}
			}
		}
	}

	/**
	 * Contribute/Donate
	 */

	.contrib {
		label {
			@include form-label;
			color: $c-font;
			margin-left: $spacing-xsmall;
			margin-right: $spacing-base;
		}
	}

	/**
	 * Checkout
	 */

	// Checkout Progress Indicator
	.TNEW_steps_block {
		margin-bottom: $spacing-medium;

		.TNEW_steps {
			border: none;
			display: table;
			width: auto;

			> li {
				@include body-copy;
				border: none;
				color: palette(grey, mid-mid-light);
				display: table-cell;
				font-size: $font-size-8;
				padding-right: toRems(8px);

				&.TNEW_step_active {
					color: $c-font;
				}
			}
		}

		.TNEW_steps_msg {
			@include body-copy;
			display: block;
		}
	}

	// Subheading
	.TNEW_subheader_bar {
		@include heading-3;
		background-color: transparent;
		margin: $spacing-xlarge 0 $spacing-medium !important;
	}

	// Donate inline field label
	.tnew-donate-control-field-label {
		display: inline;
	}

	.tnew-donate-control-container {
		@include body-copy();
		color: $c-font;

		ul li,
		ol li {
			color: $c-font;
		}
	}

	// Delivery address fields
	#tnew-shipping-address-control {
		max-width: 840px;
		width: 100%;

		.tdFormLabel {
			min-width: toRems(250px);
			width: 25%;

			+ td {
				input {
					display: inline-block;
					width: 90% !important;
				}
			}
		}
	}

	// Order Note
	.tnew-ordernote-control-container {
		max-width: 840px;
		width: 100%;

		textarea {
			display: block;
			width: 100%;
		}

		.tnew-ordernote-control-field {
			margin-top: $spacing-base;
		}
	}

	.tnew-ordernote-control-title {
		@include body-copy();
		color: $c-font;
	}

	/**
	 * Checkout Review
	 */

	.TNEW_subheader_bar {
		color: $c-font;
	}

	// Cart Table (doesn't have an id or unique class so we need to be messy here)
	.TNEW_page_block > table[width='95%'] {
		@include body-copy;
		background-color: $c-white;

		th,
		td {
			@include body-copy;
			color: $c-font;
		}

		thead {
			background-color: palette(pink);
		}

		// WARNING! The worst selectors ever seen are coming your way

		> tbody > tr > td {
			padding: $spacing-base 0;
		}

		> tbody > tr > td > table {
			width: 100%;

			th,
			td {
				border: toRems(2px) solid palette(pink);
				min-width: toRems(130px);
				padding: $spacing-small $spacing-small;
				text-align: left;

				@include breakpoint($bp-medium) {
					padding: $spacing-small $spacing-base;
				}
			}
		}

		> tbody > tr > td {
			// Wrapping table (why)

			> table {
				width: 100%;

				> tbody > tr {
					// The actual cart table

					// Header row that just uses regular <td>s instead of <th>
					&:first-of-type {
						> td {
							@include body-copy;
							background-color: palette(pink) !important;
						}
					}

					> td > table > tbody > tr > td {
						// Table within a cell of the cart table. Sigh
						border-width: 0;
						padding: 0;
					}
				}
			}
		}
	}

	// Gift Voucher form wrapper
	.tnew-giftcert-control-redeem-container {
		background-color: transparent;
	}

	// Gift Voucher Found Info
	#TNEW_redeemGCFound {
		border: none;
	}

	.tnew-giftcert-control-found-container {
		border-bottom-width: 0;
		padding: $spacing-base 0;
	}

	.tnew-giftcert-control-found-label,
	.tnew-giftcert-control-found-jid,
	.tnew-giftcert-control-found-balance,
	.tnew-giftcert-control-lookup-found,
	.tnew-giftcert-control-lookup-link,
	.tnew-giftcert-control-lookup-apply-amount,
	.tnew-giftcert-control-amount-label-container {
		display: inline;
	}

	.tnew-giftcert-control-amount-label-container {
		color: $c-font;
		padding-right: $spacing-base;
	}

	// Payment Plan Options
	.tnew-paymentplan-payment-plan-billingschedules {
		margin: $spacing-xsmall 0;
	}

	.tnew-paymentplan-payment-plan-billingschedule {
		margin-bottom: $spacing-xsmall;

		input[type='radio'] {
			margin-right: $spacing-xxsmall;
		}
	}

	// CC Payment Info
	.TNEW_payment_block {
	}

	.payment-form {
		ol {
			display: flex;
			flex-flow: row wrap;
			margin: 0 (-$spacing-xsmall);

			> li {
				flex: 0 0 33.3333%;
				padding: 0 $spacing-xsmall;
			}
		}

		label {
			color: $c-font;
			display: block;
			margin-bottom: $spacing-xsmall;
			width: 100%;
		}

		input,
		select {
			display: block;
			width: 100%;
		}

		.card-expiration {
			select {
				display: inline-block;
				width: 45%;
			}
		}
	}

	/**
	 * Error Messages
	 */

	.errDiv {
		@include body-copy;
		border-color: palette(errors);
		border-style: solid;
		border-width: toRems(4px) 0;
		color: palette(errors);
		padding: $spacing-base 0;
		margin: 0 0 $spacing-base;

		li {
			margin: 0 0 $spacing-small;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.errorLabel {
		@include body-copy;
		background-color: transparent;
		color: palette(errors);
		margin: 0 0 $spacing-large;

		li {
			margin-bottom: 0;
		}
	}

	/**
	 * Account Info
	 */

	.tab_pagePad {
		padding-top: $spacing-base;
	}

	#mainContent_divAllForms {
		table {
			width: 100%;
		}

		input[type='text'],
		input[type='password'],
		input[type='number'],
		input[type='email'],
		select {
			min-width: 90%;
		}
	}

	.tdFormLabel {
		min-width: toRems(150px);
		width: 25%;
	}

	// Account History
	#accountHistory {
		a[onclick] {
			&,
			&:link,
			&:visited {
				background: none;
				display: block;
				margin-top: $spacing-large !important;
				width: 100%;
			}

			&:hover,
			&:active,
			&:focus {
			}
		}
	}

	#headers_order,
	#headers_contrib,
	#headers_member {
		> table {
			width: 100% !important;

			> thead {
				th,
				td {
					@include body-copy;
					background-color: palette(pink) !important;
					border-width: 0;
					color: $c-font;
					padding: $spacing-small $spacing-small;
					text-align: left;

					@include breakpoint($bp-medium) {
						padding: $spacing-small $spacing-base;
					}
				}
			}
		}

		+ table {
			width: 100%;
		}
	}

	/**
	 * Calendar
	 */

	#calendar {
		.fc-header {
			display: none;
		}
	}

	.tn-calendar__container {
		display: none;
	}

	#detailed-events {
		width: 100%;

		* {
			display: none;
		}

		&:before {
			@include body-copy;
			display: block;
			content: "Please go to 'Concerts & Tickets' in the menu above to see the performance calendar.";
			opacity: 1;
		}
	}

	/**
	 * Subscriptions
	 */
	.WASO-Pkg {
		td {
			padding: 30px 0 30px 0;

			&:first-child {
				// Image cell
				padding: 30px 30px 30px 0 !important;
				width: 230px;

				img {
					display: block !important;
					height: auto !important;
					margin: 0 !important;
					padding: 0 !important;
					width: 200px !important;
				}
			}
		}
	}

	#WASO-Subs-Purchase {
		> tbody > tr {
			> td[valign='top'] {
				padding: $spacing-medium $spacing-medium $spacing-medium 0;
				width: toRems(230px);

				img {
					display: block;
					height: auto;
					width: 100% !important;
				}
			}
			&:nth-child(2n) {
				> td {
					padding-top: 30px;
				}
			}
			&:nth-child(2n + 1) {
				> td {
					padding-bottom: 30px;
				}
			}
		}

		.large {
			@include body-copy();
		}

		b.c2 {
			@include heading-3();
		}

		.tnew_priceselectordd_wrapper {
			margin-bottom: $spacing-base;
		}
	}

	// Subscription packages selector
	.tnew-cyoperf-selection-container {
		.tnew-cyoperf-perf-container,
		.tnew-cyoperf-selection-container {
			select {
				border: toRems(4px) solid $c-border;

				option {
					padding: 4px 5px;
				}
			}
		}

		// Buttons
		.tnew-cyoperf-button-container {
			.tnew-cyoperf-add-button-container,
			.tnew-cyoperf-drop-button-container,
			.tnew-cyoperf-moreinfo-button-container {
				input {
					min-width: toRems(200px);
				}
			}

			.tnew-cyoperf-add-button-container {
				margin-bottom: $spacing-small;
			}

			.tnew-cyoperf-drop-button-container {
				margin-bottom: $spacing-base;
			}

			.tnew-cyoperf-moreinfo-button-container {
				br {
					display: none;
				}
			}
		}
	}

	// Modal for 'View Perf Details' button in selector, hide by default
	.dialog {
		display: none;
	}

	/**
	 * Register
	 */

	.tnew-register-field-container-bottom,
	.tnew-register-field-container-top {
		max-width: 840px;

		> tbody > tr > td {
			padding-bottom: 15px;
			vertical-align: middle;
		}
	}

	.tnew-register-button-container {
		text-align: center;

		input[type='submit'] {
			@include breakpoint($bp-medium) {
				min-width: toRems(250px);
			}
		}
	}

	.tnew-register-required-container {
		@include body-copy();
	}

	/**
	 * Login
	 */

	.tnew-login-control-container {
		max-width: 840px;
	}

	.tnew-login-control-submit-button {
		min-width: toRems(175px);
	}

	.tnew-login-required-container {
		@include body-copy();
	}

	/**
	 * Forgot Password
	 */

	.tnew-forgot-form-container {
		max-width: 840px;

		> tbody > tr > td {
			padding-bottom: 15px;
			vertical-align: middle;
		}
	}

	.tnew-forgot-submit-button {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	.tnew-forgot-required-container {
		@include body-copy();
	}

	/**
	 * Account Lookup
	 */

	.tnew-lookup-field-container,
	.tn-table-form__btn-submit-wrapper {
		margin-bottom: $spacing-base;
		max-width: 840px;
	}

	.tnew-lookup-required-container {
		// Required fields note
		@include body-copy();
	}

	/**
	 * Mailing List Subscribe
	 */

	.tnew-shortreg-field-container-bottom {
		max-width: 840px;
	}

	.tnew-shortreg-required-container {
		// Required fields note
		@include body-copy();
	}

	/**
	* Giving Catalogues Item
	*/

	.tnew-auxreserve-container {
		.tnew-auxreserve-container-left-column {
			padding: 30px 30px 30px 0 !important;
			width: 230px;

			img {
				display: block !important;
				height: auto;
				max-width: 100% !important;
				padding: 0 !important;
				width: 100% !important;
			}
		}

		.tnew-auxreserve-container-right-column {
			padding: 30px 0 30px 30px;
		}
	}

	/**
	 * SYOS
	 */

	.tnew-syos-container {
		#seat-legend {
			.legend {
				background-color: palette(blue-grey);
			}
		}
	}

	/**
	 * Account Info
	 */

	.tnew-history-history-table-data {
		width: 100% !important;

		> tbody > tr,
		> head > tr {
			> th,
			> td {
				border: toRems(2px) solid palette(pink);
				min-width: toRems(130px);
				padding: $spacing-small $spacing-small;
				text-align: left;

				@include breakpoint($bp-medium) {
					padding: $spacing-small $spacing-base;
				}
			}
		}
	}

	/**
	 * Weekly Calendar Display
	 */

	#month-year-title,
	#week-cal-buttons {
		display: none;
	}

	#WeekCalendar {
		* {
			display: none;
		}

		&:before {
			@include body-copy;
			display: block;
			content: "Please go to 'Concerts & Tickets' in the menu above to see the performance calendar.";
			opacity: 1;
		}
	}

	.tn-week-calendar-alt {
		display: none;
	}

	/**
	 * Event Listing
	 */

	.tnew-eventlisting-prod-container-table {
		.tnew-eventlisting-prod-image-container {
			min-width: toRems(200px);

			img {
				display: block !important;
				height: auto !important;
				max-width: toRems(200px) !important;
				padding: 0 $spacing-base 0 0 !important;
				width: 100% !important;
			}
		}

		.tnew-eventlisting-prod-season-title-container {
			margin-bottom: $spacing-base;
		}
	}

	.tnew-eventlisting-prod-container {
		.moreInfo,
		.purchaseSmall {
			@include body-copy;
			@include text-link;
		}
	}

	/**
	 * Event Detail
	 */

	.tnew-psdetail-container {
		.bRight {
			border-right-width: 0;
		}

		.tnew-psdetail-perf-container {
			margin-bottom: $spacing-medium;

			h2 {
				@include heading-2;
				color: $c-font;
			}

			.tnew-psdetail-perf-datevenue-container {
				div {
					display: inline;
				}
			}

			.moreInfo,
			.purchaseSmall {
				@include body-copy;
				@include text-link;
			}
		}

		#production-season-detail-text {
			h3 {
				@include heading-4;
				color: $c-font;
			}

			h2 {
				@include heading-3;
				color: $c-font;
			}

			table > tbody > tr > td:first-child {
				padding-right: $spacing-large;
			}
		}

		.planVisit {
			h4 {
				@include heading-4;

				&,
				span {
					color: $c-font !important;
					font-weight: $font-weight-medium;
				}
			}

			.option {
				a {
					margin-right: toRems(4px);
				}
			}
		}
	}
}

// Modals (added before closing body tag so outside of s-tnew-content wrapper)
.ui-dialog.ui-dialog {
	border: 3px solid $c-border;
	padding: 0;

	.ui-dialog-titlebar {
		@include body-copy;
		background-color: $c-black;
		padding-right: toRems(50px);

		.ui-dialog-title {
			float: none;
		}
	}

	// Close button
	.ui-dialog-titlebar-close {
		display: block;
		font-size: 0;
		height: toRems(30px);
		margin: 0;
		transform: translate(-50%, -50%);
		width: toRems(30px);

		&:before,
		&:after {
			background-color: $c-white;
			content: "";
			display: block;
			height: toRems(2px);
			left: 50%;
			margin: toRems(-1px) 0 0 toRems(-10px);
			margin: 0;
			position: absolute;
			top: 50%;
			transform-origin: center center;
			width: toRems(20px);
		}

		&:before {
			transform: rotate(45deg);
		}

		&:after {
			transform: rotate(-45deg);
		}
	}

	// Main modal content
	.ui-dialog-content {
		padding: $spacing-small;

		&,
		& p {
			@include body-copy;
			color: $c-font;
		}

		.tnew-cyoperf-perf-dialog-image {
			padding: 0 !important;
			margin: 0 $spacing-small $spacing-small 0;
		}
	}
}
