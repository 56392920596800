.o-split-grid {
	margin: 0 (-$spacing-base);
	padding: $spacing-large $spacing-base; // Match spacing of o-grid

	@include breakpoint($bp-medium) {
		margin-left: -$spacing-medium;
		margin-right: -$spacing-medium;
		padding: $spacing-medlarge $spacing-medium; // Match spacing of o-grid
	}

	@include breakpoint($bp-xlarge) {
		min-height: toRems(240px);
	}

	@include breakpoint($bp-xlarge) {
		margin-left: toRems(-95px);
		margin-right: toRems(-95px);
	}

	&__wrapper {
		@include grid-full;
		float: none;
		margin: 0 auto;

		&:last-child {
			margin-right: auto;
		}
	}

	&__item {
		@include grid-quarter;

		&:not(:last-child) {
			margin-bottom: $spacing-large;

			@include breakpoint($bp-medium) {
				margin-bottom: 0;
			}
		}
	}

	&__item-half {
		@include grid-half;

		&:not(:last-child) {
			margin-bottom: $spacing-large;

			@include breakpoint($bp-medium) {
				margin-bottom: 0;
				padding-right: $spacing-medium;
			}
		}
	}

	&__item-half-at-small {
		@include grid-half-at-small;

		&:not(:last-child) {
			margin-bottom: $spacing-large;

			@include breakpoint($bp-medium) {
				margin-bottom: 0;
				padding-right: $spacing-medium;
			}
		}
	}

	&__item-half-at-small-quarter-at-medium {
		@include grid-half-at-small;

		@include breakpoint($bp-small) {
			margin-right: 0;
		}

		@include breakpoint($bp-medium) {
			@include span-columns(3);
		}

		&:not(:last-child) {
			margin-bottom: $spacing-large;

			@include breakpoint($bp-medium) {
				margin-bottom: 0;
				padding-right: $spacing-medium;
			}
		}
	}

	&__item-quarter-at-medium {
		@include grid-quarter;

		@include breakpoint($bp-small) {
			clear: left;
			float: none;
		}

		@include breakpoint($bp-medium) {
			clear: none;
			float: left;
		}

		&:not(:last-child) {
			margin-bottom: $spacing-large;

			@include breakpoint($bp-medium) {
				margin-bottom: 0;
			}
		}
	}

	&__item-three-quarters {
		@include grid-three-quarters;
	}

	&__item-full {
		@include grid-full;
	}

	&__action {
		@include grid-quarter;
		margin-top: $spacing-xsmall;

		@include breakpoint($bp-medium) {
			margin-top: $spacing-large;
		}
	}

	&--padding-slim {
		padding-bottom: $spacing-xsmall;
		padding-top: $spacing-xsmall;

		@include breakpoint($bp-large) {
			padding-bottom: $spacing-large;
			padding-top: $spacing-large;
		}

		.o-split-grid__item {
			margin-bottom: 0;
		}
	}

	&--padding-medium {
		padding-bottom: $spacing-medium;
		padding-top: $spacing-medium;

		@include breakpoint($bp-large) {
			padding-bottom: toRems(80px);
			padding-top: toRems(50px);
		}
	}

	// Form fields inline
	&--form-thirds {
		padding-bottom: $spacing-base;
		padding-top: $spacing-medium;

		@include breakpoint($bp-medium) {
			padding-bottom: toRems(50px);
			padding-top: toRems(50px);
		}

		.o-split-grid__item,
		.o-split-grid__action {
			@include grid-thirds-at-small;

			&:not(:last-child) {
				margin-bottom: $spacing-xsmall;

				@include breakpoint($bp-small) {
					margin-bottom: 0;
				}
			}
		}

		.o-split-grid__action {
			margin-top: $spacing-xsmall;

			@include breakpoint($bp-small) {
				margin-top: toRems(35px);
			}

			@include breakpoint($bp-medium) {
				margin-top: $spacing-large;
			}
		}
	}

	// Form fields inline
	&--form {
		padding-bottom: $spacing-base;
		padding-top: $spacing-medium;

		@include breakpoint($bp-medium) {
			padding-bottom: toRems(50px);
			padding-top: toRems(50px);
		}

		.o-split-grid__item:not(:last-child) {
			margin-bottom: $spacing-xsmall;

			@include breakpoint($bp-medium) {
				margin-bottom: 0;
			}
		}
	}

	&--zero-padding {
		min-height: 0;
		padding-bottom: 0;
		padding-top: 0;
	}

	&--overlap-top {
		@include breakpoint($bp-small) {
			margin-top: toRems(-70px);
			position: relative;
			z-index: $z-tile-content;
		}

		@include breakpoint($bp-medium) {
			margin-top: toRems(-120px);
		}
	}

	&--overlap {
		margin-top: $spacing-base;
		position: relative;
		z-index: $z-tile-content;

		@include breakpoint($bp-small) {
			margin-top: toRems(-65px);
		}

		@include breakpoint($bp-medium) {
			margin-top: toRems(-90px);
		}

		@include breakpoint($bp-large) {
			margin-top: toRems(-120px);
		}

		@include breakpoint($bp-xlarge) {
			padding-left: toRems(95px);
			padding-right: toRems(95px);
		}
	}

	&--overlap-bottom {
		position: relative;
		z-index: $z-tile-content;

		@include breakpoint($bp-large) {
			margin-top: toRems(80px);
		}

		@include breakpoint($bp-xlarge) {
			padding-left: toRems(95px);
			padding-right: toRems(95px);
		}
	}

	&--overlap-bottom-top {
		position: relative;
		z-index: $z-tile-content;

		@include breakpoint($bp-large) {
			margin-top: toRems(-125px);
		}

		@include breakpoint($bp-xlarge) {
			padding-left: toRems(95px);
			padding-right: toRems(95px);
		}
	}

	&--overlap--small {
		margin-top: 0;
		position: relative;
		z-index: $z-tile-content;

		@include breakpoint($bp-small) {
			margin-top: toRems(-65px);
		}

		@include breakpoint($bp-medium) {
			margin-top: toRems(-90px);
		}

		@include breakpoint($bp-large) {
			margin-top: toRems(-120px);
		}

		@include breakpoint($bp-xlarge) {
			padding-left: toRems(95px);
			padding-right: toRems(95px);
		}
	}
}
