.c-fine-print {
	@include description;
	color: palette(grey, mid-mid-light);
	font-size: $font-small;
	line-height: 1.875;

	@include breakpoint($bp-small) {
		font-size: $font-size-8;
	}

	a {
		&,
		&:link,
		&:visited {
			@include text-underline-font-size-8(false);
			color: palette(grey);
		}

		&:hover,
		&:active,
		&:focus {
			@include text-underline-font-size-8(true);
			color: palette(grey);
		}
	}
}
