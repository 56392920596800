@mixin nav-item-title() {
	&,
	&:link,
	&:visited {
		color: $c-white;
		display: block;
		font-size: $font-size-7;
		font-weight: $font-weight-medium;
		padding: toRems(9px) $spacing-base;
		width: 100%;

		@include breakpoint($bp-desktop-nav) {
			color: $c-font;
			display: table-cell;
			font-size: $font-size-8;
			font-weight: $font-weight-normal;
			padding: $spacing-base $spacing-small;
			vertical-align: middle;
			white-space: nowrap;
			width: auto;
		}
	}
}

.c-nav-menu {
	background-color: $c-black;
	color: $c-white;
	max-height: 0;
	overflow: hidden;
	transform: translateX(100%);
	transition: max-height 0ms $global-easing 500ms,
		transform 500ms $global-easing 0s;
	width: 100%;
	z-index: $z-fixed-nav;
	-webkit-backface-visibility: hidden;

	@include breakpoint($bp-desktop-nav) {
		left: toRems(-35px);
		max-height: none;
		min-width: toRems(280px);
		opacity: 0;
		padding: toRems(35px) 0;
		pointer-events: none;
		position: absolute;
		top: $header-height-desktop;
		transform: translate(0, 10px);
		transition: opacity 300ms linear 0s, transform 300ms $global-easing 0s,
			visibility 0s linear 300ms, z-index 0s linear 0s;
		visibility: hidden;
		white-space: normal;
		width: auto;
		z-index: $z-header-menu;
	}

	.is-condensed-list & {
		@include breakpoint($bp-desktop-nav) {
			left: auto;
			padding: $spacing-small 0;
			right: 0;
			width: auto;
		}

		@include breakpoint($bp-desktop-nav-large) {
			left: toRems(-35px);
			right: auto;
			padding: toRems(35px) 0;
			width: toRems(280px);
		}
	}

	&.is-visible {
		// Compact slide nav is visible
		@include breakpoint($bp-mobile-nav-only) {
			left: 0;
			max-height: none;
			min-height: 100%;
			position: absolute;
			top: 0;
			transition: max-height 0ms $global-easing 0s,
				transform 500ms $global-easing 0s;
			visibility: visible;
		}
	}

	&.is-active {
		// Desktop menu is visible
		@include breakpoint($bp-desktop-nav) {
			opacity: 1;
			pointer-events: all;
			transform: translate(0, 0);
			transition: opacity 200ms linear 0s,
				transform 200ms $global-easing 0s, visibility 0s linear 0s,
				z-index 0s linear 0s;
			visibility: visible;
			z-index: $z-header-menu + 1;
		}
	}

	&__content {
		background-color: $c-black;
		max-width: $max-content-width;
		padding: toRems(35px) 0 0;
		position: relative;
		width: 100%;

		@include breakpoint($bp-desktop-nav) {
			padding-top: 0;
		}
	}

	&__title {
		margin-top: $spacing-base;

		@include breakpoint($bp-desktop-nav) {
			display: none;
		}

		a {
			@include nav-item-title;
		}
	}

	&__list {
		margin-top: 0;
		padding: 0;
		position: relative;
		width: auto;

		@include breakpoint($bp-desktop-nav) {
			margin-top: 0;
			width: 100%;
		}
	}

	&__item {
		a {
			@include nav-item-title;
			transition: color 300ms $global-easing 0s;

			&,
			&:link,
			&:visited {
				@include breakpoint($bp-desktop-nav) {
					color: $c-white;
					display: block;
					font-size: $font-size-8;
					font-weight: $font-weight-normal;
					padding: toRems(9px) toRems(50px);
					width: 100%;
				}

				span {
					@include breakpoint($bp-desktop-nav) {
						@include text-underline-font-size-8-red(false);
					}
				}
			}

			&:hover,
			&:active,
			&:focus {
				@include breakpoint($bp-desktop-nav) {
					color: palette(red);
					text-decoration: none;
				}

				span {
					@include breakpoint($bp-desktop-nav) {
						@include text-underline-font-size-8-red(true);
					}
				}
			}
		}

		// .is-condensed-list & {
		// 	a {
		// 		&,
		// 		&:link,
		// 		&:visited {
		// 			@include breakpoint($bp-desktop-nav) {
		// 				padding-left: $spacing-base;
		// 				padding-right: $spacing-base;
		// 			}
		// 		}
		// 	}
		// }
	}

	&__actions {
		padding: 0 $spacing-base;

		@include breakpoint($bp-medium) {
			padding: 0 $spacing-medium;
		}
	}
}
