/**
 * TNEW7 package pages
 */

.s-tnew-v7-content {
  .tn-prod-list-item.tn-prod-list-item--package {
    background: none;
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: toRems(4px) solid $c-black;
  }

  .tn-package-perf {
    &.tn-package-perf--fixed-package {
      border: toRems(2px) solid palette(pink);
      margin-bottom: -toRems(2px);
    }

    &__property--datetime {
      background: none;
      color: inherit;
      padding-bottom: 0;
    }
  }

  .tn-ticket-selector {
    &__pricetype-placeholder {
      background: none;
      border: none;
      color: inherit;
      font-style: none;
      padding: 0;
      text-align: left;
      font-weight: $font-weight-bold;
    }

    &__pricetype-list {
      padding-top: $spacing-base;
      &-item {
        border: none;
        margin: 0;
        padding: 0;
        padding-bottom: $spacing-base;
      }
    }
  }

  .tn-flex-details__ticket-selector {
    &-container {
      border-top: toRems(4px) solid $c-black;
    }
  }

  .tn-flex-layout {
    .tn-flex-package {
      &-details__instructions {
        border: none;
        color: inherit;
        margin: toRems(30px) 0;
        padding: 0;

        ul {
          padding: 0;
        }
      }

      &-details__maximum-message {
        font-weight: 700;
      }
    }
    .tn-flex-performance-group {
      border: none;
      margin-top: toRems(80px);
      padding: 0;

      &__title {
        background: $c-black;
        border: none;
        color: $c-white;
      }

      &__requirements {
        font-style: normal;

        &-status-container {
          background-color: alpha-palette(black, 80%);
          color: alpha-palette(white, 50%);
          font-style: normal;
          font-weight: 700;
          margin-bottom: toRems(10px);
          padding: toRems(10px) toRems(15px);
        }
      }

      &__production-list {
        background: none;
        border: none;
        padding: 0;
      }

      &__btn-toggle-productions {
        @include breakpoint($bp-small) {
          position: absolute;
          right: toRems(5px);
          top: toRems(5px);
        }
      }

      &__title,
      &__production-list-container {
        margin: 0;
      }
    }

    .tn-flex-production {
      border-bottom: toRems(4px) solid $c-black;
      padding-left: 0;

      &__date-range {
        color: inherit;
      }
    }

    .tn-flex-performance-selector {
      &__form-group {
        background: none;
        border-bottom: toRems(2px) solid palette(pink);
      }

      &__border {
        border: toRems(2px) solid palette(pink);
      }
    }

    .tn-flex-selection {
      background: palette(pink);
      border: toRems(2px) solid palette(pink);
    }
  }

  .tn-flex-cart__primary-section {
    background: none;
    border: none;
    padding: 0 0 toRems(50px);
    position: relative;
  }

  .tn-flex-cart__primary-section + .tn-flex-cart__primary-section,
  .tn-flex-cart__secondary-section + .tn-flex-cart__secondary-section {
    padding-top: toRems(25px);
    position: relative;
  }

  .tn-flex-cart__secondary-section + .tn-flex-cart__secondary-section {
    margin-top: toRems(50px);
  }

  .tn-flex-cart__primary-section + .tn-flex-cart__primary-section:before,
  .tn-flex-cart__secondary-section + .tn-flex-cart__secondary-section:before {
    background-color: $c-white;
    content: "";
    display: block;
    height: toRems(4px);
    left: 0;
    position: absolute;
    top: 0;
    width: toRems(60px);
  }

  .btn.tn-flex-mobile-cart-drawer__btn {
    &-view-details {
      order: 2;
    }

    &-add-to-cart {
      display: none;
    }
  }

  .tn-flex-in-progress {
    &-package {
      &__heading {
        display: none;
      }

      &__header {
        background: none;
        border: none;
        margin-bottom: $spacing-large;
      }

      &__section-heading,
      &__section--selections {
        border: none;
      }

      &__selections-list {
        margin-top: $spacing-base;
      }

      &__list-item {
        border: toRems(2px) solid palette(pink);
      }
    }
    &__header {
      border-bottom: none;
    }
  }
  .tn-flex-selection.tn-flex-selection--in-progress {
    background: palette(pink);
    border: none;
  }
}

.tn-flex-cart__main-container {
  background: $c-black;
  border: none;
  color: $c-white;
  padding: toRems(55px) $spacing-medium;

  .tn-flex-cart {
    &__primary-heading,
    &__secondary-heading,
    &__selected-pricetypes {
      color: $c-white;
    }
  }

  .btn {
    border-color: $c-white;
  }
}
