/*
 * Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * www.waso.com.au
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2017
 */

@font-face {
	font-family: "Circular";
	src: url("/fonts/lineto-circular-book.eot");
	src: url("/fonts/lineto-circular-book.eot?#iefix") format("embedded-opentype"), url("/fonts/lineto-circular-book.woff2") format("woff2"), url("/fonts/lineto-circular-book.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Circular";
	src: url("/fonts/lineto-circular-medium.eot");
	src: url("/fonts/lineto-circular-medium.eot?#iefix") format("embedded-opentype"), url("/fonts/lineto-circular-medium.woff2") format("woff2"), url("/fonts/lineto-circular-medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Circular";
	src: url("/fonts/lineto-circular-bold.eot");
	src: url("/fonts/lineto-circular-bold.eot?#iefix") format("embedded-opentype"), url("/fonts/lineto-circular-bold.woff2") format("woff2"), url("/fonts/lineto-circular-bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
}

@font-face {
    font-family: "luxuryregular";
    src: url("../fonts/luxuryregular-2.0-webfont.woff2") format("woff2"), url("../fonts/luxuryregular-2.0-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
