@mixin list() {
	@include body-copy;
	list-style-type: none;
	margin-bottom: $spacing-medium;
	padding: 0;
	position: relative;

	@include breakpoint($bp-medium) {
		margin-bottom: $spacing-large;
	}

	&:last-child {
		margin-bottom: 0;

		@include breakpoint($bp-medium) {
			margin-bottom: 0;
		}
	}

	li {
		margin-bottom: $spacing-xsmall;
		padding-left: toRems(35px);
		position: relative;

		&:last-child {
			margin-bottom: 0;
		}

		&:before {
			color: $c-font;
			display: inline-block;
			left: 0;
			line-height: 1;
			position: absolute;
			top: 0;
			vertical-align: middle;
		}

		> ol,
		> ul {
			margin-bottom: $spacing-small;
			margin-top: $spacing-small;
		}
	}
}

@mixin list-unordered() {
	li:before {
		@include body-copy;
		background-color: $c-font;
		content: "";
		display: block;
		height: toRems(2px);
		top: .6em;
		width: toRems(20px);
	}
}

@mixin list-ordered() {
	counter-reset: section;

	li:before {
		content: counter(section, decimal)".";
		counter-increment: section;
		font-weight: $font-weight-normal;
		left: 0;
		top: 0.275em;
	}

	ol {
		li {
			&:before {
				content: counter(section, lower-latin)".";
			}
		}
	}
}

.c-list  {
	@include list;

	// Unordered Lists
	&--unordered {
		@include list-unordered;
	}

	// Ordered Lists
	&--ordered {
		@include list-ordered;
	}
}
