.c-card {
	padding-top: 100%;
	position: relative;
	width: 100%;
	z-index: $z-tile-background;

	&:before {
		background-color: transparent;
		border: toRems(4px) solid $c-black;
		bottom: 0;
		content: "";
		display: block;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: $z-place-behind;
	}

	&__main {
		&,
		&:link,
		&:visited {
			align-content: flex-start;
			align-items: stretch;
			color: $c-black;
			display: flex;
			flex-flow: column nowrap;
			justify-content: flex-start;
			margin-top: -100%; // Cover the space created by parent's padding-top
			min-height: 100%;
			padding: toRems(25px) $spacing-base toRems(50px);
			position: static;
			width: 100%;
			z-index: $z-tile-background;

			.c-card__title {
				@include text-underline-font-size-5(false);

				@include breakpoint($bp-medium) {
					@include text-underline-font-size-3(false)
				}
			}

			.c-card--background & {
				.c-card__title {
					@include text-underline-font-size-5-white(false);

					@include breakpoint($bp-medium) {
						@include text-underline-font-size-3-white(false)
					}
				}
			}
		}


		&:hover,
		&:active,
		&:focus {
			.c-card__title {
				@include text-underline-font-size-5(true);

				@include breakpoint($bp-medium) {
					@include text-underline-font-size-3(true)
				}
			}

			.c-card--background & {
				.c-card__title {
					@include text-underline-font-size-5-white(true);

					@include breakpoint($bp-medium) {
						@include text-underline-font-size-3-white(true)
					}
				}
			}

			.c-card__thumbnail:before {
				background-color: alpha-palette(black, 60%);
			}
		}
	}

	&__content {
		position: relative;
		z-index: $z-tile-content;
	}

	&__thumbnail {
		background: no-repeat center center scroll $c-black;
		background-size: cover;
		display: block;
		height: 100%;
		left: 0;
		padding-top: 100%;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: $z-tile-thumb;

		&:before {
			background-color: alpha-palette(black, 25%);
			content: "";
			display: block;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			transition: background 500ms $global-easing 0s;
			width: 100%;
			z-index: $z-tile-thumb-overlay;
		}

		img {
			display: none;
		}
	}

	&__title {
		@include tile-title-large;
		display: inline; // Required for 'fancy' underline style
		line-height: 1.2;
	}

	&__meta {
		font-size: $font-size-8;
		line-height: 1.375;
		margin-bottom: $spacing-base;

		@include breakpoint($bp-small) {
			margin-bottom: toRems(35px);
		}
	}

	&__label {
		display: block;
		font-weight: $font-weight-bold;
	}


	/**
	 * Card with thumbnail (background) image
	 */

	&--background {
		&:before {
			border: none;
		}

		.c-card__main {
			&,
			&:link,
			&:visited {
				border: none;
				color: $c-white;
			}
		}
	}
}
