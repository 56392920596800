// Alignment
.u-align-left.u-align-left {
	text-align: left;
}

.u-align-center.u-align-center {
	text-align: center;
}

.u-align-right.u-align-right {
	text-align: right;
}

// Headings
.u-heading-1 {
	@include heading-1;
}

.u-heading-2 {
	@include heading-2;
}

.u-heading-3 {
	@include heading-3;
}

.u-heading-4 {
	@include heading-4;
}

.u-heading-5 {
	@include heading-5;
}

.u-heading-6 {
	@include heading-6;
}

.u-heading-7 {
	@include heading-7;
}

// Blockquote
.u-blockquote {
	@include blockquote-text;
}

// Other type styles
.u-blurb {
	@include blurb;
}

.u-caption {
	@include caption;
}

.u-description {
	@include description;
}

.u-body-copy {
	@include body-copy;
}

.u-small-copy {
	@include small-copy;
}

// Links
.u-text-link {
	@include text-link;
}

.u-text-link-reverse {
	@include text-link-reverse;
}
