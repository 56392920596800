.c-button-nav {
	&,
	&:link,
	&:visited {
		color: $c-font;
		cursor: pointer;
		display: block;
		font-size: $font-small;
		height: $header-height;
		line-height: 1;
		margin: 0;
		overflow: hidden;
		padding: toRems(28px) $spacing-base;
		position: relative;
		text-align: center;
		width: 100%;
		-webkit-appearance: none; // Makes sure iOS doesn't add extra styling to buttons.

		.has-active-menu & {
			color: $c-white;
		}

		.has-active-search & {
			color: $c-white;
		}

		&:before,
		&:after {
			background-color: $c-font;
			content: "";
			display: block;
			height: toRems(2px);
			left: 50%;
			position: absolute;
			transform: translateX(-50%);
			transition: background-color 350ms $global-easing 0s;
			width: toRems(42px);

			.has-active-menu & {
				background-color: $c-white;
			}

			.has-active-search & {
				background-color: $c-white;
			}
		}

		&:before {
			top: toRems(18px);
		}

		&:after {
			bottom: toRems(18px);
		}
	}

	&__label-open {
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		transition: transform 350ms $global-easing 0s;

		.has-active-menu & {
			transform: translate(250%, -50%);
		}
	}

	&__label-close {
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-250%, -50%);
		transition: transform 350ms $global-easing 0s;

		.has-active-menu & {
			transform: translate(-50%, -50%);
		}
	}
}
