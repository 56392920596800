.s-cms-content {
	@include clearfix;
	@include body-copy;

	.o-narrow & {
		// Add spacing at the bottom of CMS content if in a narrow wrapper
		margin-bottom: $spacing-medlarge;

		@include breakpoint($bp-small) {
			margin-bottom: toRems(80px);
		}
	}

	+ #{&} {
		// Add spacing between multiple consecutive elements one after the other
		margin-top: $spacing-medium;

		@include breakpoint($bp-small) {
			margin-top: toRems(60px);
		}
	}

	// Headings
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: 1em;
		margin-top: 2.75em;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	h1 {
		@include heading-2;
	}

	h2 {
		@include heading-4;
	}

	h3 {
		@include heading-5;
	}

	h4 {
		@include heading-6;
	}

	h5 {
		@include heading-6;
	}

	h6 {
		@include heading-6;
	}

	a {
		@include text-link;
	}

	// Images
	figure {
		margin-bottom: $spacing-medium;
	}

	// Image Captions
	figcaption {
		padding: $spacing-small 0;
	}

	blockquote {
		@include blockquote-text;
		margin: $spacing-xlarge 0;
	}

	cite {
	}

	p {
		margin-bottom: 1.6em;
		margin-top: 0;

		&:last-child {
			margin-bottom: 0;
		}
	}

	ul {
		@include list;
		@include list-unordered;
	}

	ol {
		@include list;
		@include list-ordered;
	}
}
