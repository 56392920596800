.c-dropdown {
	min-width: 100%;

	@include breakpoint($bp-small) {
		min-width: 180px;
	}

	&.chosen-container-active.chosen-with-drop .chosen-single {
	    border: none;
	    background: #000;
	}

	.chosen-single {
		background: #444;
		border-radius: 0 !important;
		border: none !important;
		color: $c-white;
		box-shadow: none;
		font-size: $font-size-5;
		font-weight: $font-weight-semibold;
		height: auto;
		letter-spacing: 0.025em;
		padding: 13px $spacing-base;
		text-align: left;
		text-transform: uppercase;
		transition: background 0.3s $global-easing, color 0.3s $global-easing;

		@include breakpoint($bp-small) {
			padding: 23px $spacing-base;
		}

		&:hover {
			color: $c-white;
			background: #000;
		}

		// Arrow
		div {
			font-size: toRems(11px);
			height: auto;
			line-height: 1;
			margin-top: -5px;
			right: $spacing-small;
			top: 50%;
			transition: transform 0.3s $global-easing;
			width: auto;

			&:before {
				@extend %i-chevron-down;
				line-height: 1;
			}
		}
	}

	.chosen-drop {
		box-shadow: none;
		border: none;
		margin: 0;
		min-width: 100%;
		padding: 0;

		@include breakpoint($bp-small) {
			min-width: 180px;
		}
	}

	.chosen-results {
		@include material-shadow(1);
		padding: 0;
		margin: 0;

		li {
			background: $c-white;
			color: $c-font;
			font-size: $font-size-5;
			font-weight: $font-weight-semibold;
			letter-spacing: 0.025em;
			line-height: 1;
			padding: 17px $spacing-small;
			text-align: left;
			text-transform: uppercase;
			transition: background 0.3s $global-easing, color 0.3s $global-easing;

			@include breakpoint($bp-small) {
				padding: 26px $spacing-small;
			}

			&:hover {
				background: $c-white;
				color: $c-black !important;
			}

			&.result-selected {
				background: $c-black;
				color: $c-white !important;
			}

			&.highlighted {
				background: palette(grey, light);
				color: $c-white !important;
			}

			&.disabled-result,
			&.disabled-result:hover {
				background: $c-white;
				color: palette(grey, light);
			}
		}
	}

	&.chosen-with-drop {
		.chosen-single div {
			transform: rotate(180deg);
		}
	}
}
