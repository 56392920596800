.o-grid {
    @include outer-container;
    margin: 0 auto;
    max-width: $max-site-width + ($spacing-base * 2);
    opacity: 1;
    position: relative;
    padding: 0 $spacing-base;
    transition: opacity 300ms $global-easing 0s;

    @include breakpoint($bp-medium) {
        max-width: $max-content-width + ($spacing-medium * 2);
        padding: 0 $spacing-medium;
        transition-duration: 500ms;
    }

    &--no-pad {
        padding: 0;
    }
}
