// Headings
@mixin heading-1() {
	font-family: $circular;
	font-size: $font-size-3;
	font-weight: $font-weight-medium;
	line-height: 1;

	@include breakpoint($bp-medium) {
		font-size: $font-size-1;
		line-height: .96;
	}
}

@mixin heading-2() {
	font-family: $circular;
	font-size: $font-size-3;
	font-weight: $font-weight-medium;
	line-height: 1;

	@include breakpoint($bp-medium) {
		font-size: $font-size-2;
	}
}

@mixin heading-3() {
	font-family: $circular;
	font-size: $font-size-4;
	font-weight: $font-weight-medium;
	line-height: 1.06;

	@include breakpoint($bp-medium) {
		font-size: $font-size-3;
	}
}

@mixin heading-4() {
	font-family: $circular;
	font-size: $font-size-7;
	font-weight: $font-weight-medium;
	line-height: 1.36;

	@include breakpoint($bp-medium) {
		font-size: $font-size-5;
	}
}

@mixin heading-5() {
	font-family: $circular;
	font-size: $font-size-7;
	font-weight: $font-weight-normal;
	line-height: 1.25;
}

@mixin heading-6() {
	font-family: $circular;
	font-size: $font-size-8;
	font-weight: $font-weight-bold;
	line-height: 1.38;
}

@mixin heading-7() {
	font-family: $circular;
	font-size: $font-size-8;
	font-weight: $font-weight-normal;
	line-height: 1.5;
}

// Other Type Styles
@mixin body-copy() {
	font-family: $circular;
	font-size: $font-size-8;
	font-weight: $font-weight-normal;
	line-height: 1.56;

	@include breakpoint($bp-medium) {
		font-size: $font-size-7;
		line-height: 1.5;
	}
}

@mixin small-copy() {
	font-family: $circular;
	font-size: $font-small;
	font-weight: $font-weight-normal;
	line-height: 1;
}

@mixin blurb() {
	font-family: $circular;
	font-size: $font-size-7;
	font-weight: $font-weight-medium;
	line-height: 1.5;

	@include breakpoint($bp-medium) {
		font-size: $font-size-5;
		line-height: 1.36;
	}
}

@mixin caption() {
	font-family: $circular;
	font-size: $font-small;
	font-weight: $font-weight-normal;
	line-height: 1.79;
}

@mixin description() {
	font-family: $circular;
	font-size: $font-size-8;
	font-weight: $font-weight-normal;
	line-height: 1.38;
}

//Blockquote
@mixin blockquote-text() {
	font-family: $circular;
	font-size: $font-size-3;
	font-weight: $font-weight-medium;
	line-height: 1;

	@include breakpoint($bp-medium) {
		font-size: $font-size-2;
	}
}

// Links
@mixin text-link() {
	&,
	&:link {
		@include text-underline-font-size-8(true);
		color: $c-link;
		text-decoration: none;
		text-underline-position: under;
	}

	&:visited {
		color: $c-link-visited;
	}

	&:hover {
		@include text-underline-font-size-8(false);
		color: $c-link-hover;
		text-decoration: none;
	}

	&:active {
		color: $c-link-hover;
	}

	&:focus {
		color: $c-link-hover;
		text-decoration: none;
	}
}

// Links
@mixin text-link-reverse() {
	&,
	&:link {
		@include text-underline-font-size-8(false);
		color: $c-link;
		text-decoration: none;
	}

	&:visited {
		color: $c-link-visited;
	}

	&:hover {
		@include text-underline-font-size-8(true);
		color: $c-link-hover;
		text-decoration: none;
	}

	&:active {
		color: $c-link-hover;
	}

	&:focus {
		color: $c-link-hover;
		text-decoration: underline;
	}
}

// Form label
@mixin form-label() {
	font-family: $circular;
	font-size: $font-size-8;
	font-weight: $font-weight-normal;
	line-height: 1.25;

	@include breakpoint($bp-medium) {
		font-size: $font-size-7;
	}
}

@mixin form-label-small() {
	font-family: $circular;
	font-size: $font-size-8;
	font-weight: $font-weight-normal;
	line-height: 1.3;
}

// Buttons
@mixin button-text() {
	font-family: $circular;
	font-size: $font-size-7;
	font-weight: $font-weight-medium;
	line-height: 1.25;
}

// Hero Titles
@mixin hero-label() {
	font-family: $circular;
	font-size: $font-small;
	font-weight: $font-weight-normal;
	line-height: 1.25;

	@include breakpoint($bp-small) {
		font-size: $font-size-8;
	}
}

@mixin hero-subtitle() {
	font-family: $circular;
	font-size: $font-size-7;
	font-weight: $font-weight-medium;
	line-height: 1.25;

	@include breakpoint($bp-medium) {
		font-size: $font-size-5;
	}
}

@mixin hero-title() {
	font-family: $circular;
	font-size: $font-size-4;
	font-weight: $font-weight-medium;
	line-height: 1.2; 

	@include breakpoint($bp-small) {
		font-size: $font-size-2;
	}
}

@mixin hero-blurb() {
	font-family: $circular;
	font-size: $font-size-8;
	font-weight: $font-weight-light;
	line-height: 1.38;

	@include breakpoint($bp-medium) {
		font-size: $font-size-7;
		line-height: 1.5;
	}
}

// Tiles
@mixin tile-title-large() {
	font-family: $circular;
	font-size: $font-size-5;
	font-weight: $font-weight-medium;
	line-height: 1.06;

	@include breakpoint($bp-medium) {
		font-size: $font-size-3;
	}
}

@mixin tile-title-small() {
	font-family: $circular;
	font-size: $font-size-7;
	font-weight: $font-weight-bold;
	line-height: 1.2;
}

@mixin text-underline-common() {
	background-repeat: no-repeat;
	text-decoration: none;
	transition-property: background-color, color, opacity, border, border-color,
		background-position, outline, box-shadow, border-bottom, text-decoration,
		left, top, transform;
}

@function underlineVisibility($visible) {
	@if $visible {
		@return 100% 100%;
	} @else {
		@return 0 100%;
	}
}

@mixin text-underline-font-size-8($visible) {
	@include text-underline-common;
	background-image: linear-gradient(
		to top,
		$c-black 6.0606061%,
		transparent 0
	);
	background-size: underlineVisibility($visible);
	padding-bottom: toRems(3px);
}

@mixin text-underline-font-size-8-white($visible) {
	@include text-underline-common;
	background-image: linear-gradient(
		to top,
		$c-white 6.0606061%,
		transparent 0
	);
	background-size: underlineVisibility($visible);
	padding-bottom: toRems(3px);
}

@mixin text-underline-font-size-8-yellow($visible) {
	@include text-underline-common;
	background-image: linear-gradient(
		to top,
		palette(yellow) 6.0606061%,
		transparent 0
	);
	background-size: underlineVisibility($visible);
	padding-bottom: toRems(3px);
}

@mixin text-underline-font-size-8-red($visible) {
	@include text-underline-common;
	background-image: linear-gradient(
		to top,
		palette(red) 6.0606061%,
		transparent 0
	);
	background-size: underlineVisibility($visible);
	padding-bottom: toRems(3px);
}

@mixin text-underline-font-size-7($visible) {
	@include text-underline-common;
	background-image: linear-gradient(
		to top,
		$c-black 6.0606061%,
		transparent 0
	);
	background-size: underlineVisibility($visible);
	padding-bottom: toRems(3px);
}

@mixin text-underline-font-size-7-white($visible) {
	@include text-underline-common;
	background-image: linear-gradient(
		to top,
		$c-white 6.0606061%,
		transparent 0
	);
	background-size: underlineVisibility($visible);
	padding-bottom: toRems(3px);
}

@mixin text-underline-font-size-5($visible) {
	@include text-underline-common;
	background-image: linear-gradient(
		to top,
		$c-black 8.928571429%,
		transparent 0
	);
	background-size: underlineVisibility($visible);
	padding-bottom: toRems(3px);
}

@mixin text-underline-font-size-5-white($visible) {
	@include text-underline-common;
	background-image: linear-gradient(
		to top,
		$c-white 8.928571429%,
		transparent 0
	);
	background-size: underlineVisibility($visible);
	padding-bottom: toRems(3px);
}

@mixin text-underline-font-size-4($visible) {
	@include text-underline-common;
	background-image: linear-gradient(
		to top,
		$c-black 7.675438596%,
		transparent 0
	);
	background-size: underlineVisibility($visible);
	padding-bottom: toRems(3px);
}

@mixin text-underline-font-size-3($visible) {
	@include text-underline-common;
	background-image: linear-gradient(
		to top,
		$c-black 7.675438596%,
		transparent 0
	);
	background-size: underlineVisibility($visible);
	padding-bottom: toRems(3px);
}

@mixin text-underline-font-size-3-white($visible) {
	@include text-underline-common;
	background-image: linear-gradient(
		to top,
		$c-white 7.675438596%,
		transparent 0
	);
	background-size: underlineVisibility($visible);
	padding-bottom: toRems(3px);
}

@mixin text-underline-font-size-2($visible) {
	@include text-underline-common;
	background-image: linear-gradient(
		to top,
		$c-black 6%,
		transparent 0
	);
	background-size: underlineVisibility($visible);
	padding-bottom: toRems(3px);
}

@mixin text-underline-font-size-2-white($visible) {
	@include text-underline-common;
	background-image: linear-gradient(
		to top,
		$c-white 6%,
		transparent 0
	);
	background-size: underlineVisibility($visible);
	padding-bottom: toRems(3px);
}

@mixin text-underline-font-size-1($visible) {
	@include text-underline-common;
	background-image: linear-gradient(
		to top,
		$c-black 6.451612903%,
		transparent 0
	);
	background-size: underlineVisibility($visible);
	padding-bottom: toRems(8px);
}
