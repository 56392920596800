.c-blockquote {
	margin: $spacing-xlarge 0;  // Negative left/right margin to expand out of grid
	text-align: center;

	// Break out of grid (one column on left and right wider than grid) if in narrow wrapper
	.o-narrow &,
	.o-content-sidebar__main & {
		margin: $spacing-xlarge (-$spacing-base) toRems(70px);

		@include breakpoint($bp-medium) {
			margin-left: -((5.85586% + 2.7027%) / .657657658); // column width + column gutter width / divided by ratio of narrow grid to normal grid size
			margin-right: -((5.85586% + 2.7027%) / .657657658);
		}

		@include breakpoint($bp-large) {
			margin-bottom: toRems(110px);
			margin-top: toRems(130px);
		}

		&:last-child {
			margin-bottom: 0;

			@include breakpoint($bp-medium) {
				margin-bottom: 0;
			}

			@include breakpoint($bp-large) {
				margin-bottom: 0;
			}
		}
	}

	&__container {
		padding: $spacing-large $spacing-base;
		position: relative;

		@include breakpoint($bp-medium) {
			padding: toRems(50px) toRems(95px);
		}

		&:before {
			background-color: palette(blue-grey);
			content: "";
			display: block;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: $z-tile-background;
		}
	}

	&__content {
		@include blockquote-text;
		margin-bottom: toRems(-10px);
		margin-top: toRems(-65px);
		position: relative;
		z-index: $z-tile-content;

		@include breakpoint($bp-medium) {
			margin-top: toRems(-85px);
		}
	}

	&__caption {
		@include caption;
		display: block;
		margin-top: $spacing-small;
		position: relative;
		z-index: $z-tile-content;
	}


	/**
	 * Breakout (Narrow, Centered) -
	 * Left and right hang outside of narrow grid container
	 */

	&--breakout-narrow-center {
		.o-narrow &,
		.o-content-sidebar__main & {
			@include breakpoint($bp-large) {
				margin-left: toRems(-95px);
				margin-right: toRems(-95px);
			}
		}
	}
}
