.u-spacing-top-zero.u-spacing-top-zero {
	margin-top: 0;
}

.u-spacing-bottom-zero.u-spacing-bottom-zero {
	margin-bottom: 0;
}

.u-spacing-top-xsmall.u-spacing-top-xsmall {
	margin-top: $spacing-xsmall;
}

.u-spacing-bottom-xsmall.u-spacing-bottom-xsmall {
	margin-bottom: $spacing-xsmall;
}

.u-spacing-left-xsmall.u-spacing-left-xsmall {
	margin-left: $spacing-small;
}

.u-spacing-top-small.u-spacing-top-small {
	margin-top: $spacing-small;
}

.u-spacing-bottom-small.u-spacing-bottom-small {
	margin-bottom: $spacing-small;
}

.u-spacing-top-base.u-spacing-top-base {
	margin-top: $spacing-base;
}

.u-spacing-bottom-base.u-spacing-bottom-base {
	margin-bottom: $spacing-base;
}

.u-spacing-top-medium.u-spacing-top-medium {
	margin-top: $spacing-medium;
}

.u-spacing-bottom-medium.u-spacing-bottom-medium {
	margin-bottom: $spacing-medium;
}

.u-spacing-top-medlarge.u-spacing-top-medlarge {
	margin-top: $spacing-medlarge;
}

.u-spacing-bottom-medlarge.u-spacing-bottom-medlarge {
	margin-bottom: $spacing-medlarge;
}

.u-spacing-top-large.u-spacing-top-large {
	margin-top: $spacing-large;
}

.u-spacing-bottom-large.u-spacing-bottom-large {
	margin-bottom: $spacing-large;
}

.u-spacing-top-xlarge.u-spacing-top-xlarge {
	margin-top: $spacing-xlarge;
}

.u-spacing-bottom-xlarge.u-spacing-bottom-xlarge {
	margin-bottom: $spacing-xlarge;
}

/**
 * @bp-small
 */

.u-spacing-bottom-xxxxlarge\@small.u-spacing-bottom-xxxxlarge\@small {
	@include breakpoint($bp-small) {
		margin-bottom: $spacing-xxxxlarge;
	}
}

/**
 * @bp-medium
 */

.u-spacing-top-zero\@medium.u-spacing-top-zero\@medium {
	@include breakpoint($bp-medium) {
		margin-top: 0;
	}
}

.u-spacing-bottom-zero\@medium.u-spacing-bottom-zero\@medium {
	@include breakpoint($bp-medium) {
		margin-bottom: 0;
	}
}

.u-spacing-top-small\@medium.u-spacing-top-small\@medium {
	@include breakpoint($bp-medium) {
		margin-top: $spacing-small;
	}
}

.u-spacing-bottom-small\@medium.u-spacing-bottom-small\@medium {
	@include breakpoint($bp-medium) {
		margin-bottom: $spacing-small;
	}
}

.u-spacing-top-base\@medium.u-spacing-top-base\@medium {
	@include breakpoint($bp-medium) {
		margin-top: $spacing-base;
	}
}

.u-spacing-bottom-base\@medium.u-spacing-bottom-base\@medium {
	@include breakpoint($bp-medium) {
		margin-bottom: $spacing-base;
	}
}

.u-spacing-top-medium\@medium.u-spacing-top-medium\@medium {
	@include breakpoint($bp-medium) {
		margin-top: $spacing-medium;
	}
}

.u-spacing-bottom-medium\@medium.u-spacing-bottom-medium\@medium {
	@include breakpoint($bp-medium) {
		margin-bottom: $spacing-medium;
	}
}

.u-spacing-bottom-medlarge\@medium.u-spacing-bottom-medlarge\@medium {
	@include breakpoint($bp-medium) {
		margin-bottom: $spacing-medlarge;
	}
}

.u-spacing-bottom-large\@medium.u-spacing-bottom-large\@medium {
	@include breakpoint($bp-medium) {
		margin-bottom: $spacing-large;
	}
}

.u-spacing-top-xlarge\@medium.u-spacing-top-xlarge\@medium {
	@include breakpoint($bp-medium) {
		margin-top: $spacing-xlarge;
	}
}

.u-spacing-bottom-xlarge\@medium.u-spacing-bottom-xlarge\@medium {
	@include breakpoint($bp-medium) {
		margin-bottom: $spacing-xlarge;
	}
}

.u-spacing-bottom-xxlarge\@medium.u-spacing-bottom-xxlarge\@medium {
	@include breakpoint($bp-medium) {
		margin-bottom: $spacing-xxlarge;
	}
}

.u-spacing-bottom-xxxlarge\@medium.u-spacing-bottom-xxxlarge\@medium {
	@include breakpoint($bp-medium) {
		margin-bottom: $spacing-xxxlarge;
	}
}

/**
 * @bp-large
 */

.u-spacing-bottom-xxxlarge\@large.u-spacing-bottom-xxxlarge\@large {
	@include breakpoint($bp-large) {
		margin-bottom: $spacing-xxxlarge;
	}
}

/*
 * Inner Spacing (Padding)
 */

.u-spacing-inner-right-medium.u-spacing-inner-right-medium {
	padding-right: $spacing-medium;
}
