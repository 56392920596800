/*
TNEW7 error and information messages
*/

.s-tnew-v7-content {
  .help-block {
    @include form-error;
    float: none;
    margin-top: $spacing-xsmall;

    li {
      padding: $spacing-xxsmall 0 $spacing-xsmall;
    }
  }

  .tn-timer.tn-timer-urgent {
    color: palette(red);
  }

  .tn-heading-info,
  .tn-modal-heading-info,
  .alert {
    border-radius: 0;
  }

  .tn-heading-info,
  .tn-modal-heading-info,
  .bg-primary {
    background-color: palette(blue-grey);
    color: $c-black;
  }

  .bg {
    &-success {
      background-color: palette(bright-blue);
    }

    &-info {
      background-color: palette(yellow);
    }

    &-warning {
      background-color: palette(red);
      color: $c-white;
    }

    &-danger {
      background-color: palette(errors);
      color: $c-white;
    }
  }
}
