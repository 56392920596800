.c-header {
	background-color: $c-white;
	height: $header-height;
	position: fixed;
	top: 0;
	transition: background 350ms $global-easing 0s,
				box-shadow 350ms $global-easing 0s,
				transform 400ms $global-easing 0s;
	width: 100%;
	z-index: $z-header;

	@include breakpoint($bp-desktop-nav) {
		height: $header-height-desktop;
	}

	.has-active-search & {
		background-color: transparent;
	}

	&.is-not-top {
		box-shadow: 0 0 toRems(15px) 0 rgba(0, 0, 0, .1);
	}

	&.is-unpinned {
		transform: translateY(-115%);

		@include breakpoint($bp-desktop-nav) {
			transform: translateY(-105%);
		}

		.no-touchevents & {
			&:hover {
				transform: translateY(0);
			}
		}

		.has-active-menu & {
			transform: translateY(0);
		}
	}

	&:before {
		content: "";
		display: block;
		background-color: $c-black;
		left: 0;
		height: $header-height;
		position: absolute;
		top: 0;
	    transform: translateX(-100%);
		transition: transform 500ms $global-easing 0s;
		width: 100%;
		z-index: $z-header + 1;

		@include breakpoint($bp-desktop-nav) {
			display: none;
		}

		// Slide menu in from left when active (smaller screens):
		.has-active-menu & {
			top: 0;
			transform: translateX(0%);
			transition: transform 350ms $global-easing 0s;
	    }
	}

	&__container {
		@include width-wrapper;
		padding-left: toRems(80px);
		padding-right: toRems(80px);
		position: relative;
		width: 100%;

		@include breakpoint($bp-desktop-nav) {
			align-content: flex-start;
			align-items: stretch;
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			padding-left: 0;
			padding-right: 0;
			width: auto;
		}
	}

	&__logo {
		position: relative;
		z-index: $z-header-item;

		@include breakpoint($bp-desktop-nav) {
			flex: 0 0 auto;
		}

		a {
			display: block;
			font-size: 0;
			height: $header-height;
			line-height: 0;
			text-align: center;
			width: 100%;

			@include breakpoint($bp-desktop-nav) {
				height: $header-height-desktop;
				padding-left: toRems(25px);
				padding-right: toRems(25px);
			}
		}

		svg {
			display: inline-block;
			height: toRems(40px);
			margin-bottom: (($header-height - toRems(40px)) / 2);
			margin-top: (($header-height - toRems(40px)) / 2);
			transition: fill 500ms $global-easing 0s;
			width: toRems(100px);

			@include breakpoint($bp-desktop-nav) {
				height: toRems(85px);
				margin-bottom: (($header-height-desktop - toRems(85px)) / 2);
				margin-top: (($header-height-desktop - toRems(85px)) / 2);
				width: toRems(130px);
			}

			.has-active-menu & {
				fill: $c-white;

				@include breakpoint($bp-desktop-nav) {
					fill: $c-black;
				}
			}

			.has-active-search & {
				fill: $c-white;
			}
		}
	}

	&__nav {
		height: $header-height;
		left: 0;
		position: absolute;
		top: 0;
		width: toRems(80px);
		z-index: $z-header-item;

		@include breakpoint($bp-desktop-nav) {
			flex: 1 1 100%;
			position: relative;
			width: auto;
		}
	}

	&__actions {
		height: $header-height;
		right: 0;
		position: absolute;
		top: 0;
		width: toRems(60px);
		z-index: $z-header-item;

		@include breakpoint($bp-desktop-nav) {
			display: none;
		}

		@include breakpoint($bp-desktop-nav-large) {
			display: block;
			flex: 0 0 auto;
			height: $header-height-desktop;
			padding-left: $spacing-base;
			position: relative;
			width: auto;
		}
	}

	&__icon-list {
		@include breakpoint($bp-desktop-nav) {
			align-content: flex-start;
			align-items: stretch;
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			margin: 0;
			padding: 0;
			width: auto;
		}
	}

	&__action {
		@include breakpoint($bp-desktop-nav) {
			position: relative;
		}

		&:before {
			@include breakpoint($bp-desktop-nav) {
				background-color: $c-black;
				content: "";
				display: block;
				height: toRems(40px);
				left: 0;
				opacity: .25;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: 1px;
			}
		}
	}

	&__search {
		height: $header-height;
		opacity: 1;
		pointer-events: all;
		position: absolute;
		right: 0;
		top: 0;
		transition: opacity 600ms $global-easing 0s;
		width: toRems(60px);
		z-index: $z-header-item;

		@include breakpoint($bp-desktop-nav) {
			height: $header-height-desktop;
			position: relative;
			width: auto;
		}

		&:before {
			@include breakpoint($bp-desktop-nav) {
				background-color: $c-black;
				content: "";
				display: block;
				height: toRems(40px);
				left: 0;
				opacity: .25;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: 1px;
			}
		}
	}
}

#main {
	padding-top: $header-height;

	@include breakpoint($bp-desktop-nav) {
		padding-top: $header-height-desktop;
	}
}
