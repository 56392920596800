// button-base mixin is in _button-icon.scss

.c-button {
    @include button-base;

    /**
	 * Secondary Button
	 */

    &--secondary {
        &,
        &:link,
        &:visited {
            background-color: $c-white;
            color: $c-black;

            // If the element is a link or button element
            @at-root {
                a#{&},
                button#{&} {
                    // Only apply fancy styling if on a non-touch device
                    .no-touchevents & {
                        background-color: transparent;

                        &:before {
                            background-color: $c-white;
                            content: '';
                        }

                        &:after {
                            background-color: $c-black;
                        }
                    }
                }
            }
        }

        &:hover,
        &:active,
        &:focus {
			.no-touchevents & {
				background-color: $c-black;
				color: $c-white;

				&:disabled {
					background-color: $c-white;
					color: $c-black;
				}

				// If the element is a link or button element
				@at-root {
					a#{&},
					button#{&} {
						// Only apply fancy styling if on a non-touch device
						.no-touchevents & {
							background-color: transparent;
						}
					}
				}
			}
        }
    }

    /**
	 * Light variation on dark background
	 */

    &--light {
        &,
        &:link,
        &:visited {
            background-color: $c-white;
            border-color: $c-white;
            color: $c-black;
            z-index: 1;

            // If the element is a link or button element
            @at-root {
                a#{&},
                button#{&} {
                    // Only apply fancy styling if on a non-touch device
                    .no-touchevents & {
                        background-color: transparent;

                        &:before {
                            background-color: $c-white;
                            content: '';
                        }

                        &:after {
                            background-color: $c-black;
                        }
                    }
                }
            }
        }

        &:hover,
        &:active,
        &:focus {
            background-color: $c-black;
            color: $c-white;

            // If the element is a link or button element
            @at-root {
                a#{&},
                button#{&} {
                    // Only apply fancy styling if on a non-touch device
                    .no-touchevents & {
                        background-color: transparent;
						color: $c-white;
                    }
                }
            }
        }
    }

    /**
	 * Dark variation on dark background
	 */
    &--dark {
        &,
        &:link,
        &:visited {
            background-color: transparent;
            border-color: $c-white;
            color: $c-white;
            z-index: 1;

            // If the element is a link or button element
            @at-root {
                a#{&},
                button#{&} {
                    // Only apply fancy styling if on a non-touch device
                    .no-touchevents & {
                        background-color: transparent;

                        &:before {
                            background-color: transparent;
                            content: '';
                        }

                        &:after {
                            background-color: $c-white;
                        }
                    }
                }
            }
        }

        &:hover,
        &:active,
        &:focus {
            background-color: $c-white;
            color: $c-black;

            // If the element is a link or button element
            @at-root {
                a#{&},
                button#{&} {
                    // Only apply fancy styling if on a non-touch device
                    .no-touchevents & {
                        background-color: transparent;
                    }
                }
            }
        }
    }

    /**
	 * Fills width of container
	 */

    &--fill-width {
        &,
        &:link,
        &:visited {
            display: block;
            width: 100%;
        }
    }

    /**
	 * Wider Button
	 */

    &--wide {
        &,
        &:link,
        &:visited {
            @include breakpoint($bp-small) {
                min-width: toRems(250px);
            }
        }
    }
}

.show-schedule-accordion {
    .show-schedule-trigger {
        .show-schedule-button {
            border-style: solid;
            border-width: 1px;

            &,
            &:link,
            &:visited {
                background-color: $c-white;
                color: $c-black;

                // If the element is a link or button element
                @at-root {
                    a#{&},
                    button#{&} {
                        // Only apply fancy styling if on a non-touch device
                        .no-touchevents & {
                            background-color: transparent;

                            &:before {
                                background-color: $c-white;
                                content: '';
                            }

                            &:after {
                                background-color: $c-black;
                            }
                        }
                    }
                }
            }

            &:hover,
            &:active,
            &:focus {
                .no-touchevents & {
                    background-color: $c-black;
                    color: $c-white;

                    &:disabled {
                        background-color: $c-white;
                        color: $c-black;
                    }

                    // If the element is a link or button element
                    @at-root {
                        a#{&},
                        button#{&} {
                            // Only apply fancy styling if on a non-touch device
                            .no-touchevents & {
                                background-color: transparent;
                            }
                        }
                    }
                }
            }
        }
    }
}