.c-resource-links {
	// Add margins when used in narrow content area
	.o-narrow &,
	.o-content-sidebar__main & {
		margin-bottom: $spacing-xlarge;
		margin-top: $spacing-xlarge;

		@include breakpoint($bp-large) {
			margin-bottom: toRems(130px);
			margin-top: toRems(130px);
		}

		&:last-child {
			margin-bottom: 0;

			@include breakpoint($bp-medium) {
				margin-bottom: 0;
			}

			@include breakpoint($bp-large) {
				margin-bottom: 0;
			}
		}
	}

	&__title {
		@include heading-4;
		margin-bottom: $spacing-medium;
	}

	&__list {
		@include list;
		@include list-unordered;
	}

	&__item {
		a {
			@include text-link();

			&,
			&:link,
			&:visited {
				text-decoration: none;
			}

			&:hover,
			&:active,
			&:focus {
				text-decoration: none;
			}
		}

		abbr {
			border-bottom-width: 0;
			text-decoration: none;
		}
	}
}
