/*
TNEW7 login page
*/

.s-tnew-v7-content {
  .tn-login-component {
    margin-bottom: $spacing-large;
    @include breakpoint($bp-medium) {
      margin-bottom: 0;
    }
  }

  .tn-account-login-forgot-login-link {
    margin-bottom: $spacing-small;
  }
}
