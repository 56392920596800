// Site
$max-content-width: toRems(1110px);
$max-site-width: toRems(1600px);

// Font families
$circular: "Circular", sans-serif;
$luxuryregular: "luxuryregular", serif;

// Font sizes
$font-size-1: toRems(66px);
$font-size-2: toRems(50px);
$font-size-3: toRems(38px);
$font-size-4: toRems(30px);
$font-size-5: toRems(28px);
$font-size-6: toRems(22px);
$font-size-7: toRems(20px);
$font-size-8: toRems(16px);

// Small font sizes
$font-small: toRems(14px);
$font-xsmall: toRems(13px);
$font-xxsmall: toRems(12px);

// Font weights
$font-weight-thin: 100;
$font-weight-xlight: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-heavy: 800;
$font-weight-black: 900;

// Base Variables
$base-font-family: $circular, arial;
$base-font-size: 16px;
$base-font-weight: 400;
$base-line-height: 1.6;

// Header Sizes
$header-height: toRems(70px);
$header-height-desktop: toRems(120px);

// Neat grid settings
$grid-columns: 12;
$max-width: $max-content-width;
$visual-grid: false !default;
$border-box-sizing: true !default;
$disable-warnings: false !default;
$column: toRems(65px);
$gutter: toRems(30px);

// Have to import Neat after grid settings
@import "neat", // Neat - http://neat.bourbon.io/
	"node_modules/breakpoint-sass/stylesheets/breakpoint.scss"; // Breakpoints - http://breakpoint-sass.com/

// Breakpoints
$bp-xxlarge: min-width 1600px;
$bp-xlarge: min-width 1400px;
$bp-large: min-width 1200px;
$bp-medium: min-width 900px;
$bp-small: min-width 600px;
$bp-xsmall: max-width 600px;

$bp-desktop-nav: min-width 980px;
$bp-desktop-nav-large: min-width 1115px;
$bp-mobile-nav-only: max-width 979px;

// Z-indexes
$z-place-behind:			-1;
$z-tile-background:			25;
$z-tile-thumb:				30;
$z-tile-thumb-overlay:		40;
$z-tile-content:			50;
$z-overlay:					60;
$z-fixed-nav:				800;
$z-header-menu:				900;
$z-header:					1000;
$z-header-item:				1010;
$z-header-overlay:			1020;
$z-content-nav:             1500;
$z-search:					1600;
$z-search-content:			1610;
$z-search-overlay:			1620;
$z-content-overlay:			1900;
$z-modal:					9999;

// Global variables
$global-radius: 0;
$global-easing: cubic-bezier(.11, .74, .46, .96);

// Global spacing
$spacing-xxsmall: toRems(5px);
$spacing-xsmall: toRems(10px);
$spacing-small: toRems(15px);
$spacing-base: toRems(20px);
$spacing-medium: toRems(30px);
$spacing-large: toRems(40px);
$spacing-medlarge: toRems(50px);
$spacing-xlarge: toRems(100px);
$spacing-xxlarge: toRems(200px);
$spacing-xxxlarge: toRems(250px);
$spacing-xxxxlarge: toRems(300px);

//height to width ratio for  videos
$media-height-width-ratio: 56.25%; // 16:9 aspect ratio

