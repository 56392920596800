.c-actions {
	font-size: 0;
	line-height: 0;
	overflow: auto;
	position: relative;
	width: 100%;
	z-index: $z-tile-content;

	&--center {
		text-align: center;
	}

	&--right {
		text-align: right;
	}

	&--overlap-top {
		margin-top: -$spacing-xsmall;

		@include breakpoint($bp-medium) {
			margin-top: -$spacing-small;
		}
	}

	&--split-content-carousel {
		font-size: 0;
		margin-top: -$spacing-base;
		position: absolute;
		right: $spacing-base;
		top: 100%;

		@include breakpoint($bp-small) {
			bottom: -(toRems(60px) + $spacing-base); // Height of buttons plus some spacing
			left: 50%;
			right: auto;
			top: auto;
			transform: translateX(-50%);
			width: toRems(124px);
		}

		@include breakpoint($bp-large) {
			bottom: auto;
			margin-top: -$spacing-medium;
			top: 100%;
		}
	}

	&--hero-carousel {
		display: none;
		position: absolute;
		width: toRems(124px); // Width of two buttons and margin between them
		z-index: $z-overlay;

		@include breakpoint($bp-small) {
			bottom: toRems(25px);
			display: block;
			right: toRems(25px);
		}

		@include breakpoint($bp-medium) {
			bottom: toRems(25px);
			right: toRems(40px);
		}

		@include breakpoint($bp-large) {
			bottom: toRems(25px);
		}

		@include breakpoint($bp-xlarge) {
			bottom: toRems(25px);
			right: toRems(10px);
		}
	}

	&--v-spacing {
		margin-bottom: $spacing-medlarge;
		margin-top: $spacing-xlarge;
	}
}
