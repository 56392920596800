@mixin triangle-down() {
	background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MDAgNTAwIj48cGF0aCBkPSJNMjUwIDUwMGwyNy44LTI3LjggMTk2LjgtMTk2LjgtMjcuOS0yNy44LTE3NyAxNzcuMVYwaC0zOS40djQyNC43bC0xNzctMTc3LjEtMjcuOSAyNy44IDE5Ni44IDE5Ni44eiIvPjwvc3ZnPg==")
		no-repeat center center scroll transparent; // White-filled chevron-down icon converted to base-64 using http://b64.io/
	background-size: toRems(24px);
	content: "";
	display: block;
	height: toRems(60px);
	line-height: 60px !important; // IE was pulling in line-height property from html/body. !important to force center alignment of icon.
	opacity: 1;
	pointer-events: none;
	position: absolute;
	right: 0;
	top: 0;
	width: toRems(60px);
	z-index: $z-tile-content;
}

@mixin form-select() {
	background-color: transparent;
	display: block;
	font-size: 16px;
	margin: 0;
	padding: 0;
	position: relative;
	width: 100%;
	z-index: $z-tile-background;
}

@mixin form-select-element() {
	@include form-field-base;
	background: $c-white;
	cursor: pointer;
	height: toRems(60px);
	line-height: 1;
	padding: toRems(14px) $spacing-small;
	position: relative;

	// Hide native arrow in IE
	&::-ms-expand {
		display: none;
	}

	&:focus {
		outline: none;
	}

	&:disabled {
		background-color: alpha-palette(black, 15%);
		cursor: not-allowed;
		opacity: .5;
	}

	option {
		color: $c-font;

		&:disabled {
			color: palette(grey, mid-light);
		}
	}
}

@mixin select-dark() {
	.c-form-select__input {
		&:before {
			background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MDAgNTAwIj48cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJNMjUwIDUwMGwyNy44LTI3LjggMTk2LjgtMTk2LjgtMjcuOS0yNy44LTE3NyAxNzcuMVYwaC0zOS40djQyNC43bC0xNzctMTc3LjEtMjcuOSAyNy44IDE5Ni44IDE5Ni44eiIvPjwvc3ZnPg==");
		}

		select {
			background-color: $c-black;
			border-color: $c-white;
			color: $c-white;

			&:disabled {
				background-color: alpha-palette(white, 20%);
			}
		}

		/**
		 * Validation Error State
		 */

		&.parsley-error {
			&:after {
				opacity: 1;
			}

			select {
				border-color: palette(errors);
			}
		}
	}
}

@mixin select-mid-dark() {
	.c-form-select__input {
		&:before {
			background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MDAgNTAwIj48cGF0aCBmaWxsPSIjOWU5ZTllIiBkPSJNMjUwIDUwMGwyNy44LTI3LjggMTk2LjgtMTk2LjgtMjcuOS0yNy44LTE3NyAxNzcuMVYwaC0zOS40djQyNC43bC0xNzctMTc3LjEtMjcuOSAyNy44IDE5Ni44IDE5Ni44eiIvPjwvc3ZnPg==");
		}

		select {
			background-color: transparent;
			border-color: palette(grey, mid-light);
			color: palette(grey, mid-light);

			&:disabled {
				background-color: alpha-palette(white, 20%);
			}
		}

		/**
		 * Validation Error State
		 */

		&.parsley-error {
			&:after {
				opacity: 1;
			}

			select {
				border-color: palette(errors);
			}
		}
	}
}

.c-form-select {
	@include form-select;
	padding-bottom: 1px; // Fixes issue with IE10 adding scrollbars next to dropdowns.

	&__input {
		display: block;
		position: relative;

		&:before {
			@include triangle-down;
			z-index: $z-tile-content;
		}

		// Exclamation point (displayed on validation error)
		&:after {
			content: "!";
			color: palette(errors);
			display: block;
			font-family: $circular;
			font-size: toRems(28px);
			font-weight: $font-weight-normal;
			height: toRems(60px);
			line-height: toRems(60px);
			opacity: 0;
			pointer-events: none;
			position: absolute;
			right: toRems(-20px);
			top: 0;
			transition: opacity 300ms $global-easing 0s;
			z-index: $z-tile-content;
		}

		select {
			@include form-select-element;
		}

		/**
		 * Validation Error State
		 */

		&.parsley-error {
			&:after {
				opacity: 1;
			}

			select {
				border-color: palette(errors);
			}
		}
	}

	/**
	 * Dark
	 */

	&--dark {
		@include select-dark;
	}

	/**
	 * Mid-Dark
	 */

	&--mid-dark {
		@include select-mid-dark;
	}

	/**
	 * Field with Parsley Validation error
	 */

	&.parsley-error {
		&:before {
			background-color: palette(errors);
		}

		select {
			border-color: palette(errors);
		}
	}

	/**
	 * Select field using Chosen Plugin
	 */

	&--chosen {
		&.has-initialised {
			min-width: 100%;
			padding-bottom: 0;
			z-index: auto;

			// Use Chosen's arrow instead
			.c-form-select__input:before {
				content: none;
			}

			.chosen-container-active.chosen-with-drop .chosen-single {
				background: $c-black;
				border: toRems(4px) solid $c-black;
				box-shadow: none;
				color: $c-white;

				&:hover {
					opacity: 1;
				}

				// Arrow
				div {
					transform: rotate(180deg);

					&:before {
						// display: none !important;
						background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MDAgNTAwIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMjUwIDUwMGwyNy44LTI3LjggMTk2LjgtMTk2LjgtMjcuOS0yNy44LTE3NyAxNzcuMVYwaC0zOS40djQyNC43bC0xNzctMTc3LjEtMjcuOSAyNy44IDE5Ni44IDE5Ni44eiIvPjwvc3ZnPg=="); // White-filled chevron-down icon converted to base-64 using http://b64.io/
					}
				}
			}

			.chosen-single {
				background: $c-white;
				border-radius: 0 !important;
				border: toRems(4px) solid $c-black;
				color: $c-black;
				box-shadow: none;
				font-size: $font-size-8;
				font-weight: $font-weight-normal;
				height: auto;
				letter-spacing: 0.025em;
				opacity: 1;
				padding: toRems(14px) $spacing-small;
				text-align: left;
				transition: background .3s $global-easing, color .3s $global-easing,
					opacity 0.3s $global-easing;
				width: 100%;
				-webkit-appearance: none;

				&:hover {
					opacity: 0.5;
				}

				// Arrow
				div {
					height: toRems(60px);
					line-height: 1;
					margin-top: toRems(-4px);
					position: absolute;
					right: 0;
					top: 0;
					width: toRems(60px);

					&:before {
						// display: none !important;
						background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MDAgNTAwIj48cGF0aCBkPSJNMjUwIDUwMGwyNy44LTI3LjggMTk2LjgtMTk2LjgtMjcuOS0yNy44LTE3NyAxNzcuMVYwaC0zOS40djQyNC43bC0xNzctMTc3LjEtMjcuOSAyNy44IDE5Ni44IDE5Ni44eiIvPjwvc3ZnPg==")
							no-repeat center center scroll transparent; // Black-filled chevron-down icon converted to base-64 using http://b64.io/
						background-size: toRems(24px);
						content: "";
						display: block;
						height: 100%;
						line-height: 1;
					}
				}
			}

			.chosen-drop {
				border: none;
				box-shadow: none;
				margin: 0;
				min-width: 100%;
				padding: 0;

				@include breakpoint($bp-small) {
					min-width: 180px;
				}
			}

			.chosen-results {
				@include material-shadow(1);
				margin: 0;
				max-height: toRems(400px);
				padding: 0;

				li {
					background: $c-white;
					color: $c-font;
					font-size: $font-size-8;
					font-weight: $font-weight-normal;
					line-height: 1;
					padding: $spacing-base toRems(19px); // Line up with selected text
					transition: background 0.3s $global-easing,
						color .3s $global-easing;

					&:hover {
						background: transparent;
						color: $c-black !important;
						text-decoration: underline;
					}

					&.result-selected {
						background: transparent;
						color: $c-black !important;
					}

					&.highlighted {
						background: transparent;
						color: $c-black !important;
						text-decoration: underline;
					}

					&.disabled-result,
					&.disabled-result:hover {
						background: $c-white;
						color: palette(grey, light);
					}
				}
			}

			/**
			 * Remove references to chosen sprite image
			 * It was causing a console error as the image could not be found.
			 */

			.chosen-container-single .chosen-single abbr,
			.chosen-container-single .chosen-single div b,
			.chosen-container-single .chosen-search input[type="text"],
			.chosen-container-multi .chosen-choices li.search-choice .search-choice-close,
			.chosen-rtl .chosen-search input[type="text"] {
				background-image: none;
			}

			@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
				only screen and (min-resolution: 144dpi),
				only screen and (min-resolution: 1.5dppx) {
				.chosen-rtl .chosen-search input[type="text"],
				.chosen-container-single .chosen-single abbr,
				.chosen-container-single .chosen-single div b,
				.chosen-container-single .chosen-search input[type="text"],
				.chosen-container-multi .chosen-choices .search-choice .search-choice-close,
				.chosen-container .chosen-results-scroll-down span,
				.chosen-container .chosen-results-scroll-up span {
					background-image: none;
				}
			}
		}
	}

	&--chosen-dark {
		&.has-initialised {
			@include select-dark;

			.chosen-container-active.chosen-with-drop .chosen-single {
				background: $c-white;
				border: toRems(4px) solid $c-white;
				box-shadow: none;
				color: $c-black;

				// Arrow
				div:before {
					background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MDAgNTAwIj48cGF0aCBkPSJNMjUwIDUwMGwyNy44LTI3LjggMTk2LjgtMTk2LjgtMjcuOS0yNy44LTE3NyAxNzcuMVYwaC0zOS40djQyNC43bC0xNzctMTc3LjEtMjcuOSAyNy44IDE5Ni44IDE5Ni44eiIvPjwvc3ZnPg==");
				}
			}

			.chosen-single {
				background-color: $c-black;
				border: toRems(4px) solid $c-white;
				color: $c-white;

				// Arrow
				div:before {
					background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MDAgNTAwIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMjUwIDUwMGwyNy44LTI3LjggMTk2LjgtMTk2LjgtMjcuOS0yNy44LTE3NyAxNzcuMVYwaC0zOS40djQyNC43bC0xNzctMTc3LjEtMjcuOSAyNy44IDE5Ni44IDE5Ni44eiIvPjwvc3ZnPg=="); // White-filled chevron-down icon converted to base-64 using http://b64.io/
				}
			}

			.chosen-results {
				background: $c-black;

				li {
					background-color: $c-black;
					color: $c-white;

					&:hover {
						background-color: $c-black;
						color: $c-white !important;
					}

					&.result-selected {
						background-color: $c-black;
						color: $c-white !important;
					}
				}
			}
		}
	}

	&--chosen-mid-dark {
		&.has-initialised {
			@include select-mid-dark;

			.chosen-container-active.chosen-with-drop .chosen-single {
				background: $c-white;
				border: toRems(4px) solid $c-white;
				box-shadow: none;
				color: $c-black;

				// Arrow
				div:before {
					background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MDAgNTAwIj48cGF0aCBkPSJNMjUwIDUwMGwyNy44LTI3LjggMTk2LjgtMTk2LjgtMjcuOS0yNy44LTE3NyAxNzcuMVYwaC0zOS40djQyNC43bC0xNzctMTc3LjEtMjcuOSAyNy44IDE5Ni44IDE5Ni44eiIvPjwvc3ZnPg=="); // Black Triangle Down
				}
			}

			.chosen-single {
				background-color: transparent;
				border: toRems(4px) solid palette(grey, mid-light);
				color: palette(grey, mid-light);

				// Arrow
				div:before {
					background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNTAwIDUwMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTAwIDUwMDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+LnN0MHtvcGFjaXR5OjAuNTtmaWxsOiNGRkZGRkY7fTwvc3R5bGU+PHBhdGggY2xhc3M9InN0MCIgZD0iTTI1MCw1MDBsMjcuOC0yNy44bDE5Ni44LTE5Ni44bC0yNy45LTI3LjhsLTE3NywxNzcuMVYwbC0zOS40LDB2NDI0LjdsLTE3Ny0xNzcuMWwtMjcuOSwyNy44bDE5Ni44LDE5Ni44TDI1MCw1MDB6Ii8+PC9zdmc+); // Translucent chevron-down icon converted to base-64 using http://b64.io/
				}
			}
		}
	}
}
