.c-sponsor {
	color: $c-black;
	padding-top: toRems(135px);
	position: relative;
	text-align: center;

	@include breakpoint($bp-medium) {
		padding-top: toRems(125px);
	}

	// Vertical line
	&:before {
		background-color: $c-black;
		content: '';
		height: toRems(80px);
		left: 50%;
		position: absolute;
		top: 0;
		transform: translateX(-50%);
		width: toRems(4px);
	}

	&__title {
		display: block;
		font-size: $font-small;
		margin-bottom: $spacing-base;

		@include breakpoint($bp-medium) {
			font-size: $font-size-8;
			margin-bottom: toRems(25px);
		}
	}

	&__image {
		&,
		&:link,
		&:visited {
			display: inline-block;
			vertical-align: middle;
		}

        & + #{&} {
            margin-left: $spacing-base;
        }

		svg {
			height: toRems(57px);
			width: toRems(117px);
		}

		img {
			display: block;
			height: auto;
			max-width: toRems(120px);
			width: 100%;
		}
	}
}
