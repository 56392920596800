/**
 * Show/hide @bp-xlarge
 */

.u-show\@xlarge.u-show\@xlarge {
	display: none;

	@include breakpoint($bp-xlarge) {
		display: block;
	}
}

.u-hide\@xlarge.u-hide\@xlarge {
	@include breakpoint($bp-xlarge) {
		display: none;
	}
}

/**
 * Show/hide @bp-large
 */

.u-show\@large.u-show\@large {
	display: none;

	@include breakpoint($bp-large) {
		display: block;
	}
}

.u-hide\@large.u-hide\@large {
	@include breakpoint($bp-large) {
		display: none;
	}
}

/**
 * Show/hide @bp-medium
 */

.u-show\@medium.u-show\@medium {
	display: none;

	@include breakpoint($bp-medium) {
		display: block;
	}
}

.u-hide\@medium.u-hide\@medium {
	@include breakpoint($bp-medium) {
		display: none;
	}
}

/**
 * Show/hide @bp-small
 */

.u-show\@small.u-show\@small {
	display: none;

	@include breakpoint($bp-small) {
		display: block;
	}
}

.u-hide\@small.u-hide\@small {
	@include breakpoint($bp-small) {
		display: none;
	}
}

.u-show-inline-block\@small.u-show-inline-block\@small {
	display: none;

	@include breakpoint($bp-small) {
		display: inline-block;
	}
}

/**
 * Show/hide @bp-xsmall
 */

 .u-show\@xsmall.u-show\@xsmall {
	display: none;

	@include breakpoint($bp-xsmall) {
		display: block;
	}
}

/**
 * Show/hide @bp-desktop-nav
 */

.u-show\@desktop-nav.u-show\@desktop-nav {
	display: none;

	@include breakpoint($bp-desktop-nav) {
		display: block;
	}
}

.u-hide\@desktop-nav.u-hide\@desktop-nav {
	@include breakpoint($bp-desktop-nav) {
		display: none;
	}
}


/**
 * Show/hide @bp-desktop-nav-large
 */

.u-show\@desktop-nav-large.u-show\@desktop-nav-large {
	display: none;

	@include breakpoint($bp-desktop-nav-large) {
		display: block;
	}
}

.u-hide\@desktop-nav-large.u-hide\@desktop-nav-large {
	@include breakpoint($bp-desktop-nav-large) {
		display: none;
	}
}
