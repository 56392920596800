.u-bg-white {
	background-color: $c-white;
}

.u-bg-pink {
	background-color: palette(pink);

	&--small {
		@include breakpoint($bp-small) {
			background-color: palette(pink);
		}
	}
}

.u-bg-black {
	background-color: $c-black;

	&--small {
		@include breakpoint($bp-small) {
			background-color: $c-black;
		}
	}
}

.u-bg-black-translucent {
	background-color: alpha-palette(black, 80%);
}

.u-bg-blue-grey {
	background-color: palette(blue-grey);
	background-color: rgba(palette(blue-grey), .5);
}
