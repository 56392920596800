@mixin checkbox-input() {
	left: -9999em;
	position: absolute;
	visibility: hidden;

	&:checked {
		+ .c-form-checkbox__label {
			&:before {
				background-color: $c-white;
			}

			&:after {
				color: $c-white;
				opacity: 1;
			}
		}
	}
}

@mixin checkbox-label() {
	@include form-label-small;
	cursor: pointer;
	padding-left: (toRems(30px) + $spacing-base); // Width of pseudo checkbox + spacing.
	position: relative;
	text-transform: none;

	&:before {
		background: alpha-palette(black, 0%);
		border-radius: 0;
		border: toRems(4px) solid $c-black;
		content: ".";
		cursor: pointer;
		height: toRems(30px);
		left: 0;
		transform: translateY(-50%);
		position: absolute;
		text-indent: -999em;
		top: 50%;
		width: toRems(30px);
	}

	&:after {
		background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MDAgNTAwIj48cGF0aCBkPSJNNDg3LjkgODMuNGMtMTYuMi0xNi4yLTQyLjUtMTYuMi01OC43IDBMMTg0IDMyOC42IDcwLjggMjE1LjNjLTcuOC03LjgtMTguMy0xMi4yLTI5LjMtMTIuMi0xMS4xIDAtMjEuNSA0LjMtMjkuMyAxMi4yQy00IDIzMS41LTQgMjU3LjggMTIuMSAyNzRsMTQyLjYgMTQyLjZjNy44IDcuOCAxOC4zIDEyLjIgMjkuMyAxMi4yIDExLjEgMCAyMS41LTQuMyAyOS4zLTEyLjJsMjc0LjUtMjc0LjVjMTYuMi0xNi4yIDE2LjItNDIuNS4xLTU4Ljd6Ii8+PC9zdmc+") no-repeat center center scroll transparent;
		background-size: toRems(16px);
		color: $c-black;
		content: "";
		display: block;
		height: toRems(16px);
		left: toRems(7px);
		opacity: 0;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		transition: opacity 350ms $global-easing 0s;
		width: toRems(16px);
	}
}

.c-form-checkbox {
	display: block;

	// Hover
	&:hover {
		.c-form-checkbox__label {
			&:after {
				opacity: .15;
			}
		}
	}

	// Checked
	input[type='checkbox'] {
		@include checkbox-input();
	}

	// Base / Unchecked
	&__label {
		@include checkbox-label;
	}


	/**
	 * Field with Parsley Validation error
	 */

	&.parsley-error {
		.c-form-checkbox__label {
			color: palette(errors);

			&:before {
				border-color: palette(errors);
			}
		}
	}


	/**
	 * Dark
	 */

	&--dark {
		color: $c-white;

		.c-form-checkbox__label {
			&:before {
				background: alpha-palette(white, 0%);
				border: toRems(4px) solid $c-white;
			}

			&:after {
				background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MDAgNTAwIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNNDg3LjkgODMuNGMtMTYuMi0xNi4yLTQyLjUtMTYuMi01OC43IDBMMTg0IDMyOC42IDcwLjggMjE1LjNjLTcuOC03LjgtMTguMy0xMi4yLTI5LjMtMTIuMi0xMS4xIDAtMjEuNSA0LjMtMjkuMyAxMi4yQy00IDIzMS41LTQgMjU3LjggMTIuMSAyNzRsMTQyLjYgMTQyLjZjNy44IDcuOCAxOC4zIDEyLjIgMjkuMyAxMi4yIDExLjEgMCAyMS41LTQuMyAyOS4zLTEyLjJsMjc0LjUtMjc0LjVjMTYuMi0xNi4yIDE2LjItNDIuNS4xLTU4Ljd6Ii8+PC9zdmc+");
				color: $c-white;
			}
		}

		input[type='checkbox'] {
			&:checked {
				+ .c-form-checkbox__label {
					&:before {
						background-color: $c-black;
					}

					&:after {
						color: $c-black;
					}
				}
			}
		}
	}


	/**
	 * Mid-Dark
	 */

	&--mid-dark {
		color: palette(grey, mid-light);

		.c-form-checkbox__label {
			&:before {
				background: alpha-palette(white, 0%);
				border: toRems(4px) solid palette(grey, mid-light);
			}

			&:after {
				background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MDAgNTAwIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNNDg3LjkgODMuNGMtMTYuMi0xNi4yLTQyLjUtMTYuMi01OC43IDBMMTg0IDMyOC42IDcwLjggMjE1LjNjLTcuOC03LjgtMTguMy0xMi4yLTI5LjMtMTIuMi0xMS4xIDAtMjEuNSA0LjMtMjkuMyAxMi4yQy00IDIzMS41LTQgMjU3LjggMTIuMSAyNzRsMTQyLjYgMTQyLjZjNy44IDcuOCAxOC4zIDEyLjIgMjkuMyAxMi4yIDExLjEgMCAyMS41LTQuMyAyOS4zLTEyLjJsMjc0LjUtMjc0LjVjMTYuMi0xNi4yIDE2LjItNDIuNS4xLTU4Ljd6Ii8+PC9zdmc+");
				color: palette(grey, mid-light);
			}
		}

		input[type='checkbox'] {
			&:checked {
				+ .c-form-checkbox__label {
					&:before {
						background-color: $c-black;
					}

					&:after {
						color: $c-black;
					}
				}
			}
		}
	}
}
