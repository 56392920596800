.c-social-share {
	border-top: toRems(4px) solid $c-black;
	margin-left: (-$spacing-base);
	margin-right: (-$spacing-base);
	padding-top: $spacing-base;
	text-align: center;

	@include breakpoint($bp-medium) {
		margin: 0;
		padding-top: $spacing-large;
	}

	&__title {
		@include heading-4;
		display: block;
		font-weight: $font-weight-bold;
		margin-bottom: $spacing-medium;

		@include breakpoint($bp-medium) {
			margin-bottom: $spacing-base;
		}
	}

	&__action {
		display: block;
		margin-bottom: $spacing-xxsmall;

		@include breakpoint($bp-medium) {
			display: none;
		}
	}

	&__item {
		display: block;
		margin-bottom: $spacing-xxsmall;
		opacity: 0;
		transform: translateY(-20px);
		transition: opacity 500ms $global-easing 0s,
			transform 500ms $global-easing 0s;


		@include breakpoint($bp-medium) {
			float: left;
			margin: 0 $spacing-xxsmall 0 0;
		}
	}

	&__drawer {
		display: block;
		left: 50%;
		overflow: hidden;
		position: absolute;
		transform: translateX(-50%);
		visibility: hidden;
		z-index: $z-tile-content + 10; // + 10 to bring in front of 'up next' module beneath it on concert pages

		@include breakpoint($bp-medium) {
			display: flex;
			justify-content: center;
			left: auto;
			position: relative;
			transform: none;
			transition: none;
		}
	}

	&.is-open {
		.c-social-share__drawer {
			visibility: visible;

			@include breakpoint($bp-medium) {
				transform: none;
			}
		}

		.c-social-share__item {
			opacity: 1;
			transform: translateY(0);

			&:nth-child(1) { transition-delay: 100ms }
			&:nth-child(2) { transition-delay: 200ms }
			&:nth-child(3) { transition-delay: 300ms }
			&:nth-child(4) { transition-delay: 400ms }
			&:nth-child(5) { transition-delay: 500ms }
		}
	}
}
