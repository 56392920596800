.c-form-label {
	@include form-label;
	color: $c-font;
	display: block;
	margin-bottom: $spacing-small;

	&--light {
		color: $c-white;
	}

	&--mid-dark {
		color: palette(grey, mid-light);
	}

	&--mid-dark {
		color: alpha-palette(white, 50%);
	}

	&--show\@small {
		overflow: hidden;
		position: absolute;
		text-indent: -9999px;

		@include breakpoint($bp-small) {
			position: relative;
			text-indent: 0;
		}
	}

	&--show\@medium {
		overflow: hidden;
		position: absolute;
		text-indent: -9999px;

		@include breakpoint($bp-medium) {
			position: relative;
			text-indent: 0;
		}
	}
}
