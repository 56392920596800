.o-inline-list {
	display: block;
	font-size: 0;
	line-height: 0;

	&__item {
		display: inline;
		vertical-align: middle;
	}

	/* Small spacing between items */
	&--spacing {
		margin: (-$spacing-xxsmall);
		.o-inline-list__item {
			display: inline-block;
			padding: $spacing-xxsmall;
		}
	}
}
