@mixin form-field-base() {
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	border-radius: $global-radius;
	border: toRems(4px) solid $c-black;
	box-shadow: none;
	color: $c-font;
	display: block;
	font-family: $circular;
	font-size: 16px;
	font-weight: $font-weight-normal;
	line-height: 1.875;
	width: 100%;
}

form {
	overflow: visible;

	fieldset {
		border: none;
		padding: 0;
	}

	legend {

	}

	ul, ol {
		@include clearfix;

		li {
			@include clearfix;
			margin-bottom: $spacing-base;

			@include breakpoint($bp-large) {
				margin-bottom: $spacing-large;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
