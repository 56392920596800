/*
TNEW7 headings
*/

.s-tnew-v7-content {
  h2 {
    font-size: $font-size-4;
    font-weight: $font-weight-medium;
    line-height: 1.06;
    margin-bottom: $spacing-base;

    @include breakpoint($bp-medium) {
      font-size: $font-size-3;
    }
  }

  h3 {
    font-size: $font-size-5;
    font-weight: $font-weight-medium;
    line-height: 1.06;
    margin-bottom: $spacing-base;

    @include breakpoint($bp-medium) {
      font-size: $font-size-4;
    }
  }
}
