@mixin pagination-item() {
	display: inline-block;
	font-size: $font-size-7;
	line-height: toRems(40px);
	text-align: center;
	vertical-align: middle;
}

.c-pagination {
	margin: 0 auto;
	max-width: toRems(590px);

	&__item-container {
		align-items: center;
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		width: 100%;
	}

	&__item {
		@include pagination-item;

		a {
			&,
			&:link,
			&:visited {
				color: $c-black;
				display: inline-block;
				min-width: toRems(30px);
				padding: 0 $spacing-xsmall;
				text-align: center;
				text-decoration: none;
				transition: color 500ms $global-easing;

				@include breakpoint($bp-small) {
					min-width: toRems(40px);
				}

				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}
		}
	}

	&__current {
		@include pagination-item;
		font-weight: $font-weight-bold;
		min-width: toRems(30px);

		@include breakpoint($bp-small) {
			min-width: toRems(40px);
		}
	}
}
