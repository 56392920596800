/*
TNEW7 account create page
*/

.s-tnew-v7-content {
  .tn-create {
    &-heading {
      margin-top: $spacing-large;
      @include breakpoint($bp-medium) {
        margin-top: 0;
      }
    }

    &-sub-heading {
      margin-bottom: $spacing-small;
    }

    &-brief-heading {
      margin-top: $spacing-base;
    }
  }
}
