@media print {
  .s-tnew-v7-content {
    .tn-subnav-component {
      display: none;
    }

    .c-tnew-v7-hero {
      background: none;
      padding: 0;

      :after,
      :before {
        display: none;
      }
    }
  }

  .c-header__nav,
  .c-header__actions {
    opacity: 0;
  }
}
