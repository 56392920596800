.o-tile-carousel {
    max-height: toRems(250px);
    opacity: 0;
    overflow: hidden;
    transition: max-height 300ms $global-easing 0s,
        opacity 300ms $global-easing 0s;
    width: 100%;

    @include breakpoint($bp-medium) {
        max-height: toRems(350px);
        transition-duration: 500ms;
    }

    &.slick-initialized {
        max-height: toRems(350px);
        opacity: 1;
    }

    &__item {
        padding: 0 toRems(1px);
        width: toRems(250px);

        @include breakpoint($bp-medium) {
            width: toRems(350px);
        }
    }
}
