*,
*:before,
*:after {
	box-sizing: border-box;
}

*:focus {
	outline: none;
}

// Text highlight
::selection {
	color: $text-highlight-color;
	background: lighten($text-highlight-bg-color, 3%);
}

::-moz-selection {
	color: $text-highlight-color;
	background: lighten($text-highlight-bg-color, 3%);
}

html,
body {
	-moz-osx-font-smoothing: auto;
	-moz-osx-font-smoothing: grayscale;
	-webkit-backface-visibility: hidden;
	-webkit-font-smoothing: antialiased !important;
	color: $c-font;
	font-family: $base-font-family;
	font-size: $base-font-size;
	font-weight: $base-font-weight;
	line-height: $base-line-height;
	min-height: 100vh;
	min-width: 320px;
	position: relative;
	text-decoration-skip: ink; // make underlines not run over descenders, more info: https://developer.mozilla.org/en-US/docs/Web/CSS/text-decoration-skip
	text-rendering: optimizeLegibility !important;
	width: 100%;
	z-index: 0;

	&:before {
		background-color: $c-black;
		content: "";
		display: block;
		height: 100%;
		left: 0;
		pointer-events: none;
		position: fixed;
		top: 0;
		transform: translateX(-100%);
		transition: transform 500ms $global-easing 0s, z-index 0s linear 500ms;
		width: 100%;
		z-index: $z-place-behind;

		@include breakpoint($bp-desktop-nav) {
			display: none;
		}
	}

	.has-active-menu & {
		@include breakpoint($bp-mobile-nav-only) {
			overflow-x: visible;

			&:before {
				pointer-events: all;
				transform: translateX(0);
				transition: transform 350ms $global-easing 0s,
					z-index 0s linear 0s;
				z-index: $z-header-menu - 1;
			}
		}
	}
}

body {
	font-size: toRems($base-font-size);
}

// Links
a[href*="mailto:"] {
	word-wrap: break-word;
}

button {
	background-color: transparent;
	border: none;
	outline: none;
}

// Headings
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

// Reset Lists
ul,
ol {
	list-style: none;
	margin: 0;
	padding: 0;
}

// Images
figure {
	margin: 0;
}

img {
	display: block;
	height: auto;
	max-width: 100%;
}

a {
	text-decoration: none;
}

// Paragraph
p {
	margin: 0;

	b,
	strong {
		font-weight: $font-weight-bold;
	}

	i,
	em {
		font-style: italic;
	}
}

// Default Transitions
a,
input[type="text"],
textarea,
button,
[class^="c-btn"] {
	-webkit-tap-highlight-color: $tap-highlight-color;
	transition-duration: 300ms;
	transition-property: background-color, color, opacity, border, border-color,
		background-position, outline, box-shadow, border-bottom, text-decoration,
		left, top, transform;
	transition-timing-function: $global-easing;
	transform-origin: center center;
}
