@mixin button-base() {
	&,
	&:link,
	&:visited {
		@include button-text;
		background-color: $c-link;
		border: toRems(4px) solid $c-black;
		border-radius: $global-radius;
		color: $c-white;
		cursor: pointer;
		display: inline-block;
		min-height: toRems(60px);
		max-width: 100%;
		margin: 0;
		opacity: 1;
		padding: toRems(13.5px) $spacing-base;
		position: relative;
		text-align: center;
		transition: background 400ms $global-easing 0s,
			color 400ms $global-easing 0s, opacity 400ms $global-easing 0s;
		vertical-align: middle;
		width: 100%;
		-webkit-appearance: none; // Makes sure iOS doesn't add extra styling to buttons.

		@include breakpoint($bp-small) {
			display: inline-block;
			margin: 0;
			width: auto;
		}

		&:disabled {
			opacity: .7;
		}

		.c-actions & {
			@include breakpoint($bp-small) {
				margin-left: toRems(2px);
				margin-right: toRems(2px);
			}

			&:first-child {
				@include breakpoint($bp-small) {
					margin-left: 0;
				}
			}

			&:last-child {
				@include breakpoint($bp-small) {
					margin-right: 0;
				}
			}
		}

		// If the element is a link or button element
		@at-root {
			a#{&},
			button#{&} {
				// Only apply fancy styling if on a non-touch device
				.no-touchevents & {
					background-color: transparent;

					&:before {
						background-color: $c-black;
						content: "";
						display: block;
						height: 100%;
						left: 0;
						position: absolute;
						top: 0;
						transition: height 400ms $global-easing 0s;
						width: 100%;
						z-index: $z-place-behind - 1;
					}

					&:after {
						background-color: $c-white;
						content: "";
						display: block;
						height: 0;
						left: 0;
						position: absolute;
						top: 0;
						transition: height 400ms $global-easing 0s;
						width: 100%;
						z-index: $z-place-behind;
					}
				}
			}
		}
	}

	&:hover,
	&:active,
	&:focus {
		// Only apply styling changes to non-touch devices
		.no-touchevents & {
			background-color: $c-white;
			color: $c-black;
		}

		&:disabled {
			background-color: $c-link;
			color: $c-white;
		}

		// If the element is a link or button element
		@at-root {
			a#{&},
			button#{&} {
				// Only apply fancy styling if on a non-touch device
				.no-touchevents & {
					background-color: transparent;

					&:after {
						height: 100%;
					}

					&:disabled {
						&:after {
							height: 0;
						}
					}
				}
			}
		}
	}
}

@mixin button-icon() {
	@include button-base;

	&__label {
		@include visually-hidden;
	}

	&,
	&:link,
	&:visited {
		padding-left: toRems(14px);
		padding-right: toRems(14px);

		svg {
			display: block;
			fill: $c-white;
			height: toRems(24px);
			transition: fill 400ms $global-easing 0s;
			width: toRems(24px);
		}
	}

	&:hover,
	&:active,
	&:focus {
		// Only apply styling changes to non-touch devices
		.no-touchevents & {
			svg {
				fill: $c-black;
			}
		}

		// If the element is a link or button element
		@at-root {
			a#{&},
			button#{&} {
				// Only apply fancy styling if on a non-touch device
				.no-touchevents & {
					svg {
						fill: $c-black;
					}

					&:disabled {
						svg {
							fill: $c-white;
						}
					}
				}
			}
		}
	}
}

@mixin button-icon-inline() {
	&,
	&:link,
	&:visited {
		display: inline-block;
		width: auto;

		.c-actions & {
			margin-left: toRems(2px);
			margin-right: toRems(2px);

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
}

@mixin button-icon-transparent() {
	&,
	&:link,
	&:visited {
		background-color: transparent;

		svg {
			fill: palette(blue-grey);
		}

		// If the element is a link or button element
		@at-root {
			a#{&},
			button#{&} {
				// Only apply fancy styling if on a non-touch device, override pseudo background elements
				.no-touchevents & {
					&:before,
					&:after {
						display: none;
					}
				}
			}
		}
	}

	&:hover,
	&:active,
	&:focus {
		// Only apply styling changes to non-touch devices
		.no-touchevents & {
			background-color: transparent;

			svg {
				fill: palette(blue-grey);
			}
		}

		// If the element is a link or button element
		@at-root {
			a#{&},
			button#{&} {
				// Only apply fancy styling if on a non-touch device
				.no-touchevents & {
					svg {
						fill: palette(blue-grey);
					}
				}
			}
		}
	}
}

.c-button-icon {
	@include button-icon;

	/**
	 * Inline -
	 * Inline at all screen sizes
	 */

	&--inline {
		@include button-icon-inline;
	}

	/**
	 * Small @ Small -
	 * Shrink button for small devices and above
	 */

	&--small\@xsmall {
		&,
		&:link,
		&:visited {
			@include breakpoint($bp-xsmall) {
				min-height: toRems(50px);
				padding: toRems(8.5px) toRems(9px);
			}
		}
	}

	/**
	 * Small -
	 * 50 x 50 button (default size is 60 x 60)
	 */

	&--small {
		&,
		&:link,
		&:visited {
			min-height: toRems(50px);
			padding-left: toRems(11px);
			padding-right: toRems(11px);

			svg {
				display: block;
				fill: $c-white;
				height: toRems(20px);
				transition: fill 400ms $global-easing 0s;
				width: toRems(20px);
			}
		}
	}

	&--transparent-bg {
		@include button-icon-transparent;
	}

	&--icon-only {
		@include button-icon-transparent;

		&,
		&:link,
		&:visited {
			border-color: transparent;
		}

		&:hover,
		&:active,
		&:focus {
			// Only apply styling changes to non-touch devices
			&,
			.no-touchevents & {
				background-color: transparent;
				border-color: transparent;

				svg {
					fill: $c-white;
				}
			}

			// If the element is a link or button element
			@at-root {
				a#{&},
				button#{&} {
					// Only apply fancy styling if on a non-touch device
					.no-touchevents & {
						background-color: transparent;
						border-color: transparent;

						&:disabled {
							background-color: transparent;
							border-color: transparent;
						}

						svg {
							fill: $c-white;
						}
					}
				}
			}

			&:disabled {
				background-color: transparent;
				border-color: transparent;
			}
		}
	}
}
