// Mixin 'nav-item-title' is defined in _nav-menu.scss

.c-site-navigation {
	background-color: $c-black;
	color: $c-white;
	left: 0;
	height: 100vh;
	overflow: auto;
	overflow-x: hidden;
	padding-bottom: $spacing-xlarge;
	padding-top: $header-height;
	position: fixed;
	top: 0;
	transform: translateX(-100%);
	transition: transform 500ms $global-easing 0s, top 0s linear 500ms;
	width: 100%;
	z-index: $z-header-menu;

	@include breakpoint($bp-desktop-nav) {
		background-color: transparent;
		color: $c-font;
		height: auto;
		overflow: visible;
		padding-bottom: 0;
		position: relative;
		min-height: $header-height-desktop;
		padding: 0;
		transform: none;
	}

	// Slide menu in from left when active (smaller screens):
	.has-active-menu & {
		position: relative;
		top: 0;
		transform: translateX(0%);
		transition: transform 350ms $global-easing 0s, top 0s linear 0s;

		@include breakpoint($bp-desktop-nav) {
			transform: none;
		}
	}

	.has-active-search & {
		z-index: $z-header-menu - 1;
	}

	&__primary {
		transform: translateX(0);
		transition: transform 500ms $global-easing 0s;

		@include breakpoint($bp-desktop-nav) {
			flex: 0 1 auto;
		}

		.c-site-navigation[data-current-level="1"] &,
		.c-site-navigation[data-current-level="2"] &,
		.c-site-navigation[data-current-level="3"] & {
			transform: translateX(-100%);
		}
	}

	&__secondary {
		padding-top: $spacing-base;
		position: relative;
		transform: translateX(0);
		transition: transform 500ms $global-easing 0s;

		@include breakpoint($bp-desktop-nav) {
			flex: 0 1 auto;
			position: relative;
			z-index: $z-header-menu + 2;
		}

		.c-site-navigation[data-current-level="1"] &,
		.c-site-navigation[data-current-level="2"] &,
		.c-site-navigation[data-current-level="3"] & {
			transform: translateX(-100%);
		}
	}

	&__list {
		padding-top: toRems(35px);
		position: relative;
		width: 100%;

		@include breakpoint($bp-desktop-nav) {
			align-content: center;
			align-items: stretch;
			display: flex;
			flex-flow: row nowrap;
			height: $header-height-desktop;
			justify-content: center;
			padding-top: 0;
			padding-right: toRems(100px);
			transform: none;
			width: auto;
		}

		@include breakpoint($bp-desktop-nav-large) {
			padding-right: 0;
		}

		.c-site-navigation__secondary & {
			padding-top: $spacing-medium;

			&:after {
				background-color: $c-white;
				content: "";
				display: block;
				height: toRems(2px);
				left: 50%;
				position: absolute;
				top: 0;
				transform: translateX(-50%);
				width: 100%;
				width: calc(100% - #{($spacing-base * 2)});
			}
		}
	}

	&__item {
		@include breakpoint($bp-desktop-nav) {
			flex: 0 1 auto;
			position: relative;
		}

		// 'More' link for tablets
		&.is-condensed-list {
			display: none;
		
			@include breakpoint($bp-desktop-nav) {
				display: block;
				position: absolute;
				right: 0;
			}
		
			@include breakpoint($bp-desktop-nav-large) {
				display: none;
			}
		}
	}

	&__label {
		position: relative;
		z-index: $z-header-menu + 2;

		@include breakpoint($bp-desktop-nav) {
			display: table;
			height: $header-height-desktop;
			text-align: center;
		}

		&:before {
			@include breakpoint($bp-desktop-nav) {
				background-color: $c-white;
				bottom: 0;
				content: "";
				display: block;
				height: 100%;
				left: 0;
				opacity: 0;
				pointer-events: none;
				position: absolute;
				transition: opacity 0s $global-easing 0s;
				width: toRems(215px);
				z-index: -1;
			}
		}
	}

	&__title {
		@include nav-item-title;

		.c-site-navigation__secondary & {
			&,
			&:link,
			&:visited {
				font-size: $font-size-8;
				font-weight: $font-weight-normal;
				padding-bottom: toRems(7px);
				padding-top: toRems(7px);
			}

			&:hover,
			&:active {
				background-color: $c-white;
				color: $c-link;
			}
		}

		.c-site-navigation__item & {
			@include breakpoint($bp-desktop-nav) {
				position: relative;
			}

			&:before {
				@include breakpoint($bp-desktop-nav) {
					background-color: $c-black;
					content: "";
					display: block;
					height: toRems(8px);
					left: $spacing-small;
					opacity: 0;
					position: absolute;
					top: 0;
					transition: opacity 500ms $global-easing 0s;
					width: 100%;
					width: calc(100% - #{$spacing-small * 2});
				}
			}
		}

		.c-site-navigation__item:hover &,
		.c-site-navigation__item:focus &,
		.c-site-navigation__item.is-active &,
		.c-site-navigation__item.is-current & {
			&:before {
				@include breakpoint($bp-desktop-nav) {
					opacity: 1;
				}
			}
		}
	}
}

.has-active-menu {
	body {
		@include breakpoint($bp-mobile-nav-only) {
			height: 100vh;
			overflow: hidden;
			position: fixed;
		}
	}

	#main {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;

		@include breakpoint($bp-desktop-nav) {
			position: static;
			left: auto;
			top: auto;
		}
	}
}