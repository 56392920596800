.c-split-image-text {
	align-items: flex-start;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	position: relative;
	z-index: $z-tile-background;

	@include breakpoint($bp-small) {
		flex-direction: row;
	}

	.o-narrow &,
	.o-content-sidebar__main & {
		margin: $spacing-medlarge 0;

		@include breakpoint($bp-medium) {
			margin: toRems(80px) 0 toRems(80px) (-((5.85586% + 2.7027%) / .657657658)); // column width + column gutter width / divided by ratio of narrow grid to normal grid size
		}

		@include breakpoint($bp-large) {
			margin-bottom: toRems(120px);
		}
	}

	&__image {
		flex: 1 1 auto;
		margin: 0 0 $spacing-base;
		padding: 0;
		position: relative;
		width: 100%;
		z-index: $z-tile-thumb;

		@include breakpoint($bp-small) {
			flex-basis: 42.4242424%;
			margin-bottom: 0;
			margin-right: 2.7027%; // 1 gutter width as a proportion of the div's width (i.e. 30px / 380px)
			width: 42.4242424%; // 4 columns + 3 gutter widths
		}

		img {
			display: none;
		}
	}

	&__thumbnail {
		background: no-repeat center center scroll $c-black;
		background-size: cover;
		display: block;
		padding: 100% 0 0;
		width: 100%;
		z-index: $z-tile-thumb + 1;
	}

	&__text {
		flex: 1 1 auto;
		position: relative;
		z-index: $z-tile-content;
		width: 100%;

		@include breakpoint($bp-small) {
			flex-basis: 53.9393939%;
			width: 53.9393939%;
		}
	}

	&__title {
		@include heading-4;
		font-weight: $font-weight-bold;
		margin-bottom: $spacing-base;

		@include breakpoint($bp-small) {
			font-weight: $font-weight-medium;
		}
	}
}
