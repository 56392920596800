.s-tnew-v7-content {
  .tn-prod-season-header {
    display: none;
  }

  .tn-event-detail {
    flex-direction: column;

    &__main-container {
      order: -1;

      .tn-ticketing-mode-change__content {
        background: none;
        border: none;
        display: block;
        font-style: normal;
        padding: 0;
      }

      .tn-ticket-selector {
        max-width: none;

        &__zone-selector {
          margin-top: $spacing-medlarge;
        }

        &__pricetype {
          &-list {
            border-top: toRems(4px) solid $c-black;
            margin-top: $spacing-medlarge;
            padding-top: $spacing-medlarge;

            &-item {
              border: none;
              margin: 0;
              padding: 0;
              padding-bottom: $spacing-base;
            }
          }

          &-placeholder {
            background: none;
            border: none;
            color: inherit;
            font-weight: $font-weight-bold;
            padding: 0;
            text-align: left;
          }

          &-list-item {
            border-top: 0;
            margin-bottom: 0;
          }
        }
      }
    }

    &__performance-details-container {
      margin-bottom: 0;
    }

    &__location {
      padding-bottom: $spacing-base;
    }

    .tn-additional-events {
      &__instructions {
        background: none;
        border: none;
        margin: 0px -10px 0px;
      }

      &__controls-container {
        border: none;
        max-width: 600px;
      }
    
      &__select-view-container {
        display: block !important;
      }
    }

    &__additional-events-container {
      background: none;
      border-top: toRems(4px) solid $c-black;
      margin-top: $spacing-large;
      padding: $spacing-large 0 0;
      position: relative;
      text-align: center;
      width: 100%;

      .tn-additional-events {
        &--desktop-format-select-list,
        &__continue-shopping-link {
          display: block;
          margin: 0 auto;
          max-width: 600px;
        }
      }
    }

    &__seatmap-container {
      display: none;
    }

    &__btn-view-seat-map-modal {
      margin-top: $spacing-large;
    }
  }

  // SYOS

  .tn-syos {
    z-index: 9999;

    &-screen-container__button-list-container {
      .tn-syos-screen-button {
        @include button-icon;

        &__max-price {
          &::before {
            opacity: 1;
          }
        }
      }
    }

    &-screen-button__section-heading {
      border: none;
      margin: 0;
      padding: 0;
    }

    &__layout-container,
    &-mobile-cart-controls__item {
      background: $c-white;
      border: none;
    }

    &__layout-container {
      border-top: toRems(4px) solid$c-black;
      margin-top: $spacing-large;
      padding-top: $spacing-large;
    }

    &-screen-container {
      &__map-container img {
        margin: 0 auto;
      }

      &__button-list-heading {
        color: inherit;
      }
    }

    &-legend {
      &__legend-drawer {
        background: $c-black;
        border: none;
        color: $c-white;
      }

      &__desktop-heading-wrapper {
        display: none;
      }

      &__btn-close-legend {
        opacity: 1;

        &:before,
        &:after {
          background: $c-white;
        }
      }

      &__description-term svg[aria-label="Star"] {
        fill: $c-white;
      }
    }

    &-cart {
      &.tn-syos-mobile-popover {
        border: none;
        background: none;
      }

      &__content-container {
        background: $c-white;
        border: toRems(4px) solid $c-black;
        color: $c-black;
      }

      &__heading {
        display: none;
      }

      &__seats-list {
        background: none;
        box-shadow: none;
        @include breakpoint($bp-large) {
          padding: 0;
        }
      }

      &__header,
      &__summary {
        background: white;
        border: none;
      }

      &__header,
      &__summary-items {
        padding-bottom: toRems(30px);
      }
    }

    &-cart,
    &-mobile-overlay__container,
    &-price-type-selector,
    &-seat-info-popover {
      z-index: 9999;
    }

    &-mobile-cart-controls {
      height: toRems(70px);

      &__btn-view-cart-icon {
        opacity: 1;
      }
    }

    &-price-type-selector__content-container,
    &-seat-info-popover__content-container {
      border: none;
      box-shadow: none;
      @include breakpoint($bp-large) {
        border: toRems(4px) solid $c-black;
      }
    }

    &-seat-info {
      &__header {
        background: none;
        border-bottom: toRems(4px) solid $c-black;
      }

      &__header th {
        color: inherit;
        text-transform: none;
      }

      &__price-type {
        &-heading {
          font-size: 0.8em;
          margin-bottom: 0;
        }

        &-list-item {
          border: none;
        }

        &-list {
          padding-top: 0;
        }

        &--button {
          display: flex !important;
          width: 100% !important;
        }
        &-description {
          text-align: left;
        }
      }
      &__seat-type {
        background-color: palette(red);
      }
    }

    &-screen-button {
      width: 100% !important;
      opacity: 1 !important;
    }

    &-cart-seat {
      border: none;
      border-top: toRems(2px) solid $c-white;
      padding-left: 0;

      &:first-child {
        border: none;
      }

      &__btn-remove {
        border: none !important;
      }
    }

    &-cart-seat__btn-remove,
    &-seat-container button,
    &-btn-view-screens,
    &-legend__btn-show-legend,
    &-legend__btn-show-legend-mobile {
      background: $c-black;
      border: toRems(4px) solid $c-black;
      color: $c-white;
      text-decoration: none !important;

      &:hover,
      &:focus {
        background: $c-white;
        color: $c-black;
      }
    }

    &-btn-view-screens {
      left: 0;
      top: 0;
    }

    &-legend__btn-show-legend {
      top: 0;
      &-mobile {
        left: 0;
      }
    }

    &-btn-toggle-full-screen {
      right: 0;
      top: 0;
    }

    &-zoom-control,
    &-legend__btn-show-legend {
      right: 0;
    }

    &__btn-add-to-cart {
      @include button-base;
      text-decoration: none;
      font-size: 0.85em !important;
      z-index: 0; // So fancy background is visible
    }
  }

  .tn-syos__screen-view-components {
    .tn-syos-screen-container {
      display: flex;
      flex-direction: column-reverse !important;

      @include breakpoint($bp-medium) {
        flex-direction: row !important;
      }
    }
  }
}
