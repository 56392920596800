$image-right-margin: 35px;

@mixin hero-image-base {
	margin-bottom: 0;
	opacity: 1;
	overflow: visible;

	@include breakpoint($bp-small) {
		margin-bottom: $spacing-medium; // Leaves space for the offset background
	}

	@include breakpoint($bp-large) {
		margin-bottom: $spacing-base;
	}

	.o-grid {
		opacity: 1;
	}

	> .o-grid {
		padding: 0;
	}
}

@mixin hero-image-main {
	align-items: stretch;
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: $z-tile-background;

	&:before {
		@include hero-bg-color;
	}
}

@mixin hero-images-container {
	background: no-repeat center center scroll $c-black;
	background-size: cover;
	display: block;
	height: 100%;
	left: 0;  // sits on the edge outside grid
	min-height: 0;
	padding-bottom: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: $z-tile-thumb;

	@include breakpoint($bp-small) {
		left: -$spacing-base;  // sits on the edge outside grid
		width: calc(100% - #{$image-right-margin} + #{$spacing-base});
	}

	@include breakpoint($bp-medium) {
		left: toRems(-140px);
		width: calc(100% + #{toRems(140px)} - #{$spacing-medium});
	}

	@include breakpoint($bp-xlarge) {
		left: toRems(-225px);
		width: calc(100% + #{toRems(225px)});
	}

	.c-hero-image__image {
		left: 0;
		width: 100%;
	}
}

@mixin hero-image-common {
	display: block;
	height: 100%;
	left: 0;
	min-height: 0;
	padding-bottom: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: $z-tile-thumb;

	@include breakpoint($bp-small) {
		left: -$spacing-base;
		width: calc(100% - #{$image-right-margin} + #{$spacing-base});
	}

	@include breakpoint($bp-medium) {
		left: toRems(-140px);
		width: calc(100% + #{toRems(140px)} - #{$spacing-medium});
	}

	@include breakpoint($bp-xlarge) {
		left: toRems(-225px);
		width: calc(100% + #{toRems(225px)});
	}
}

@mixin hero-image-image {
	@include hero-image-common;
	background: no-repeat center center scroll $c-black;
	background-size: cover;

	// Black BG shown while image is loading
	&:before {
		background-color: $c-black;
		content: "";
		display: block;
		height: 100%;
		left: 0;
		opacity: 1;
		position: absolute;
		top: 0;
		transition: opacity 300ms $global-easing 0s;
		width: 100%;
		z-index: $z-tile-content + 1;
	}

	// Image overlay gradient shown after image has loaded
	&:after {
		background: linear-gradient(180deg,transparent,rgba(0,0,0,0) ,rgba(0,0,0,.6));
		bottom: 0;
		content: "";
		display: block;
		height: 100%;
		left: 0;
		opacity: 0;
		pointer-events: none;
		position: absolute;
		transition: opacity 300ms $global-easing 0s;
		width: 100%;
		z-index: $z-tile-content + 1;

		@include breakpoint($bp-small) {
			background: linear-gradient(45deg, rgba($c-black, 0.5), rgba($c-black, 0));
		}
	}

	&.is-loaded {
		&:before {
			opacity: 0;
			pointer-events: none;
		}

		&:after {
			opacity: .9;
		}
	}

	img {
		display: none;
	}
}

@mixin hero-image-video {
	@include hero-image-common;
	max-height: toRems(640px);
	overflow: hidden;
}

@mixin hero-image-content {
	align-items: flex-start;
	color: $c-white;
	display: flex;
	flex-flow: column nowrap;
	height: 100%;
	justify-content: flex-end;
	max-width: 100%;
	min-height: toRems(440px);
	padding: $spacing-large $spacing-medium;
	position: relative;
	z-index: $z-tile-content;

	@include breakpoint($bp-small) {
		justify-content: center;
		max-width: toRems(730px);
		padding: $spacing-base;
		padding-bottom: $spacing-medlarge;
		padding-top: $spacing-xlarge;
	}

	@include breakpoint($bp-medium) {
		min-height: toRems(540px);
		padding-bottom: $spacing-large;
		padding-top: $spacing-xlarge;
	}

	@include breakpoint($bp-large) {
		min-height: toRems(640px);
		padding-bottom: $spacing-xlarge;
		padding-top: $spacing-xlarge;
	}

	.c-hero__breadcrumb {
		background: $c-black;
		padding-left: $spacing-xsmall;
		top: 0;

		@include breakpoint($bp-small) {
			background: transparent;
			margin-left: $spacing-medium;
			padding-left: 0;
			top: $spacing-medium;
		}
	}
}

@mixin hero-image-content-inner {
	position: relative;
	width: 100%;

	.c-down-arrow {
		bottom: -$spacing-medium;
		fill: $c-white;
		height: 22px;
		position: absolute;
		right: -$spacing-small;
		width: 22px;

		svg {
			height: 22px;
			width: 22px;
		}

		@include breakpoint($bp-small) {
			bottom: $spacing-small;
			right: $spacing-small;
		}
	}
}

@mixin hero-image-label {
	@include hero-label;
	font-size: $font-xxsmall;
	margin-bottom: $spacing-xxsmall;

	@include breakpoint($bp-medium) {
		font-size: $font-size-8;
		margin-bottom: 0;
	}
}

@mixin hero-image-title {
	@include hero-title;

	@include breakpoint($bp-small) {
		font-size: $font-size-1;
	}

	a {
		&,
		&:link,
		&:visited {
			color: $c-white;
			text-decoration: none;
		}

		&:hover,
		&:active,
		&:focus {
			text-decoration: underline;
		}
	}
}

@mixin hero-image-subtitle {
	@include hero-subtitle;
	font-size: $font-size-8;
	margin-bottom: 0;
	margin-top: $spacing-base;
}

@mixin hero-image-cta {
	&,
	&:link,
	&:visited {
		@include hero-subtitle;
		@include text-underline-font-size-7-white(true);
		color: $c-white;
		font-size: $font-size-8;
		margin-bottom: 0;
		margin-top: toRems(35px);
		padding-bottom: toRems(9px);

		@include breakpoint($bp-small) {
			font-size: $font-size-7;
		}

		@include breakpoint($bp-medium) {
			@include text-underline-font-size-5-white(true);
			margin-top: toRems(45px);
			padding-bottom: toRems(9px);
		}
	}

	&:hover,
	&:active,
	&:focus {
		@include text-underline-font-size-7-white(false);
		padding-bottom: toRems(9px);

		@include breakpoint($bp-medium) {
			@include text-underline-font-size-5-white(false);
			padding-bottom: toRems(9px);
		}
	}
}

@mixin hero-image-video-bg-image {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
	position: absolute;
	width: 100%;

	&:after {
		background-color: $c-black;
		bottom: 0;
		content: "";
		left: 0;
		opacity: 0;
		position: absolute;
		right: 0;
		top: 0;
	}

	img {
		display: none;
	}
}

@mixin hero-image-video-iframe {
	height: 100%;
	margin: 0 auto;
	max-width: 100%;
	overflow: hidden;
	padding-bottom: 74%;
	pointer-events: none;
	position: relative;
	width: 100%;

	// Video overlay gradient
	&:after {
		background: linear-gradient(180deg,transparent,rgba(0,0,0,0) ,rgba(0,0,0,.6));
		bottom: 0;
		content: "";
		display: block;
		height: 100%;
		left: 0;
		opacity: 0.9;
		pointer-events: none;
		position: absolute;
		transition: opacity 300ms $global-easing 0s;
		width: 100%;
		z-index: $z-tile-content + 1;

		@include breakpoint($bp-small) {
			background: linear-gradient(45deg, rgba($c-black, 0.5), rgba($c-black, 0));
		}
	}

	@include breakpoint($bp-medium) {
		height: 0;
	}

	iframe {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		// Video styling to hide black bars

		@include breakpoint($bp-small) {
			transform: scale(1.45);
		}

		@include breakpoint($bp-medium) {
			transform: scale(1.15) translateY(-45px);
		}

		@include breakpoint($bp-large) {
			transform: scale(1.1) translateY(-100px);
		}

		@include breakpoint($bp-xlarge) {
			transform: translateY(-130px);
		}
	}
}

.c-hero-image {
	@include hero-image-base;

	&__main {
		@include hero-image-main;
	}

	&__images {
		@include hero-images-container;
	}

	&__image {
		@include hero-image-image;
	}

	&__video {
		@include hero-image-video;
	}

	&__bg-image {
		@include hero-image-video-bg-image;
	}

	&__video-iframe {
		@include hero-image-video-iframe;
	}

	&__content {
		@include hero-image-content;
	}

	&__content-inner {
		@include hero-image-content-inner;
	}

	&__label {
		@include hero-image-label;
	}

	&__subtitle {
		@include hero-image-subtitle;
	}

	&__date {
		.c-hero-image__venue + & {
			&:before {
				// If placed after venue, display a slash between the two
				@include breakpoint($bp-medium) {
					content: "/ ";
					display: inline-block;
				}
			}
		}
	}

	&__title {
		@include hero-image-title;

		@include breakpoint($bp-small) {
			// If a label and title are right next to each other, add some extra spacing
			.c-hero-image__label + & {
				margin-top: toRems(40px);
			}
		}
	}

	&__cta {
		@include hero-image-cta;
		display: inline-block;
	}


	/*
	 * Carousel Hero -
	 * Used in the Home page slider
	 */

	&--carousel {
		.c-hero-image__split {
			// Current Slide
			&.slick-active {
				.c-hero__label,
				.c-hero__subtitle,
				.c-hero__title,
				.c-hero__cta {
					opacity: 1;
					transform: translateY(0);
				}

				.c-hero-image__label {
					transition: opacity 300ms $global-easing 400ms,
						transform 300ms $global-easing 400ms;

					@include breakpoint($bp-small) {
						transition-duration: 400ms;
					}

					@include breakpoint($bp-medium) {
						transition-duration: 800ms;
					}
				}

				.c-hero-image__subtitle {
					transition: opacity 300ms $global-easing 550ms,
						transform 300ms $global-easing 550ms;

					@include breakpoint($bp-small) {
						transition-delay: 550ms;
						transition-duration: 400ms;
					}

					@include breakpoint($bp-medium) {
						transition-duration: 800ms;
					}
				}

				.c-hero-image__title {
					transition: opacity 400ms $global-easing 700ms,
						transform 400ms $global-easing 700ms;

					@include breakpoint($bp-small) {
						transition-duration: 700ms;
					}

					@include breakpoint($bp-medium) {
						transition-duration: 1500ms;
					}
				}

				.c-hero-image__cta {
					transition: opacity 300ms $global-easing 900ms,
						transform 300ms $global-easing 900ms;

					@include breakpoint($bp-small) {
						transition-delay: 1100ms;
						transition-duration: 400ms;
					}

					@include breakpoint($bp-medium) {
						transition-duration: 800ms;
					}
				}
			}

			// Next Slide
			&.is-next {
				.c-hero-image__label,
				.c-hero-image__subtitle,
				.c-hero-image__title,
				.c-hero-image__cta {
					opacity: 0;
				}

				.c-hero-image__label {
					transform: translateY(-75%);
				}

				.c-hero-image__subtitle {
					transform: translateY(-20%);
				}

				.c-hero-image__title {
					transform: translateY(-20%);
				}

				.c-hero-image__cta {
					transform: translateY(-50%);
				}
			}
		}

		.c-hero-image__content {
			position: relative;
			width: 100%;
		}

		.c-hero-image__text {
			opacity: 1;
			position: relative;
			transition: opacity 300ms $global-easing 0s,
				max-height 300ms $global-easing 0s;
			width: 100%;
			z-index: $z-tile-content;

			&.is-loading {
				opacity: 0;
				overflow: hidden;
			}

			.slick-list {
				height: 100%;
				overflow: visible; // Makes the title that overlaps image be visible
			}

			// Make all the slides the same height (for when one is longer)
			.slick-track {
				align-items: stretch;
				display: flex;
				height: 100%;
				justify-content: center;

				.slick-slide {
					height: auto;
				}
			}
		}

		.c-hero-image__images {
			padding-bottom: 0;
			z-index: $z-tile-thumb;

			.slick-list,
			.slick-track {
				height: 100%;
			}
		}

		.c-hero-image__image {
			flex: none;
			margin: 0;
			padding-bottom: 0;
			position: relative;
			width: 100%;
			z-index: $z-tile-content - 2;

			span {
				display: block;
				padding-bottom: 74%;
			}
		}

		// Remove flex styling when this is nested in c-hero__text
		.c-hero-image__split {
			display: block;
			flex: none;
			max-width: 100%;
			width: 100%;
		}
	}

	/*
	 * Video
	 */

	&--video {
		margin-bottom: toRems(60px);

		@include breakpoint($bp-small) {
			margin-bottom: toRems(70px);
		}

		@include breakpoint($bp-medium) {
			margin-bottom: toRems(50px);
		}

		@include breakpoint($bp-xlarge) {
			margin-bottom: toRems(40px);
		}
	}
}
