.c-accordion {
	&__trigger {
		&,
		&:link,
		&:visited {
			@include button-text;
			background-color: transparent;
			border-radius: $global-radius;
			color: alpha-palette(white, 50%);
			cursor: pointer;
			display: inline-block;
			font-size: $font-size-8;
			min-height: toRems(60px);
			max-width: 100%;
			margin: 0;
			outline: none;
			padding: toRems(13.5px) $spacing-base;
			position: relative;
			text-align: center;
			transition: background 400ms $global-easing 0s,
						color 400ms $global-easing 0s;
			width: 100%;
			-webkit-appearance: none; // Makes sure iOS doesn't add extra styling to buttons.
		}
	}

	&.is-visible {
		.c-accordion__icon {
			&:before {
				transform: scaleY(0);
				opacity: 0;
			}
		}
	}

	&__icon {
		display: inline-block;
		height: toRems(14px); // Height of font
		margin-right: $spacing-small;
		position: relative;
		vertical-align: middle;
		width: toRems(14px);

		&:before,
		&:after {
			background-color: alpha-palette(white, 50%);
			content: "";
			display: inline-block;
			height: toRems(11px);
			opacity: 1;
			position: absolute;
			transform-origin: center;
			width: toRems(1px);
			transition: transform 400ms $global-easing 0s,
				opacity 400ms $global-easing 0s;
		}

		&:after {
			transform: rotate(90deg);
		}
	}

	&__drawer {
		@include clearfix;
		padding: $spacing-medium 0 $spacing-large;

		@include breakpoint($bp-medium) {
			padding-bottom: toRems(90px);
		}
	}
}
