.s-tnew-v7-content {
  .form-group {
    margin-bottom: $spacing-base;
    position: relative;

    &[data-control-group-for="Address.Address2"] {
      display: none;
    }
  }

  .tn-required-field {
    position: absolute;
    right: 0;
    top: 0;
    // color: palette(errors);
    @include breakpoint($bp-medium) {
      top: 0.3em;
    }
  }
}
