.c-section-panel {
	background-color: palette(blue-grey);
	margin: 0;
	opacity: 1;
	position: relative;
	transition: opacity 300ms $global-easing 0s;

	@include breakpoint($bp-medium) {
		transition-duration: 500ms;
	}

	&__header {
		margin-bottom: $spacing-xsmall;
		padding: 0 $spacing-base; // Match o-grid
		text-align: center;
		transform: translateY(#{toRems(-26px)});

		@include breakpoint($bp-medium) {
			transform: translateY(#{toRems(-32px)});
		}
	}

	&__title {
		@include heading-2;
		line-height: 1.21;
	}

	&__main {
		padding-bottom: toRems(110px);

		@include breakpoint($bp-medium) {
			padding-bottom: toRems(70px);
		}
	}

	&__actions {
		padding: 0 $spacing-base; // Match o-grid
		text-align: center;
		transform: translateY(50%);
	}

	&--separator {
		margin: toRems(200px) 0 0;

		@include breakpoint($bp-small) {
			margin-top: toRems(300px);
		}

		@include breakpoint($bp-medium) {
			margin-top: toRems(400px);
		}

		&:before {
			background-color: $c-black;
			content: "";
			height: toRems(120px);
			left: 50%;
			position: absolute;
			top: 0;
			transform: translate(-50%, -200px);
			width: toRems(4px);

			@include breakpoint($bp-small) {
				height: toRems(210px);
				transform: translate(-50%, -300px);
			}

			@include breakpoint($bp-medium) {
				height: toRems(330px);
				transform: translate(-50%, -400px);
			}
		}
	}

	/**
	 * Panel with action button at the bottom
	 */

	&--action {
		margin-bottom: $spacing-medium;

		.c-section-panel__main {
			padding-bottom: toRems(25px);

			@include breakpoint($bp-medium) {
				padding-bottom: toRems(40px);
			}
		}
	}

	/**
	 * Extra top margin for Home page panel
	 */

	&--homepage {
		margin-top: toRems(170px);

		@include breakpoint($bp-small) {
			margin-top: toRems(270px);
		}

		@include breakpoint($bp-medium) {
			margin-top: toRems(340px);
		}

		&:before {
			transform: translate(-50%, -170px);

			@include breakpoint($bp-small) {
				height: toRems(190px);
				transform: translate(-50%, -240px);
			}

			@include breakpoint($bp-medium) {
				height: toRems(260px);
				transform: translate(-50%, -310px);
			}
		}
	}
}
