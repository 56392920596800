.o-content-sidebar {
	@include clearfix;
	margin: $spacing-medlarge 0 $spacing-xlarge;

	@include breakpoint($bp-medium) {
		align-items: stretch;
		display: flex;
		margin-bottom: $spacing-xxlarge;
		margin-top: toRems(95px);
	}

	&__main {
		display: block;
		width: 100%;

		@include breakpoint($bp-medium) {
			@include span-columns(8);
			margin: 0 auto;
		}

		@include breakpoint($bp-large) {
			display: inline-block;
			margin-right: 8.55856%; // 1.5 columns
		}
	}

	&__aside {
		@include span-columns(1);
		display: none;
		flex: 1 0 auto;

		@include breakpoint($bp-large) {
			display: inline-block;
		}

		&:last-child {
			margin-right: (-2.7027%); // Size of gutter
		}
	}

	&--small-top-margin {
		margin-top: $spacing-large;

		@include breakpoint($bp-small) {
			margin-top: $spacing-xsmall;
		}
	}
}
