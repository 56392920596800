.c-label-list {
	line-height: 1.375;

	&__row {
		margin-bottom: toRems(11px); // Match height of content running alongside

		@include breakpoint($bp-medium) {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			width: 100%;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__term {
		display: block;

		@include breakpoint($bp-medium) {
			display: inline-block;
			//flex: 0 0 50%;
		}
	}

	&__item {
		display: none;
		margin-left: 0;

		@include breakpoint($bp-medium) {
			color: palette(grey, mid-mid-light);
			display: inline-block;
			flex: 0 0 50%;
			text-align: right;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}
