.c-label {
	@include small-copy;
	background-color: palette(blue-grey);
	display: inline-block;
	margin-bottom: $spacing-xsmall;
	padding: toRems(6px) $spacing-xsmall;

	// Just in case it's a link
	&,
	&:link,
	&:visited {
		color: $c-font;
		text-decoration: none;
	}

	.c-event & {
		margin-bottom: 0;
	}
}
