.c-modal-compact {
	background-color: $c-white;
	border: toRems(8px) solid $c-black;
	margin: 0 auto;
	max-width: toRems(660px);
	padding: toRems(120px) toRems(50px) toRems(50px);
	position: relative;

	&__title {
		@include heading-3;
		margin-bottom: toRems(35px);
	}

	&__description {
		@include description;
		margin-bottom: $spacing-medlarge;
	}

	&__meta {
		border-top: toRems(4px) solid $c-black;
		padding-bottom: $spacing-xsmall;
		padding-top: toRems(25px);
	}

	&__close {
		position: absolute;
		right: toRems(-4px);
		top: toRems(-4px);

		.c-button-icon {
			@include button-base;
			@include button-icon;

			&:active {
				top: auto;
			}
		}
	}

	&__labels {
		float: right;
		max-width: toRems(255px);
		text-align: right;
	}
}

.mfp-bg {
	background-color: transparentize($c-white, .25);
}


.mfp-slide.mfp-bg {

	// -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	// filter: alpha(opacity = 0);
	opacity: 0;
	transition: all 0.3s ease-out;

	.lt-ie9 & {
		opacity: 1;
	}
}

.mfp-slide.mfp-bg.mfp-ready {
	opacity: $mfp-overlay-opacity;

	.lt-ie9 & {
		opacity: 1;
	}
}

.mfp-slide.mfp-bg.mfp-removing {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity = 0);
	opacity: 0;

	.lt-ie9 & {
		opacity: 1;
	}
}

.mfp-slide .mfp-content {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity = 0);
	opacity: 0;
	transition: all 300ms ease-in-out;
	transform: translateY(5%);
}

.mfp-slide.mfp-ready .mfp-content {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: alpha(opacity = 100);
	opacity: 1;
	transform: translateY(0);
}

.mfp-slide.mfp-removing .mfp-content {
	transform: translateY(-5%);
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity = 0);
	opacity: 0;
}
