/*
TNEW7 Cart
Appears in cart, payment, and confirmation pages
*/

.s-tnew-v7-content {
  .tn-empty-cart-message {
    background: none;
    border-color: palette(pink);
    border-width: toRems(2px);
    color: inherit;
    font-style: normal;
  }

  .tn-cart {
    &-component {
      /*
        margin-left: auto;
        margin-right: auto;
        max-width: 800px;
        */
    }

    &-item {
      border: none;
      color: $c-black;
      padding-bottom: toRems(57px);
      @include breakpoint($bp-medium) {
        padding-bottom: toRems(62px);
      }

      &-summary {
        background: none;
        border: toRems(2px) solid palette(pink);
        padding: 0;

        &__property {
          padding: $spacing-small;
          @include body-copy;

          &--name {
            background: palette(pink);
            grid-column: 1/4;
            padding-right: toRems(50px);

            font-size: $font-size-6;
            font-weight: $font-weight-medium;
            line-height: 1.06;

            @include breakpoint($bp-medium) {
              font-size: $font-size-5;
            }
          }

          &--date-time {
            padding-bottom: 0;
          }

          &--location {
            padding-top: 0;
          }

          &--price-total {
            border: toRems(2px) solid palette(pink);
            border-top-width: 0;
            bottom: 0;
            font-weight: $font-weight-bold;
            left: 0;
            position: absolute;
            right: 0;
            text-align: right;

            &.tn-cart-item-summary__property--primary-in-package {
              border-left-width: toRems(2px) !important;
              border-top: none;
              bottom: 0;
            }
          }
        }
      }

      &-detail__seat-location-divider:after {
        color: $c-black;
      }

      &__package-perfs-container {
        border-color: palette(pink);
        border-left-width: toRems(5px);
        padding-bottom: toRems(10px);

        section {
          margin-bottom: $spacing-base;
        }
      }

      &-detail__list,
      &-details__list {
        background: none;
        border: none;
        color: inherit;
        padding: 0;
      }

      &-details__list {
        border: toRems(2px) solid palette(pink);
        border-top: none;
        padding: $spacing-small;

        li {
          @include body-copy;
        }
      }
    }

    &__section--packages .tn-cart-item-summary__property--price-total {
      border-top-width: toRems(2px);
      @include breakpoint($bp-medium) {
        border-left-width: toRems(5px);
      }
    }

    &-totals {
      border: toRems(2px) solid palette(pink);
      padding: $spacing-small;

      &__line-item {
        color: $c-black;
        display: flex;
        justify-content: space-between;
        padding: toRems(3px 0);

        @include body-copy;

        h3,
        div {
          font-size: inherit;
          line-height: inherit;
          font-weight: inherit;
          margin-bottom: 0;
          width: auto;
        }

        &--subtotal {
          display: none;
        }

        &--fee {
          & + .tn-cart-totals__line-item--fee {
            border-top-width: 0;
            margin-top: 0;
          }
        }
        &--total {
          background: palette(pink);
          font-weight: $font-weight-bold;
          margin: -$spacing-small;
          padding: $spacing-small;
        }

        & + .tn-cart-totals__line-item--total {
          margin-top: $spacing-small;
        }
      }
    }

    &__btn-remove {
      margin: $spacing-small;
    }

    &-buttons__item-container + .tn-cart-buttons__item-container {
      padding-top: $spacing-small;
      text-align: center;
      @include breakpoint($bp-medium) {
        text-align: left;
      }
    }
  }
}
