/*
TNEW7 hero header
*/

.s-tnew-v7-content {
  .c-tnew-v7-hero {
    background-color: palette(yellow);
    margin-bottom: $spacing-large;
    margin-top: toRems(-50px);
    padding: $spacing-xlarge 0;
    position: relative;

    &__title {
      font-size: $font-size-3;
      letter-spacing: -0.01em; // Gets iOS type matching designs more closely
      padding: 0 toRems(20px) 0 0;
      position: relative;

      @include breakpoint($bp-medium) {
        font-size: $font-size-2;
        padding: 0 50% 0 0;
      }
    }

    &__blurb {
      margin-top: $spacing-large;
    }

    @include breakpoint($bp-small) {
      padding: toRems(95px) 0 toRems(65px);
    }

    @include breakpoint($bp-medium) {
      padding-bottom: toRems(95px);
    }

    &:before {
      background-color: palette(yellow);
      content: "";
      height: 100%;
      left: -100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }
  }
}
