.c-event {
	width: 100%;
	z-index: $z-tile-background;

	@include breakpoint($bp-medium) {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
	}

	.o-listing__item  & {
		@include breakpoint($bp-small) {
			height: 100%;
			padding-bottom: calc(#{toRems(60px) + $spacing-xsmall} + (2.7027% * 2)); // Height of absolutely positioned button + top padding + bottom margin (calculated as percentage like right margin, multiplied by two because the parent item is 50% parent width). Epic.
		}

		@include breakpoint($bp-medium) {
			height: auto;
			padding-bottom: 0;
		}
	}

	&__divider {
		border-bottom: 4px solid $c-placeholder;
		display: none;
		margin-left: -0.75rem;
		padding-bottom: 1.25rem;
		width: calc(100vw - 1rem);

		@include breakpoint($bp-xsmall) {
			display: block;
		}
	}

	&__image {
		flex: 1 1 100%;
		margin-bottom: $spacing-base;

		@include breakpoint($bp-xsmall) {
			margin-left: -1.25rem;
			width: 100vw;
		}

		@include breakpoint($bp-small) {
			flex-basis: auto;
		}

		@include breakpoint($bp-medium) {
			flex-basis: (5.85586% * 3) + (2.7027% * 2); // 3 columns + 2 gutters
			flex-grow: 0;
			margin-bottom: 0;
			width: (5.85586% * 3) + (2.7027% * 2);
		}

		a {
			&,
			&:link,
			&:visited {
				display: block;
			}
			@include breakpoint($bp-xsmall) {
				height: 25vh;
				max-height: 220px;
				min-height: 140px;
			}
		}
	}

	&__thumbnail {
		@include breakpoint($bp-xsmall) {
			height: 100%;
			padding-top: 0;
		}
		background: no-repeat center center scroll $c-black;
		background-size: cover;
		display: block;
		height: 0;
		padding-top: 100%;
		width: 100%;
		z-index: $z-tile-thumb;

		img {
			display: none;
		}
	}

	&__main {
		flex: 2 1 100%;

		@include breakpoint($bp-small) {
			flex: 1 1 toRems(80px);
		}

		@include breakpoint($bp-medium) {
			flex-basis: (5.85586% * 6) + (2.7027% * 4); // 6 columns + 4 gutters
			flex-grow: 1;
			margin-left: 2.7027%;
			padding-right: 2.7027%; // Just so content doesn't touch the edge
			width: (5.85586% * 6) + (2.7027% * 4);
		}
	}

	&__content {
		@include breakpoint($bp-small) {
			display: flex;
			flex-direction: column;
			height: 100%;
			justify-content: flex-start;
		}
	}

	&__title {
		@include heading-3;
		font-weight: $font-weight-bold;
		margin-bottom: $spacing-large;

		@include breakpoint($bp-xsmall) {
			margin-bottom: .5rem;
		}

		@include breakpoint($bp-small) {
			font-size: $font-size-3;
			margin-bottom: $spacing-base;
		}

		@include breakpoint($bp-medium) {
			flex: 0 0 auto;
			font-weight: $font-weight-medium;
			margin-bottom: $spacing-small;
		}

		a {
			&,
			&:link,
			&:visited {
				@include text-underline-font-size-4(false);
				color: $c-font;
				line-height: 1.25;

				@include breakpoint($bp-medium) {
					@include text-underline-font-size-3(false);
				}
			}

			&:hover,
			&:active,
			&:focus {
				@include text-underline-font-size-4(true);

				@include breakpoint($bp-medium) {
					@include text-underline-font-size-3(true);
				}
			}
		}
	}

	&__description {
		display: none;

		@include breakpoint($bp-medium) {
			display: block;
			flex: 0 0 auto;
			line-height: 1.4;
			margin-bottom: $spacing-base;
		}
	}

	&__labels {
		display: none;

		@include breakpoint($bp-medium) {
			display: block;
			flex: 0 0 auto;
			margin-bottom: 0;
			margin-top: auto;
		}
	}

	&__meta {
		flex: 2 1 100%;

		@include breakpoint($bp-medium) {
			flex: 1 1 100%;
		}

		@include breakpoint($bp-medium) {
			border-left: toRems(4px) solid $c-black;
			display: block;
			flex-basis: (5.85586% * 3) + (2.7027% * 3); // 3 columns + 3 gutters
			flex-grow: 0;
			padding-bottom: toRems(60px) + $spacing-xsmall;
			padding-left: 2.7027%;
			position: relative;
			width: (5.85586% * 3) + (2.7027% * 3);
		}
	}

	&__venues {
		@include description;
		margin-bottom: $spacing-base;
	}

	&__venue {
		+ .c-event__venue {
			margin-top: $spacing-base;
		}
	}

	&__venue-name {
		@include description;
		font-weight: $font-weight-bold;
	}

	&__actions {
		@include breakpoint($bp-small) {
			bottom: 2.7027%;
			left: 0;
			position: absolute;
			width: 100%;
		}

		@include breakpoint($bp-medium) {
			bottom: 0;
			left: (((5.85586% * 3) + (2.7027% * 3)) / 2.7027%) * 1%;
			margin-left: 0;
			margin-right: 0;
			width: 100% - ((((5.85586% * 3) + (2.7027% * 3)) / 2.7027%) * 1%);
		}
	}
}
