.c-video {
	// Break out of grid (one column on left and right wider than grid) if in narrow wrapper
	.o-narrow &,
	.o-content-sidebar__main & {
		margin: $spacing-medlarge (-$spacing-base);

		@include breakpoint($bp-medium) {
			margin: toRems(80px) (-((5.85586% + 2.7027%) / .657657658)); // column width + column gutter width / divided by ratio of narrow grid to normal grid size
		}

		@include breakpoint($bp-large) {
			margin-bottom: toRems(80px);
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__wrapper {
		position: relative;

		&.is-playing {
			.c-video__iframe {
				opacity: 1;
				transform: scale(1);
				visibility: visible;
			}
		}
	}

	&__action {
		padding: 0 $spacing-base;
		position: absolute;
		text-align: center;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
	}

	&__image {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 100%;
		position: absolute;
		width: 100%;

		&:after {
			background-color: $c-black;
			bottom: 0;
			content: "";
			left: 0;
			opacity: .5;
			position: absolute;
			right: 0;
			top: 0;
		}

		img {
			display: none;
		}
	}

	&__iframe {
		height: 0;
		margin: 0 auto;
		max-width: 100%;
		overflow: hidden;
		padding-bottom: 56.25%; // Ratio to 100% width
		position: relative;
		width: 100%;

		.js & {
			opacity: 0;
			overflow: hidden;
			transform: scale(0.8);
			transition: transform 1s $global-easing .3s,
				opacity 1s $global-easing .3s,
				visibility 0s linear .3s;
			visibility: hidden;
		}

		iframe, object, embed {
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}
	}
}
