.online-event-type-badge {
    text-transform: uppercase;
    font-size: 14px;
    display: inline-block;
    font-weight: bold;
    width: 35%;
    text-align: center;
    margin-bottom: .9375rem;

    &.concert-webcast {
        background-color: #EEB0CB;
        padding: 5px;
    }

    &.concert-highlight {
        background-color: #F5C121;
        padding: 5px;
    }

    &.online-exclusive {
        background-color: #58A5DB;
        padding: 5px;
    }
}