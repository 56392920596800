.c-footer {
    background-color: palette(pink);
    opacity: 1;
    transition: opacity 300ms $global-easing 0s;

    @include breakpoint($bp-medium) {
        transition-duration: 500ms;
    }

    &__wrapper {
        @include span-columns(12);
        float: none;
        margin: 0 auto;

        &:last-child {
            margin-right: auto;
        }
    }

    &__main {
        @include clearfix;
        padding: toRems(37px) 0 0;

        @include breakpoint($bp-large) {
            padding-top: toRems(180px);
        }
    }

    &__block {
        @include grid-quarter;
        border-bottom: 2px solid $c-black;

        @include breakpoint($bp-medium) {
            border-bottom: 0;
            padding-right: $spacing-large;
        }

        // Accordion is open
        &.is-visible {
            .c-footer__header:after {
                transform: translateY(-50%) rotateX(180deg);
            }
        }
    }

    &__body {
        padding-bottom: $spacing-medium;

        @include breakpoint($bp-medium) {
            display: block !important; // Force content open when there is no accordion
        }
    }

    // Align line-height with links
    &__content {
        margin-bottom: toRems(25px);

        &:last-child {
            margin-bottom: 0;
        }

        a {
            @include text-link-reverse;
        }
    }

    &__links {
        a {
            @include text-link-reverse;
        }
    }

    &__header {
        padding: toRems(17px) 0;
        position: relative;

        @include breakpoint($bp-medium) {
            font-size: $font-size-7;
            margin-bottom: $spacing-xsmall;
        }

        // Arrow icon
        &:after {
            content: '';
            background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAYCAYAAAAVibZIAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAALZJREFUeNrs1cENgzAMBdC4EzACIzACo3aUjsAo2cC1qami4G9TtT0gEckiJD/vZpmYuRxZRDTrV/KPNPsByoZSlr2VP6wLvdBTodIx47fY27A2vUtVqUn/vdLoKw7vJzPUKoPUYo8gHKENyKtlhymM0B2oVnMZwh7qget5F4JwjyJwh0Zwi0agiyJ4QzMQogDmbu+CIerAbUEwRQEcglqHBp+0n8LbFJ3lTf3VNB2srWuWfQowALo+o42GEhNoAAAAAElFTkSuQmCC')
                no-repeat center center scroll transparent;
            display: block;
            height: toRems(25px);
            position: absolute;
            right: 0;
            top: 50%;
            transform-origin: center;
            transform: translateY(-50%) rotateX(0deg);
            transition: transform 500ms $global-easing 0s;
            width: toRems(20px);

            @include breakpoint($bp-medium) {
                content: none;
            }
        }

        &:hover {
            cursor: pointer;

            @include breakpoint($bp-medium) {
                cursor: default;
            }
        }
    }

    &__title {
        @include body-copy;
        color: $c-font;
        font-weight: $font-weight-medium;

        @include breakpoint($bp-medium) {
            font-size: $font-size-7;
        }
    }

    &__fine-print {
        line-height: 1;
        padding: toRems(50px) 0 toRems(57px);
        text-align: center;
    }

    &__sponsor {
        &,
        &:link,
        &:visited {
            display: inline-block;
            height: toRems(63px);
            vertical-align: bottom;
        }

        svg {
            height: toRems(63px);
            width: toRems(130px);
        }
    }

    &__logo {
        &,
        &:link,
        &:visited {
            display: inline-block;
            height: toRems(53px);
            vertical-align: bottom;
        }

        svg {
            height: toRems(53px);
            width: toRems(180px);
        }
    }

    &--underlap {
        @include breakpoint($bp-large) {
            margin-top: toRems(-120px);
        }
    }
}
