.c-cta-nav {
	background: $c-black;
	bottom: 0;
	color: $c-white;
	height: 60px;
	opacity: 1 !important;
	position: fixed;
	right: 0;
	transform: translateY(100%);
	transition: transform .2s ease-in-out;
	width: 100%;
	z-index: 999;

	&.is-active {
		transform: translateY(0);
	}

	.c-nav-container {
		align-items: center;
		border-top: 2px #333333 solid;
		display: flex;
		flex-direction: row;
		height: 100%;
		justify-content: space-between;
		position: relative;
		width: 100%;
	}

	.c-content-left {
		align-items: center;
		display: flex;
		flex-direction: column;
		font-size: toRems(16px);
		font-weight: 500;
		height: 100%;
		justify-content: center;
		line-height: 20px;
		padding: 0 $spacing-small;
		width: 100%;

		.o-split-grid__item-half-at-small {
			width: 100%;
		}
	}

	.c-cta-button {
		align-items: center;
		background: $c-dark-grey;
		color: $c-white;
		cursor: default;
		display: flex;
		flex-direction: row;
		font-size: toRems(16px);
		font-weight: 500;
		height: 60px;
		justify-content: center;
		line-height: 20px;
		max-width: 33%;
		min-width: 120px;
		padding: 0 $spacing-small;
		position: relative;
		text-align: center;
		text-transform: capitalize;
		transition: background .2s ease-in-out;

		&:not([href="#"]) {
			cursor: pointer;

			&:hover,
			&:focus {
				background: darken($c-dark-grey, 10%);
			}
		}


		&--multiple {
			cursor: pointer;
			text-align: left;

			svg {
				fill: $c-white;
				height: 15px;
				margin-left: $spacing-xsmall;
				transform: rotateZ(45deg);
				transition: fill .2s ease-in-out, transform .2s ease-in-out;
				width: 15px;
			}

			.c-cta-button {
				border-bottom: 1px #333333 solid;
			}

			&:hover,
			&:focus {
				svg {
					fill: darken($c-white, 30%);
				}
			}

			&.open {
				svg {
					transform: rotateZ(0deg);
				}
			}
		}
	}

	.c-cta-options {
		bottom: 100%;
		overflow: hidden;
		position: absolute;
		right: 0;
		transform: translateY(calc(100% + 60px));
		transition: transform .2s ease-in-out;
		width: 100%;
		z-index: -1;

		&.open {
			transform: translateY(-1px);
		}

		.c-cta-button {
			max-width: 100%;
		}
	}

	@include breakpoint($bp-medium) {
		height: 100px;
		max-width: 900px;
		right: calc(10vw - 90px);

		.c-nav-container {
			border-left: 2px #333333 solid;
			border-right: 2px #333333 solid;
		}

		.c-content-left {
			font-size: toRems(18px);
			padding: 0 $spacing-medium;
		}

		.c-cta-button {
			font-size: toRems(18px);
			height: 100px;
			min-width: 200px;
			padding: 0 $spacing-large;

			svg {
				height: 18px;
				margin-left: $spacing-small;
				width: 18px;
			}
		}
	}
}
