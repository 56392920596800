.c-icon-tooltip {
	&,
	&:link,
	&:visited {
		@include tooltip;
		border: none;
		border-radius: 0;
		cursor: pointer;
		display: block;
		margin: 0;
		opacity: 1;
		padding: toRems(15px);
		position: relative;
		text-align: center;
		vertical-align: middle;
		width: toRems(60px);
		-webkit-appearance: none; // Makes sure iOS doesn't add extra styling to buttons.

		svg {
			display: block;
			fill: $c-black;
			height: toRems(30px);
			transition: fill 500ms $global-easing 0s;
			width: toRems(30px);

			.has-active-menu & {
				fill: $c-white;
				transition: fill 300ms $global-easing 200ms;

				@include breakpoint($bp-desktop-nav) {
					fill: $c-black;
				}
			}
		}
	}

	&__label {
		@include visually-hidden;
	}

	.c-header__icon-list & {
		@include breakpoint($bp-desktop-nav) {
			&,
			&:link,
			&:visited {
				height: $header-height-desktop;
				padding-bottom: (($header-height-desktop - toRems(30px)) / 2);
				padding-top: (($header-height-desktop - toRems(30px)) / 2);
			}
		}
	}
}
