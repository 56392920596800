.c-content-link	{
	@include clearfix;
	padding: toRems(45px) 0;

	&__wrapper {
		color: $c-black;
		font-size: $font-size-8;
		line-height: 1.5;

		@include breakpoint($bp-medium) {
			@include span-columns(10);
			font-size: $font-size-7;
		}
	}

	&__title {
		&,
		&:link,
		&:visited {
			@include heading-3;
			color: $c-black;
			display: block;
			line-height: 1.25;
			margin-bottom: $spacing-medium;

			@include breakpoint($bp-medium) {
				margin-bottom: toRems(35px);
			}

			span {
				@include text-underline-font-size-4(false);

				@include breakpoint($bp-medium) {
					@include text-underline-font-size-3(false);
				}
			}
		}

		&:hover,
		&:active,
		&:focus {
			span {
				@include text-underline-font-size-4(true);

				@include breakpoint($bp-medium) {
					@include text-underline-font-size-3(true);
				}
			}
		}
	}

	&__blurb {
		margin-bottom: $spacing-base;

		@include breakpoint($bp-medium) {
			margin-bottom: $spacing-medium;
		}
	}

	&__link {
		&,
		&:link,
		&:visited {
			@include text-underline-font-size-7(true);
			color: $c-black;
			word-break: break-all;
		}

		&:hover,
		&:focus {
			@include text-underline-font-size-7(false);
		}
	}
}
