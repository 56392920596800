.c-content-block {
	color: $c-white;

	&__title {
		@include heading-5;
		font-weight: 500;
		margin-bottom: $spacing-small;
	}

	&__content {
		line-height: 1.375;

		a {
			&,
			&:link,
			&:visited {
				color: $c-white;
			}

			&:hover {
				text-decoration: underline;
			}
		}
	}

	// Container for buttons within forms
	&__actions {
		margin-top: $spacing-xsmall;

		@include breakpoint($bp-medium) {
			margin-top: $spacing-large;
		}
	}

	&--centered {
		text-align: center;
	}

	&--dark--mobile, &--dark--mobile .c-content-block__content a {
		color: $c-font;

		@include breakpoint($bp-small) {
			color: $c-white;
		}
	}

	&--centered\@medium-max {
		text-align: center;

		@include breakpoint($bp-medium) {
			text-align: left;
		}
	}

	.past-event-title {
		margin-bottom: .975rem;
	}

	.c-visual-panel__link.upcoming-events-link {
		font-size: 1.5em;
	}
}
