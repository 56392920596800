.c-calendar {
	th,
	td {
		@include body-copy;
		border: toRems(2px) solid palette(pink);
		min-width: toRems(130px);
		padding: $spacing-medium $spacing-small;
		text-align: left;

		@include breakpoint($bp-medium) {
			padding: $spacing-medlarge $spacing-base;
		}
	}

	.fc-head {
		background-color: palette(pink);
	}

	.fc-head-container,
	.fc-widget-content {
		padding: 0;
	}

	// Overwrite inline style to hide scrollbar
	.fc-day-grid-container {
		overflow-y: hidden !important;
	}

	// Toolbar (Next/prev Month buttons, Month title, etc)
	.fc-toolbar {
		display: flex;
		margin-bottom: $spacing-medlarge;

		.fc-left,
		.fc-right,
		.fc-center {
			flex: 1 1 auto;
		}

		.fc-left {
			flex-basis: toRems(60px) + toRems(4px) + toRems(60px) + toRems(1px);
			flex-shrink: 0;
			order: 1;
		}

		.fc-right {
			order: 3;
		}

		// Current month title
		.fc-center {
			flex: 1 1 100%;
			order: 2;
			padding-left: $spacing-medlarge;
			text-align: left;

			h2 {
				@include hero-subtitle;
				margin-top: toRems(12.5px);
			}
		}
	}

	// Buttons
	.fc-button {
		@include button-base;
		@include button-icon;
		@include button-icon-inline;

		&,
		&:link,
		&:visited {
			background-image: none;
			box-shadow: none;
			margin-left: toRems(2px);
			margin-right: toRems(2px);
			text-shadow: none;

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}

			// Only apply fancy styling if on a non-touch device
			.no-touchevents & {
				background-color: transparent;

				&:before {
					background-color: $c-black;
					content: "";
					display: block;
					height: 100%;
					left: 0;
					position: absolute;
					top: 0;
					transition: height 400ms $global-easing 0s;
					width: 100%;
					z-index: $z-place-behind - 1;
				}

				&:after {
					background-color: $c-white;
					content: "";
					display: block;
					height: 0;
					left: 0;
					position: absolute;
					top: 0;
					transition: height 400ms $global-easing 0s;
					width: 100%;
					z-index: $z-place-behind;
				}
			}

			.fc-icon {
				background: transparent;
				display: block;
				height: toRems(24px);
				left: 0;
				margin: 0;
				text-shadow: none;
				top: 0;
				width: toRems(24px);

				&.fc-icon-right-single-arrow,
				&.fc-icon-left-single-arrow {
					&:after {
						content: "";
						display: block;
						height: toRems(24px);
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
						width: toRems(24px);
					}
				}

				&.fc-icon-left-single-arrow {
					&:after {
						background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MDAgNTAwIj48cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJNMCAyNTBsMjcuOCAyNy44IDE5Ni44IDE5Ni44IDI3LjgtMjcuOS0xNzcuMS0xNzdINTAwdi0zOS40SDc1LjNsMTc3LjEtMTc3LTI3LjgtMjcuOUwyNy44IDIyMi4yIDAgMjUweiIvPjwvc3ZnPg==");
					}
				}

				&.fc-icon-right-single-arrow {
					&:after {
						background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MDAgNTAwIj48cGF0aCAgZmlsbD0iI2ZmZmZmZiIgZD0iTTUwMCAyNTBsLTI3LjgtMjcuOEwyNzUuNCAyNS40bC0yNy44IDI3LjkgMTc3LjEgMTc3SDB2MzkuNGg0MjQuN2wtMTc3LjEgMTc3IDI3LjggMjcuOSAxOTYuOC0xOTYuOEw1MDAgMjUweiIvPjwvc3ZnPg==");
					}
				}
			}
		}

		&:hover,
		&:active,
		&:focus {
			background-color: $c-white;
			color: $c-black;

			&:disabled {
				background-color: $c-link;
				color: $c-white;
			}

			// Only apply fancy styling if on a non-touch device
			.no-touchevents & {
				background-color: transparent;

				&:after {
					height: 100%;
				}

				&:disabled {
					&:after {
						height: 0;
					}
				}
			}

			.fc-icon {
				&.fc-icon-left-single-arrow {
					&:after {
						background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MDAgNTAwIj48cGF0aCBkPSJNMCAyNTBsMjcuOCAyNy44IDE5Ni44IDE5Ni44IDI3LjgtMjcuOS0xNzcuMS0xNzdINTAwdi0zOS40SDc1LjNsMTc3LjEtMTc3LTI3LjgtMjcuOUwyNy44IDIyMi4yIDAgMjUweiIvPjwvc3ZnPg==");
					}
				}

				&.fc-icon-right-single-arrow {
					&:after {
						background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MDAgNTAwIj48cGF0aCBkPSJNNTAwIDI1MGwtMjcuOC0yNy44TDI3NS40IDI1LjRsLTI3LjggMjcuOSAxNzcuMSAxNzdIMHYzOS40aDQyNC43bC0xNzcuMSAxNzcgMjcuOCAyNy45IDE5Ni44LTE5Ni44TDUwMCAyNTB6Ii8+PC9zdmc+");
					}
				}
			}
		}
	}

	// Day Header
	.fc-day-header {
		@include form-label-small;
		font-weight: $font-weight-medium;
		padding-bottom: $spacing-base;
		padding-top: $spacing-base;
	}

	// Today's date
	.fc-today.fc-today {
		background-color: lighten(palette(pink), 8%); 

		&.fc-day-top {
			background-color: transparent;
		}
	}

	// Date number
	.fc-day-top {
		padding: $spacing-small $spacing-base 0;
	}

	.fc-day-number,
	&.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
		@include description;
		float: left;
		padding: 0;
	}

	// Event
	.fc-event-container {
		padding: toRems(8px) $spacing-base;
	}

	.fc-event {
		&,
		&:link,
		&:visited {
			@include small-copy;
			background-color: transparent;
			border: none;
			border-radius: 0;
			color: $c-font;
			margin: 0;
		}

		&:hover,
		&:active,
		&:focus {
			text-decoration: underline;
		}

		.fc-content {
			@include small-copy;
		}

		.fc-time {
			@include small-copy;
			display: block;
			font-weight: $font-weight-bold;
			margin-bottom: toRems(3px);
		}

		.fc-title {
			@include small-copy;
			display: block;
			padding-bottom: 2px; // Prevents 'g' and 'y' from being cut off at the bottom (problem with font rendering)
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 100%;
		}
	}

	// More Events label
	.fc-more-cell {
		@include small-copy;
		background-color: transparent;
		border: none;
		border-radius: 0;
		color: $c-font;
		padding: 0 $spacing-base;

		.fc-more {
			@include small-copy;
			margin: $spacing-xxsmall 0 0;
		}
	}

	// Popupver (to display 'more' events)
	.fc-popover {
		border: none;
		box-shadow: 0 toRems(2px) toRems(12px) rgba(0, 0, 0, 0.15);

		.fc-header .fc-close {
			display: block;
			margin-right: -$spacing-xsmall;
			margin-top: $spacing-xxsmall;
		}

		.fc-header {
			background-color: palette(pink);
			padding: $spacing-xxsmall $spacing-base;
		}

		.fc-event {
			margin: $spacing-xsmall 0;
		}
	}
}
