.c-visual-panel {
	@include breakpoint($bp-large) {
		margin-bottom: toRems(-205px);
	}

	&__wrapper {
		&,
		&:link,
		&:visited {
			display: block;
			overflow: hidden;
			padding: toRems(35px) 0 toRems(55px);
			position: relative;
			text-decoration: none;

			@include breakpoint($bp-medium) {
				padding-bottom: toRems(80px);
				padding-top: toRems(100px);
			}

			@include breakpoint($bp-large) {
				padding-bottom: toRems(200px);
			}
		}

		&:hover,
		&:active,
		&:focus {
			.c-visual-panel__background {
				transform: scale(1.05);

				&:before {
					background-color: alpha-palette(black, 25%);
				}
			}
		}
	}

	&__content {
		color: $c-white;
		padding-right: toRems(80px);
		position: relative;
		z-index: $z-tile-content;

		@include breakpoint($bp-small) {
			max-width: toRems(340px);
			padding-right: 0;
		}
	}

	&__background {
		background: no-repeat center 20% scroll $c-black;
		background-size: cover;
		display: block;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		transition: transform 500ms $global-easing 0s;
		width: 100%;
		z-index: $z-tile-thumb;

		&:before {
			background-color: alpha-palette(black, 60%);
			content: '';
			display: block;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			transition: background 500ms $global-easing 0s;
			width: 100%;
			z-index: $z-tile-thumb-overlay;
		}

		img {
			display: none;
		}
	}

	&__sub-title {
		@include heading-4;
		margin-bottom: $spacing-base;
	}

	&__title {
		color: $c-white;
		display: block;
		font-size: $font-size-5;
		font-weight: $font-weight-medium;
		line-height: 1.214;
		margin-bottom: $spacing-medium;

		@include breakpoint($bp-medium) {
			font-size: $font-size-2;
			line-height: 1;
			margin-bottom: toRems(95px);
		}
	}

	&__link {
		&,
		&:link,
		&:visited {
			@include heading-4;
			@include text-underline-font-size-7-white(true);
			color: $c-white;

			@include breakpoint($bp-medium) {
				@include text-underline-font-size-5-white(true);
			}
		}

		&:hover,
		&:active,
		&:focus {
			@include text-underline-font-size-7-white(false);

			@include breakpoint($bp-medium) {
				@include text-underline-font-size-5-white(false);
			}
		}
	}

	.next-concert-production-year {
		font-size: 1.5rem;
	}
}
