.c-newsletter {
	background-color: $c-black;
	display: block;
	margin: 0 (-$spacing-base);
	padding: toRems(50px) $spacing-base toRems(50px);
	text-align: center;
	position: relative;
	z-index: $z-tile-content;

	@include breakpoint($bp-medium) {
		margin-left: -$spacing-medium;
		margin-right: -$spacing-medium;
		padding: $spacing-large $spacing-medium toRems(50px);
	}

	@include breakpoint($bp-xlarge) {
		margin-left: toRems(-95px);
		margin-right: toRems(-95px);
		padding-bottom: toRems(90px);
		padding-top: toRems(90px);
	}

	&__link {
		&,
		&:link,
		&:visited {
			color: $c-white;
			display: inline-block;
			padding: 0 $spacing-xsmall; // Increase click-space
			position: relative;

			.c-newsletter__title {
				@include text-underline-font-size-5-white(false);

				@include breakpoint($bp-large) {
					@include text-underline-font-size-2-white(false);
				}
			}
		}

		&:hover,
		&:focus {
			.c-newsletter__title {
				@include text-underline-font-size-5-white(true);

				@include breakpoint($bp-large) {
					@include text-underline-font-size-2-white(true);
				}
			}
		}
	}

	&__title {
		display: inline;
		font-size: $font-size-5;
		font-weight: $font-weight-medium;
		line-height: 1.3;
		padding-right: $spacing-base;
		vertical-align: middle;

		@include breakpoint($bp-large) {
			display: inline-block;
			font-size: $font-size-2;
		}
	}

	&__icon {
		display: inline-block;
		margin-top: toRems(3px);
		vertical-align: middle;

		@include breakpoint($bp-large) {
			margin-top: toRems(5px);
		}

		// Arrow right
		svg {
			display: block;
			fill: $c-white;
			height: toRems(22px);
			margin: 0 auto;
			width: toRems(22px);
		}
	}

	&--overlap {
		@include breakpoint($bp-large) {
			margin-bottom: toRems(-75px);
		}

		@include breakpoint($bp-xlarge) {
			margin-bottom: toRems(-120px);
		}
	}
}
