/*
  CSS fix for 100vh in mobile WebKit
*/

html,
body {
  min-height: -webkit-fill-available;
}

.tn-syos--viewing-seats .tn-syos__layout-container {
  min-height: -webkit-fill-available;
}