.s-tnew-v7-content {
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="tel"],
  input[type="number"] {
    @include form-text-input;
    height: toRems(60px);
    line-height: 60px !important; // IE was pulling in line-height property from html/body. !important to force center alignment of
    /*
      // Disable red error highlight 
      box-shadow: none !important;
      border-color: inherit !important;
      */
  }

  input[type="number"] {
    padding-right: 0;
  }

  .input-group-addon {
    background: #000 !important;
    border-color: #000 !important;
    color: #fff !important;
  }

  .radio {
    padding-bottom: $spacing-base;

    & input[type="radio"] {
      left: 0;
      opacity: 0;
      position: absolute;
      -webkit-appearance: none;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      height: toRems(10px);
      margin-top: 0;
      margin-left: toRems(20px);
      position: absolute;
      text-indent: -999em;
      top: 50%;
      transform: translateY(-50%);
      transition: background 500ms $global-easing,
      opacity 500ms $global-easing;
      width: toRems(10px);
        
      &:checked {
        background-color: alpha-palette(black, 100%);
        opacity: 1;
      }

      &:disabled {
        cursor: pointer;
        &:before {
          border-color: #ccc;
        }
        &:after {
          display: none;
        }
      }
    }

    & label {
      @include form-label-small;
      cursor: pointer;
      padding-left: (
        toRems(30px) + $spacing-base
      ); // Width of pseudo radio + spacing.
      position: relative;
      text-transform: none;

      &:before {
        background: $c-white;
        border-radius: 50%;
        border: toRems(4px) solid $c-black;
        content: ".";
        cursor: pointer;
        height: toRems(30px);
        left: 0;
        margin-left: toRems(10px);
        position: absolute;
        text-indent: -999em;
        top: 50%;
        transform: translateY(-50%);
        width: toRems(30px);
      }
    }
  }

  select {
    @include form-select-element;
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MDAgNTAwIj48cGF0aCBkPSJNMjUwIDUwMGwyNy44LTI3LjggMTk2LjgtMTk2LjgtMjcuOS0yNy44LTE3NyAxNzcuMVYwaC0zOS40djQyNC43bC0xNzctMTc3LjEtMjcuOSAyNy44IDE5Ni44IDE5Ni44eiIvPjwvc3ZnPg==")
      no-repeat right $spacing-xsmall center scroll $c-white; // White-filled chevron-down icon converted to base-64 using http://b64.io/
    background-size: toRems(24px);
    padding: toRems(14px) $spacing-large toRems(14px) $spacing-small;

    /*
    // Disable blue glow
    box-shadow: none !important;
    outline: none !important;
    border-color: inherit !important;
    */

    option {
      color: $c-font;

      &:disabled {
        color: palette(grey, mid-light);
      }
    }
  }

  label.control-label {
    @include form-label;
    color: $c-font;
    display: block;
    margin-bottom: $spacing-xsmall;
  }
}

.modal,
.s-tnew-v7-content {
  textarea {
    @include form-text-input;
    resize: vertical;
  }

  .checkbox {
    padding-bottom: $spacing-base;

    & input {
      left: 0;
      opacity: 0;
      position: absolute;
      -webkit-appearance: none;
      border: none;
      cursor: pointer;
      height: toRems(16px);
      margin-top: 0;
      margin-left: toRems(7px);
      position: absolute;
      text-indent: -999em;
      top: 50%;
      transform: translateY(-50%);
      transition: opacity 500ms $global-easing;
      width: toRems(16px);
        
      &:checked {
        background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MDAgNTAwIj48cGF0aCBkPSJNNDg3LjkgODMuNGMtMTYuMi0xNi4yLTQyLjUtMTYuMi01OC43IDBMMTg0IDMyOC42IDcwLjggMjE1LjNjLTcuOC03LjgtMTguMy0xMi4yLTI5LjMtMTIuMi0xMS4xIDAtMjEuNSA0LjMtMjkuMyAxMi4yQy00IDIzMS41LTQgMjU3LjggMTIuMSAyNzRsMTQyLjYgMTQyLjZjNy44IDcuOCAxOC4zIDEyLjIgMjkuMyAxMi4yIDExLjEgMCAyMS41LTQuMyAyOS4zLTEyLjJsMjc0LjUtMjc0LjVjMTYuMi0xNi4yIDE2LjItNDIuNS4xLTU4Ljd6Ii8+PC9zdmc+") no-repeat center center scroll transparent;
        background-size: toRems(16px);
        opacity: 1;
      }

      &:disabled {
        cursor: pointer;
        &:before {
          border-color: #ccc;
        }
        &:after {
          display: none;
        }
      }
    }

    & label {
      @include form-label-small;
      cursor: pointer;
      padding-left: (
        toRems(30px) + $spacing-base
      ); // Width of pseudo radio + spacing.
      position: relative;
      text-transform: none;

      &:before {
        background: $c-white;
        border: toRems(4px) solid $c-black;
        content: ".";
        cursor: pointer;
        height: toRems(30px);
        left: 0;
        margin-left: 0;
        position: absolute;
        text-indent: -999em;
        top: 50%;
        transform: translateY(-50%);
        width: toRems(30px);
      }
    }
  }
}
