.c-display-switch {
	&__option {
		@include small-copy;
		display: inline-block;
		overflow: hidden;
		position: relative;
		vertical-align: middle;

		&:hover {
			cursor: pointer;
		}

		&:after {
			background-color: palette(grey, mid-mid-light);
			content: "";
			display: block;
			height: 100%;
			position: absolute;
			right: 0;
			top: 0;
			width: 1px;
		}

		&:last-child {
			&:after {
				display: none;
			}
		}

		label {
			display: block;

			&:hover {
				cursor: pointer;
			}
		}

		input[type='radio'] {
			left: -9999em;
			position: absolute;

			&:checked {
				+ .c-display-switch__icon {
					opacity: 1;

					+ .c-display-switch__label {
						opacity: 1;
					}
				}
			}
		}
	}

	&__icon {
		display: inline-block;
		opacity: .5;
		padding: 0 $spacing-xsmall 0 $spacing-base;
		transition: opacity 500ms $global-easing 0s;
		vertical-align: middle;

		.c-display-switch__option:first-child & {
			padding-left: 0;
		}

		svg {
			display: block;
			height: toRems(20px);
			width: toRems(20px);
		}
	}

	&__label {
		@include small-copy;
		display: inline-block;
		opacity: .5;
		padding-right: $spacing-base;
		transition: opacity 500ms $global-easing 0s;
		vertical-align: middle;

		.c-display-switch__option:last-child & {
			padding-right: 0;
		}
	}

	&--align-right {
		text-align: right;
	}
}
