@mixin flexible-row {
	@include breakpoint($bp-medium) {
		align-items: flex-end;
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
	}
}

@mixin flexible-row-item {
	margin-bottom: toRems(55px);

	@include breakpoint($bp-medium) {
		flex: 0 1 auto;
		margin-bottom: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

@mixin flexible-row-content {
	padding: $spacing-xxsmall;

	@include breakpoint($bp-medium) {
		padding: $spacing-medium;
	}
}

.o-flexible-row {
	@include flexible-row;

	&__item {
		@include flexible-row-item;
	}

	&__content {
		@include flexible-row-content;
	}


	/**
	 * Four column  grid
	 */

	&--four-col {
		flex-wrap: wrap;
		justify-content: flex-start;
		// margin: 0 -($spacing-xxsmall);

		@include breakpoint($bp-medium) {
			// margin: 0 -($spacing-xsmall);
		}

		.o-flexible-row__item {
			display: inline-block;
			margin: 0 0 $spacing-xsmall;
			max-width: calc(100% / 2);

			@include breakpoint($bp-small) {
				flex: 0 0 calc(100% / 3);
				max-width: calc(100% / 3);
			}

			@include breakpoint($bp-large) {
				flex: 0 0 calc(100% / 4);
				max-width: calc(100% / 4);
			}
		}
	}


	/**
	 * Vertically centred
	 */

	&--v-center {
		font-size: 0;

		@include breakpoint($bp-medium) {
			align-items: center;
			font-size: $base-font-size;
		}

		.o-flexible-row__item {
			display: inline-block;
			vertical-align: middle;

			@include breakpoint($bp-medium) {
				display: block;
			}
		}
	}


	/**
	 * Extra spacing between items
	 */

	&---larger-spacing {
		margin: 0 -($spacing-xsmall);

		@include breakpoint($bp-small) {
			margin: 0 -($spacing-base);
		}

		@include breakpoint($bp-medium) {
			margin: 0 -($spacing-medium);
		}

		.o-flexible-row__content {
			padding: $spacing-xsmall;

			@include breakpoint($bp-small) {
				padding: $spacing-base;
			}

			@include breakpoint($bp-medium) {
				padding: $spacing-medium;
			}
		}
	}
}



