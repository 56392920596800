.c-featured-content {
	padding-bottom: toRems(65px);
	padding-top: toRems(75px);

	@include breakpoint($bp-small) {
		padding-bottom: $spacing-base;
		padding-top: $spacing-base;
	}

	&__title {
		@include heading-2;

		a {
			@include text-link;

			&,
			&:link,
			&:visited {
				@include heading-2;
				@include text-underline-font-size-3(false);
				line-height: 1.2;
				text-decoration: none;

				@include breakpoint($bp-medium) {
					@include text-underline-font-size-2(false);
				}
			}

			&:hover,
			&:active,
			&:focus {
				@include text-underline-font-size-3(true);
				text-decoration: none;

				@include breakpoint($bp-medium) {
					@include text-underline-font-size-2(true);
				}
			}
		}
	}

	&__main {
		@include body-copy;
		display: none;
		position: relative;

		@include breakpoint($bp-small) {
			display: block;
			padding-top: toRems(110px);
		}

		&:before {
			background-color: $c-black;
			content: "";
			display: none;
			height: toRems(4px);
			left: 0;
			position: absolute;
			top: toRems(50px);
			width: toRems(60px);

			@include breakpoint($bp-small) {
				display: block;
			}
		}
	}


	&--vertical-center {
		@include breakpoint($bp-small) {
			display: table-cell;
			overflow: hidden;
			table-layout: fixed;
			vertical-align: middle;
			width: 100%;
		}
	}

	&--carousel {
		padding-bottom: toRems(30px);

		@include breakpoint($bp-small) {
			display: table-cell;
			overflow: hidden;
			padding-bottom: $spacing-base;
			table-layout: fixed;
			vertical-align: middle;
		}

		@include breakpoint($bp-small) {
			padding-left: (2.7027% * 2);
			width: 100%;
		}

		@include breakpoint($bp-medium) {
			padding-right: 0;
		}

		.slick-slide & {
			opacity: 0;
			padding-left: $spacing-base;
			padding-right: $spacing-base;
			transition: opacity 500ms $global-easing 0s,
						transform 500ms $global-easing 0s;

			@include breakpoint($bp-small) {
				padding-left: (2.7027% * 2);
				padding-right: 0;
			}

			@include breakpoint($bp-medium) {
				padding-right: 0;
			}
		}

		.slick-active & {
			opacity: 1;
			transition-delay: 350ms;

			.c-featured-content__main {
				&:before {
					opacity: 1;
					transform: translateX(0);
					transition-delay: 400ms;
				}
			}

			.c-featured-content__body {
				opacity: 1;
				transform: translateX(0);
				transition-delay: 500ms;
			}
		}

		.c-featured-content__main {
			@include breakpoint($bp-small) {
				max-height: ((($font-size-8 * 4) * 1.56) + toRems(70px)); // $ lines of text * line height + top padding
				overflow: hidden;
				padding-top: toRems(70px);
			}

			@include breakpoint($bp-medium) {
				max-height: ((($font-size-7 * 4) * 1.5) + toRems(50px)); // $ lines of text * line height + top padding
				padding-top: toRems(50px);
			}

			@include breakpoint($bp-large) {
				max-height: ((($font-size-7 * 4) * 1.5) + toRems(110px)); // $ lines of text * line height + top padding
				padding-top: toRems(110px);
			}

			&:before {
				@include breakpoint($bp-small) {
					opacity: 0;
					top: toRems(30px);
					transform: translateX(15%);
					transition: opacity 500ms $global-easing 0s,
								transform 500ms $global-easing 0s;
				}

				@include breakpoint($bp-medium) {
					top: toRems(20px);
				}

				@include breakpoint($bp-large) {
					top: toRems(50px);
				}
			}
		}

		.c-featured-content__body {
			@include breakpoint($bp-small) {
				max-height: ((($font-size-8 * 4) * 1.56) + toRems(70px)); // $ lines of text * line height + top padding
				opacity: 0;
				overflow: hidden;
				transform: translateX(10%);
				transition: opacity 500ms $global-easing 0s,
							transform 500ms $global-easing 0s;
			}
		}
	}

	&--desc-on-mobile {
		.c-featured-content__main {
			display: block;
			padding-top: $spacing-large;

			@include breakpoint($bp-small) {
				padding-top: toRems(110px);
			}
		}
	}
}
