.c-breadcrumb {
	&,
	&:link,
	&:visited {
		color: $c-font;
		display: inline-block;
		padding: $spacing-xsmall $spacing-base $spacing-xsmall toRems(45px);
		position: relative;
		z-index: $z-tile-content;

		&:before,
		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 50%;
			transition: opacity 300ms $global-easing 0s, transform 300ms $global-easing 0s;
			transform: translate(0, -50%);
		}

		&:before {
			background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MDAgNTAwIj48cGF0aCBkPSJNMCAyNTBsMjcuOCAyNy44IDE5Ni44IDE5Ni44IDI3LjgtMjcuOS0xNzcuMS0xNzdINTAwdi0zOS40SDc1LjNsMTc3LjEtMTc3LTI3LjgtMjcuOUwyNy44IDIyMi4yIDAgMjUweiIvPjwvc3ZnPg==")
				no-repeat left center scroll transparent;
			background-size: toRems(24px);
			height: toRems(24px);
			left: 0;
			width: toRems(24px);
			z-index: $z-tile-content + 1;
		}

		&:after {
			background-color: $c-black;
			height: toRems(2px);
			left: toRems(2px);
			transform-origin: right;
			transform: translate(#{toRems(-16px)}, -50%) scaleX(0);
			width: toRems(38px);
			z-index: $z-tile-content + 2;
		}
	}

	&:hover,
	&:active,
	&:focus {
		&:before {
			opacity: 0.3;
		}

		// Check for if supports clip path - Add non IE styles
		@supports (clip-path: inset(0 50% 0 0)) {
			.no-touchevents & {
				&:before {
					opacity: 1;
					transform: translate(#{toRems(-38px)}, -50%);
				}

				&:after {
					transform: translate(#{toRems(-16px)}, -50%) scaleX(1);
				}
			}

			.c-nav-menu & {
				&:before {
					opacity: 1;
					transform: translate(0, -50%);
				}

				&:after {
					transform: translate(0, -50%) scaleX(1);
				}
			}
		}
	}

	&--light {
		&,
		&:link,
		&:visited {
			color: $c-white;

			&:before {
				background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MDAgNTAwIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMCAyNTBsMjcuOCAyNy44IDE5Ni44IDE5Ni44IDI3LjgtMjcuOS0xNzcuMS0xNzdINTAwdi0zOS40SDc1LjNsMTc3LjEtMTc3LTI3LjgtMjcuOUwyNy44IDIyMi4yIDAgMjUweiIvPjwvc3ZnPg==");
			}

			&:after {
				background-color: $c-white;
			}
		}
	}

	&--light-\@small {
		&,
		&:link,
		&:visited {

			@include breakpoint($bp-small) {
				color: $c-white;

				&:before {
					background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MDAgNTAwIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMCAyNTBsMjcuOCAyNy44IDE5Ni44IDE5Ni44IDI3LjgtMjcuOS0xNzcuMS0xNzdINTAwdi0zOS40SDc1LjNsMTc3LjEtMTc3LTI3LjgtMjcuOUwyNy44IDIyMi4yIDAgMjUweiIvPjwvc3ZnPg==");
				}

				&:after {
					background-color: $c-white;
				}
			}
		}
	}

	&--large {
		&,
		&:link,
		&:visited {
			font-size: $font-size-7;
			font-weight: $font-weight-medium;
		}
	}

	&--top-margin {
		margin-top: $spacing-small;

		@include breakpoint($bp-small) {
			margin-top: toRems(25px);
		}
	}
}
