.c-quicklink {
	&,
	&:link,
	&:visited {
		color: $c-white;
		display: block;
		height: 100%;
		padding: toRems(13px) 0;
		position: relative;
		width: 100%;

		.c-quicklink__title {
			color: $c-white;
		}
	}

	&:hover,
	&:active,
	&:focus {
		color: palette(red);

		.c-quicklink__title,
		p {
			color: palette(red);
			transition: color 300ms $global-easing 0s;
		}

		.c-quicklink__icon {
			// Yellow right arrow
			&:before {
				background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MDAgNTAwIj48cGF0aCBmaWxsPSIjZWUzMDMwIiBkPSJNNTAwIDI1MGwtMjcuOC0yNy44TDI3NS40IDI1LjRsLTI3LjggMjcuOSAxNzcuMSAxNzdIMHYzOS40aDQyNC43bC0xNzcuMSAxNzcgMjcuOCAyNy45IDE5Ni44LTE5Ni44TDUwMCAyNTB6Ii8+PC9zdmc+);

				// Check for if supports clip path - Add non IE styles
				@supports (clip-path: inset(0 50% 0 0)) {
					@include breakpoint($bp-medium) {
						transform: translateX(#{toRems(32px)});
					}
				}
			}

			// Check for if supports clip path - Add non IE styles
			@supports (clip-path: inset(0 50% 0 0)) {
				&:after {
					background-color: palette(red);
					

					@include breakpoint($bp-medium) {
						transform:  scaleX(1) translate(0, -50%);
						transition: transform 300ms $global-easing 0s, background-color 300ms $global-easing 0s;
					}
				}
			}
		}
	}

	&__icon {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translate(0, -50%);
		transition: transform 300ms $global-easing 0s;
		height: toRems(24px);
		width: toRems(24px);

		@include breakpoint($bp-medium) {
			bottom: 0;
			top: auto;
			left: 0;
			right: auto;
		}

		// White right arrow
		&:before {
			background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNTAwIDUwMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTAwIDUwMDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+LnN0MHtmaWxsOiNGRkZGRkY7ZW5hYmxlLWJhY2tncm91bmQ6bmV3ICAgIDt9PC9zdHlsZT48cGF0aCBjbGFzcz0ic3QwIiBkPSJNNTAwLDI1MGwtMjcuOC0yNy44TDI3NS40LDI1LjRsLTI3LjgsMjcuOWwxNzcuMSwxNzdIMGwwLDM5LjRoNDI0LjdsLTE3Ny4xLDE3N2wyNy44LDI3LjlsMTk2LjgtMTk2LjhMNTAwLDI1MHoiLz48L3N2Zz4=) no-repeat left center scroll transparent;
			background-size: toRems(24px);
			content: "";
			display: block;
			height: toRems(24px);
			left: 0;
			transition: transform 300ms $global-easing 0s;
			width: toRems(24px);
		}

		// Line extender
		&:after {
			-webkit-backface-visibility: hidden; // Prevent flash of pixelation in Chrome
			background-color: $c-white;
			content: "";
			display: block;
			height: toRems(2px);
			position: absolute;
			top: 50%;
			transform-origin: right;
			transform: scaleX(0) translate(0, -50%);
			transition: transform 300ms $global-easing 0s;
			width: toRems(50px);

			@include breakpoint($bp-medium) {
				transform-origin: left;
				left: 0;
			}
		}
	}

	&__title {
		@include heading-5;
		font-weight: $font-weight-medium;

		@include breakpoint($bp-medium) {
			margin-bottom: $spacing-base;
		}
	}

	// Requires splitting up at different breakpoints
	&__title-part {
		display: block;

		@include breakpoint($bp-large) {
			display: inline-block;
		}
	}

	&__sub-title {
		display: none;

		@include breakpoint($bp-medium) {
			display: block;
		}

		p {
			color: $c-white; // Override TNEW stylesheet which makes all p tags grey (brilliant work)
		}
	}

	&__content {
		line-height: 1.375;
		padding-right: $spacing-large;

		@include breakpoint($bp-medium) {
			padding-bottom: toRems(50px);
			padding-right: 0;
		}
	}
}
