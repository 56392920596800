.o-listing {
	&__item {
		border-bottom: toRems(4px) solid $c-black;
		position: relative;
	}

	&--events {
		display: flex;
		flex-flow: column nowrap;
		margin-bottom: $spacing-medlarge;
		width: auto;

		@include breakpoint($bp-small) {
			flex-flow: row wrap;
			margin-bottom: toRems(70px);
		}

		@include breakpoint($bp-medium) {
			display: block;
			flex-flow: column nowrap;
			margin-bottom: toRems(160px);
		}

		.o-listing__item {
			border-bottom-width: 0;
			margin-bottom: $spacing-base;

			@include breakpoint($bp-small) {
				flex: 0 0 ((5.85586% * 6) + (2.7027% * 5));
				margin: 0 2.7027% 0 0;
				width: (5.85586% * 6) + (2.7027% * 5);
			}

			@include breakpoint($bp-medium) {
				flex-basis: 100%;
				margin-bottom: $spacing-medlarge;
				width: 100%;
			}

			&:nth-child(2n) {
				@include breakpoint($bp-small) {
					margin-right: 0;
				}
			}
		}
	}
}
