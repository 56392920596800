.c-loader {
	animation: loader 1.1s infinite linear;
	border-color: $c-black $c-black $c-black $c-white;
	border-style: solid;
	border-width: toRems(6px);
	font-size: toRems(10px);
	left: 50%;
	margin: 0 auto;
	opacity: 0;
	overflow: hidden;
	pointer-events: none;
	position: absolute;
	text-indent: -9999em;
	top: toRems(40px);
	transform: translate(-50%, 0, 0);
	transition: opacity 500ms $global-easing 0s;
	z-index: $z-content-overlay;

	&,
	&:after {
		border-radius: 50%;
		width: toRems(60px);
		height: toRems(60px);
	}

	.is-loading & {
		opacity: 1;
	}

	&--align-bottom {
		bottom: toRems(25px);
		top: auto;
	}

	&--more {
		.is-loading & {
			opacity: 0;
		}

		.is-loading-more & {
			bottom: toRems(-60px);
			opacity: 1;

			@include breakpoint($bp-medium) {
				bottom: toRems(-100px);
			}
		}
	}
}

@keyframes loader {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
