.c-ajax-content {
	position: relative;
	z-index: $z-tile-background;

	&:before {
		background-color: alpha-palette(white, 95%);
		content: "";
		display: block;
		height: 100%;
		left: 0;
		opacity: 0;
		pointer-events: none;
		position: absolute;
		top: 0;
		transition: opacity 500ms $global-easing 0s;
		width: 100%;
		z-index: $z-content-overlay;
	}

	&__destination {
		transition: height 500ms $global-easing 0s;

		.is-loading & {
			overflow: hidden;
		}
	}

	&.is-loading {
		&:before {
			pointer-events: all;
			opacity: 1;
		}
	}

	&--bottom-spacing {
		margin-bottom: toRems(100px);

		@include breakpoint($bp-large) {
			margin-bottom: toRems(150px);
		}
	}

	&--bottom-loader {
		padding-bottom: toRems(80px);

		@include breakpoint($bp-large) {
			padding-bottom: toRems(100px);
		}

		&:before {
			bottom: 0;
			height: toRems(100px);
			top: auto;
		}
	}
}
