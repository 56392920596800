@mixin tooltip() {
	&[data-tooltip] {
		overflow: visible;
		position: relative;

		&:before,
		&:after {
			bottom: 100%;
			filter: alpha(opacity=0);
			left: 50%;
			opacity: 0;
			pointer-events: none;
			position: absolute;
			transform-origin: top;
			transform: translate(-50%, 0);
			z-index: $z-overlay;
		}

		&:before {
			display: block;
			content: "";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: toRems(16px) toRems(16px) 0 toRems(16px);
			border-color: transparent transparent $c-black transparent;
			transform: translate(-50%, #{toRems(-16px)});
		}

		&:after {
			background: $c-black;
			border-radius: $global-radius;
			color: $c-white;
			content: attr(data-tooltip);
			font-size: $font-size-8;
			font-weight: $font-weight-normal;
			line-height: 1.25;
			margin-bottom: toRems(16px);
			padding: $spacing-base;
			white-space: nowrap;
		}

		&:hover {
			&:before,
			&:after {
				filter: alpha(opacity=100);
				opacity: 1;
				pointer-events: auto;
			}
		}
	}

	&[data-tooltip-pos="down"],
	&[data-tooltip-pos="down"] {
		&:before,
		&:after {
			bottom: auto;
			left: 50%;
			top: 100%;
			transform: translate(-50%, #{toRems(-16px)});
		}

		&:before {
			border-width: 0 toRems(16px) toRems(16px) toRems(16px);
		}

		&:after {
			margin-top: toRems(16px);
		}
	}
}

.c-button-search {
	&,
	&:link,
	&:visited {
		background-color: transparent;
		border: none;
		border-radius: 0;
		cursor: pointer;
		display: block;
		margin: 0;
		opacity: 1;
		padding: $spacing-base;
		position: relative;
		text-align: center;
		vertical-align: middle;
		width: toRems(60px);
		-webkit-appearance: none; // Makes sure iOS doesn't add extra styling to buttons.

		.has-active-search & {
			opacity: 0;
			pointer-events: none;
		}

		svg {
			display: block;
			fill: $c-black;
			height: toRems(20px);
			transition: fill 500ms $global-easing 0s;
			width: toRems(20px);

			.has-active-menu & {
				fill: $c-white;
				transition: fill 300ms $global-easing 200ms;

				@include breakpoint($bp-desktop-nav) {
					fill: $c-black;
				}
			}
		}
	}

	&:hover,
	&:active,
	&:focus {
		background-color: transparent;
	}

	&__label {
		@include visually-hidden;
	}

	/**
	 * Header Search Button
	 * Gets placed absolutely on mobile, and size is adjusted on desktop.
	 */

	&--header {
		padding-bottom: toRems(25px);
		padding-top: toRems(25px);
		position: absolute;
		right: 0;
		top: 0;

		@include breakpoint($bp-desktop-nav) {
			@include tooltip;
			height: $header-height-desktop;
			padding: (($header-height-desktop - toRems(24px)) / 2) toRems(18px);
			position: static;
		}

		// Move the tooltip to the left a bit so it's not off screen
		&[data-tooltip] {
			&:before {
				transform: translate(-50%, #{toRems(-16px)});
			}

			&:after {
				transform: translate(-67.5%, #{toRems(-16px)});
			}

			&:hover {
				&:after {
					transform: translate(-67.5%, #{toRems(-16px)});
				}
			}
		}

		svg {
			@include breakpoint($bp-desktop-nav) {
				height: toRems(24px);
				width: toRems(24px);
			}
		}
	}
}
