.c-form-radio {
	// Hover
	&:hover {
		.c-form-radio__label {
			&:after {
				opacity: .2;
			}
		}
	}

	// Checked
	input[type='radio'] {
		left: -9999em;
		position: absolute;

		&:checked {
			+ .c-form-radio__label {
				&:after {
					color: $c-black;
					opacity: 1;
				}
			}
		}
	}


	// Base / Unchecked
	&__label {
		@include form-label-small;
		cursor: pointer;
		padding-left: (toRems(30px) + $spacing-base); // Width of pseudo radio + spacing.
		position: relative;
		text-transform: none;

		&:before {
			background: $c-white;
			border-radius: 50%;
			border: toRems(4px) solid $c-black;
			content: ".";
			cursor: pointer;
			height: toRems(30px);
			left: 0;
			position: absolute;
			text-indent: -999em;
			top: 50%;
			transform: translateY(-50%);
			width: toRems(30px);
		}

		&:after {
			background-color: alpha-palette(black, 100%);
			border-radius: 50%;
			content: ".";
			cursor: pointer;
			height: toRems(10px);
			left: toRems(10px);
			opacity: 0;
			position: absolute;
			text-indent: -999em;
			top: 50%;
			transform: translateY(-50%);
			transition: background 500ms $global-easing,
						opacity 500ms $global-easing;
			width: toRems(10px);
		}
	}


	/**
	 * Field with Parsley Validation error
	 */

	&.parsley-error {
		.c-form-radio__label {
			color: palette(errors);

			&:before {
				border-color: palette(errors);
			}
		}
	}


	/**
	 * Dark
	 */

	&--dark {
		// Checked
		input[type='radio'] {
			&:checked {
				+ .c-form-radio__label {
					&:after {
						color: $c-white;
					}
				}
			}
		}


		// Base / Unchecked
		.c-form-radio__label {
			color: $c-white;

			&:before {
				background: $c-black;
				border: toRems(4px) solid $c-white;
			}

			&:after {
				background-color: alpha-palette(white, 100%);
			}
		}
	}


	/**
	 * Mid-Dark
	 */

	&--mid-dark {
		// Checked
		input[type='radio'] {
			&:checked {
				+ .c-form-radio__label {
					&:after {
						color: palette(grey, mid-light);
					}
				}
			}
		}


		// Base / Unchecked
		.c-form-radio__label {
			color: palette(grey, mid-light);

			&:before {
				background: $c-black;
				border: toRems(4px) solid palette(grey, mid-light);
			}

			&:after {
				background-color: palette(grey, mid-light);
			}
		}
	}
}
