.o-section {
	@include row;
	display: block;
	padding: $spacing-base 0;


	&--split-content {
		padding-bottom: 0;
		padding-top: 0;

		@include breakpoint($bp-small) {
			padding-bottom: $spacing-medium;
			padding-top: $spacing-medium;
		}

		@include breakpoint($bp-medium) {
			padding-bottom: $spacing-medlarge;
			padding-top: $spacing-medlarge;
		}
	}
}
