/*
TNEW7 account update page
*/

.s-tnew-v7-content {
  .tn-account-interests-page,
  .tn-account-update-page {
    & > .col-xs-12 {
      padding: 0;
    }

    .nav-tabs {
      border-bottom: toRems(4px) solid $c-black;

      & > li {
        display: inline-block;
        margin-bottom: toRems(-4px);
        max-width: 50%;

        &.active {
          & > a {
            background: $c-white;
            border-bottom-color: $c-white;
            color: $c-black;
          }
        }

        & > a {
          background: $c-black;
          border-radius: 0;
          border: toRems(4px) solid $c-black;
          color: $c-white;
          text-decoration: none;

          &.active,
          &:hover,
          &:focus {
            background: $c-white;
            color: $c-black;
          }
        }
      }
    }

    #tn-update-form .row:last-child > div {
      @include breakpoint($bp-small) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        & > a {
          margin-left: $spacing-base;
          margin-top: 0;
        }
      }
    }
  }
}
