.c-contextual-nav {
	background-color: $c-black;
	color: $c-white;
	padding: toRems(55px) $spacing-medium;

	&__header {
		padding: 0 0 toRems(50px);
		position: relative;

		&:after {
			background-color: $c-white;
			bottom: toRems(25px);
			content: "";
			display: block;
			height: toRems(4px);
			left: 0;
			position: absolute;
			width: toRems(60px);
		}
	}

	&__title {
		@include heading-5;
		font-weight: $font-weight-medium;
		margin-bottom: $spacing-xxsmall;
	}

	&__sub-title {
		@include heading-6;
		font-weight: $font-weight-normal;
		margin-bottom: $spacing-medium;
	}

	&__item {
		line-height: 1.65;
		margin-bottom: $spacing-medium;

		&:last-child {
			margin-bottom: 0;
		}

		&.is-active {
			padding-left: toRems(35px);
			position: relative;

			&:before {
				background-color: palette(red);
				content: "";
				display: block;
				height: toRems(1px);
				left: 0;
				position: absolute;
				top: 50%;
				width: toRems(15px);
			}

			a {
				&,
				&:link,
				&:visited {
					@include text-underline-font-size-8-red(false);
					color: palette(red);
					display: inline;
					position: relative;
				}

				&:hover,
				&:active,
				&:focus {
					@include text-underline-font-size-8-red(true);
				}
			}
		}

		a {
			&,
			&:link,
			&:visited {
				@include text-underline-font-size-8-white(false);
				color: $c-white;
				display: inline;
			}

			&:hover,
			&:active,
			&:focus {
				@include text-underline-font-size-8-white(true);
			}
		}
	}
}
