.c-button-search-close {
	&,
	&:link,
	&:visited {
		@include button-text;
		background: transparent;
		border: none;
		border-radius: $global-radius;
		color: $c-white;
		cursor: pointer;
		display: inline-block;
		min-height: toRems(50px);
		max-width: 100%;
		margin: 0;
		opacity: 0;
		padding: toRems(25px) $spacing-base;
		pointer-events: none;
		position: absolute;
		right: 0;
		text-align: center;
		top: 0;
		transition: opacity 400ms $global-easing 0s;
		vertical-align: middle;
		width: toRems(60px);
		-webkit-appearance: none; // Makes sure iOS doesn't add extra styling to buttons.

		.has-active-search & {
			opacity: 1;
			pointer-events: all;
		}

		@include breakpoint($bp-desktop-nav) {
			height: $header-height-desktop;
			padding: (($header-height-desktop - toRems(24px)) / 2) toRems(18px);
			position: relative;
		}

		&:disabled {
			opacity: .7;
		}

		svg {
			display: block;
			fill: palette(blue-grey);
			height: toRems(20px);
			transition: fill 400ms $global-easing 0s;
			width: toRems(20px);
		}
	}

	&:hover,
	&:active,
	&:focus {
		background-color: transparent;

		svg {
			fill: palette(blue-grey);
		}
	}

	&__label {
		@include visually-hidden;
	}
}
