@mixin hero-bg-color {
	background-color: palette(pink);
	content: "";
	display: block;
	height: 100%;
	left: -$spacing-base;
	position: absolute;
	right: 0;
	top: $spacing-medium;
	width: auto;
	z-index: $z-tile-background;

	@include breakpoint($bp-small) {
		left: 0;
		right: 0;
		top: toRems(40px);
	}

	@include breakpoint($bp-medium) {
		left: toRems(-95px);
		right: 0;
		top: $spacing-medium;
	}

	@include breakpoint($bp-xlarge) {
		height: calc(100% - #{toRems(10px)});
		left: toRems(-95px);
		right: toRems(-100px);
	}
}

.c-carousel-hero {
	margin: 0 0 $spacing-medium;
	overflow: hidden;

	@include breakpoint($bp-large) {
		margin-bottom: $spacing-base;
	}

	&__main {
		padding-bottom: $spacing-medium; // to allow room for the progress bar
		position: relative;
		z-index: $z-tile-background;

		@include breakpoint($bp-small) {
			background-color: transparent;
			padding-bottom: 0;
		}

		&:before {
			@include hero-bg-color;
		}
	}

	&__pagination {
		@include caption;
		bottom: -$spacing-small;
		line-height: 0.2;
		opacity: .3;
		position: absolute;
		right: $spacing-small; // Width of next/prev buttons
		z-index: $z-overlay;

		@include breakpoint($bp-small) {
			display: none;
		}
	}

	&__progress {
		background-color: alpha-palette(white, 30%);
		bottom: toRems(50px);
		height: toRems(4px);
		left: $spacing-base;
		position: absolute;
		width: calc(100% - #{$spacing-base * 2});
		z-index: $z-overlay;

		@include breakpoint($bp-small) {
			width: calc(100% - #{$spacing-large * 2});
		}

		@include breakpoint($bp-large) {
			bottom: toRems(40px);
		}

		@include breakpoint($bp-xlarge) {
			width: calc(100% - #{$spacing-large});
		}
	}

	&__progress-bar {
		background-color: alpha-palette(white, 30%);
		height: toRems(4px);
		opacity: 1;
		transform-origin: left center;
		transition: opacity 500ms linear 0s,
			transform 500ms linear 0s;
		width: 100%;

		&.has-been-reset {
			opacity: 0;
			transition: opacity 500ms linear 0s,
				transform 0s linear 500ms;
		}
	}

	.slick-arrow {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: $z-tile-content - 1;

		&.slick-prev {
			left: 0;
		}

		&.slick-next {
			right: 0;
		}
	}

	> .o-grid {
		padding: 0;
	}

	@include breakpoint($bp-small) {
		> .o-grid {
			padding: initial;
		}
	}
}
