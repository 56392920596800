.c-tile-large {
	height: 0;
	padding-top: 107.6923077%;
	position: relative;
	width: 100%;
	z-index: $z-tile-background;

	@include breakpoint($bp-medium) {
		padding-top: 88.3333333%;
	}

	&__main {
		&,
		&:link,
		&:visited {
			color: $c-font;
			display: block;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: $z-tile-background;
		}

		&:hover,
		&:active,
		&:focus {
			color: $c-font;

			.c-tile__content {
				text-decoration: none;
			}

			.no-touchevents & {
				.c-tile-large__info {
					&:before {
						opacity: 0.3;
					}

					// Check for if supports clip path - Add non IE styles
					@supports (clip-path: inset(0 50% 0 0)) {
						&:before {
							opacity: 1;
							transform: translate(#{toRems(38px)}, 0);
						}

						&:after {
							transform: scaleX(1);
						}
					}
				}

				.c-tile-large__title {
					@include breakpoint($bp-medium) {
						transform: translate(0, 0);
					}
				}

				.c-tile-large__description {
					@include breakpoint($bp-medium) {
						max-height: toRems(170px);
						opacity: 1;
						transform: translate(0, 0);
					}
				}

				.c-tile-large__thumbnail:before {
					background-color: alpha-palette(black, 25%);
				}
			}
		}
	}

	&__content {
		background-color: palette(blue-grey);
		bottom: 0;
		height: 66.6666667%; // to make it square
		position: absolute;
		right: 0;
		width: 71.4285714%;
		z-index: $z-tile-content;

		@include breakpoint($bp-medium) {
			height: 73.3752621%; // to make it square
			width: 64.8148148%; // 4 columns
		}
	}

	&__info {
		align-items: stretch;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		height: 100%;
		padding: $spacing-base $spacing-base toRems(65px);
		justify-content: flex-end;

		&:before,
		&:after {
			content: "";
			display: block;
			position: absolute;
			transform: translate(0, 0);
			transition: opacity 300ms $global-easing 0s, transform 300ms $global-easing 0s;
		}

		&:before {
			background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MDAgNTAwIj48cGF0aCBkPSJNNTAwIDI1MGwtMjcuOC0yNy44TDI3NS40IDI1LjRsLTI3LjggMjcuOSAxNzcuMSAxNzdIMHYzOS40aDQyNC43bC0xNzcuMSAxNzcgMjcuOCAyNy45IDE5Ni44LTE5Ni44TDUwMCAyNTB6Ii8+PC9zdmc+")
				no-repeat left center scroll transparent;
			background-size: toRems(24px);
			bottom: $spacing-base;
			height: toRems(24px);
			left: $spacing-base;
			width: toRems(24px);
			z-index: $z-tile-content + 1;
		}

		&:after {
			background-color: $c-black;
			bottom: $spacing-base + toRems(11px);
			height: toRems(2px);
			left: toRems(0px) + $spacing-base;
			transform-origin: left;
			transform: scaleX(0);
			width: toRems(38px);
			z-index: $z-tile-content + 2;
		}
	}

	&__thumbnail {
		background: no-repeat center center scroll $c-black;
		background-size: cover;
		display: block;
		height: 0;
		padding-top: 107.6923077%;
		padding-top: calc(107.6923077% - #{$spacing-base});
		position: relative;
		width: 100%;
		width: calc(100% - #{$spacing-base});
		z-index: $z-tile-thumb;

		@include breakpoint($bp-medium) {
			padding-top: 100% - ((2.7027% + 5.85586%) * 2);
			width: 100% - ((2.7027% + 5.85586%) * 2); // Minus 1 column & 1 gutter (multiplied by two as the card is 50% width)
		}

		&:before {
			background-color: alpha-palette(black, 0%);
			content: "";
			display: block;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			transition: background 500ms $global-easing 0s;
			width: 100%;
			z-index: $z-tile-thumb-overlay;
		}

		img {
			display: none;
		}
	}

	&__title {
		@include tile-title-large;
		color: $c-font;
		font-size: $font-size-5;

		@include breakpoint($bp-medium) {
			font-size: $font-size-3;
			transform: translate(0, toRems(50px));
			transition: transform 400ms $global-easing 0s;
		}
	}

	&__description {
		color: $c-font;
		font-family: $circular;
		font-size: $font-size-8;
		font-weight: $font-weight-normal;
		line-height: 1.38;
		margin: 0;
		max-height: 0;
		opacity: 0;
		overflow: hidden;
		position: relative;

		@include breakpoint($bp-medium) {
			margin: $spacing-medium 0 0;
			padding-bottom: $spacing-base;
			transform: translate(0, toRems(50px));
			transition: opacity 400ms $global-easing 0s,
				max-height 400ms $global-easing 0s,
				transform 400ms $global-easing 0s;
		}

		&:after {
			bottom: 0;
			content: "";
			display: block;
			height: $spacing-base;
			left: 0;
			position: absolute;
			width: 100%;
			background: linear-gradient(
				to bottom,
				transparentize(palette(blue-grey), 1) 0%,
				palette(blue-grey) 100%
			);
		}
	}

	&__subtitle {
		color: $c-white;
		font-size: $font-size-8;
		font-weight: $font-weight-normal;
		line-height: 1.375;
		margin-bottom: 0;
	}
}
