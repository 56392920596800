.c-tile {
	padding-top: 100%;
	position: relative;
	width: 100%;
	z-index: $z-tile-background;

	&__main {
		&,
		&:link,
		&:visited {
			align-content: flex-end;
			align-items: stretch;
			bottom: 0;
			color: $c-white;
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			height: 100%;
			justify-content: flex-end;
			left: 0;
			padding: $spacing-small $spacing-base;
			position: absolute;
			width: 100%;
			z-index: $z-tile-background;

			@include breakpoint($bp-large) {
				padding: $spacing-base $spacing-medium;
			}

			.c-tile__title {
				@include text-underline-font-size-5-white(false);

				@include breakpoint($bp-medium) {
					@include text-underline-font-size-3-white(false);
				}
			}
		}

		&:hover,
		&:active,
		&:focus {
			color: $c-white;

			.c-tile__title {
				@include text-underline-font-size-5-white(true);

				@include breakpoint($bp-medium) {
					@include text-underline-font-size-3-white(true);
				}
			}

			.c-tile__content {
				text-decoration: none;
			}

			.c-tile__thumbnail:before {
				background-color: alpha-palette(black, 60%);
			}
		}
	}

	&__content {
		position: relative;
		z-index: $z-tile-content;
	}

	&__thumbnail {
		background: no-repeat center center scroll $c-black;
		background-size: cover;
		display: block;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: $z-tile-thumb;

		&:before {
			background-color: alpha-palette(black, 25%);
			content: "";
			display: block;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			transition: background 500ms $global-easing 0s;
			width: 100%;
			z-index: $z-tile-thumb-overlay;
		}

		img {
			display: none;
		}
	}

	&__title {
		@include tile-title-large;
		color: $c-white;
		display: inline;
		font-size: 8vw;
		line-height: 1.25;

		@include breakpoint($bp-small) {
			font-size: 4.75vw;
		}

		@include breakpoint($bp-medium) {
			font-size: $font-size-3;
		}
	}

	&__subtitle {
		color: $c-white;
		display: block;
		font-size: $font-size-8;
		font-weight: $font-weight-normal;
		line-height: 1.375;
		margin-bottom: 0;
	}

	/**
	 * Tile with Extra Info
	 * Instead of one large title, it has two levels of titles.
	 */
	&--extra {
		.c-tile__main {
			padding: toRems(25px) $spacing-medium;

			&,
			&:link,
			&:visited {
				.c-tile__title {
					@include text-underline-font-size-7-white(false);

					@include breakpoint($bp-medium) {
						@include text-underline-font-size-7-white(false);
					}
				}
			}

			&:hover,
			&:active,
			&:focus {
				.c-tile__title {
					@include text-underline-font-size-7-white(false);

					@include breakpoint($bp-medium) {
						@include text-underline-font-size-7-white(false);
					}
				}
			}
		}

		.c-tile__content {
			max-width: toRems(185px);
		}

		.c-tile__title {
			@include tile-title-small;
			margin-bottom: $spacing-xsmall;
		}
	}

	&--outline {
		.c-tile__title,
		.c-tile__subtitle {
			color: $c-black;
		}

		.c-tile__main {
			&,
			&:link,
			&:visited {
				border: toRems(4px) solid $c-black;

				.c-tile__title {
					@include text-underline-font-size-5(false);

					@include breakpoint($bp-medium) {
						@include text-underline-font-size-3(false);
					}
				}
			}

			&:hover,
			&:active,
			&:focus {
				.c-tile__title {
					@include text-underline-font-size-5(true);

					@include breakpoint($bp-medium) {
						@include text-underline-font-size-3(true);
					}
				}
			}
		}
	}
}
