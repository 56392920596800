/*
TNEW7 checkout page
*/

.s-tnew-v7-content {
  .tn-patron-shipping-information {
    margin-top: $spacing-large;
    @include breakpoint($bp-large) {
      margin-top: 0;
    }
  }

  .tn-patron-billing-information,
  .tn-patron-shipping-information {
    > div {
      float: none;
      @include breakpoint($bp-large) {
        float: left;
      }
    }
  }

  .tn-donation-ask-component {
    max-width: 600px;
    @include breakpoint($bp-large) {
      width: 50%;
    }
    div {
      width: 100%;
    }
  }

  .tn-donationAskCheckout-component {
    #OtherRadio input[type='number'] {
      left: 0;
      position: relative;
      display: inline;
      margin-left: 50px;
      padding: 0.6875rem 0.9375rem;
      font-size: inherit;
      line-height: 1em !important;

      @include breakpoint($bp-small) {
        margin-left: 14px;
      }
    }
  }
}
