/**
 * Generated SVG Sprite Sizes
 *
 * DO NOT EDIT THIS FILE IT IS FOR REFERENCE ONLY
 *
 * This sprites Sass file is generated by Gulp
 * To add more icons to the svg spritesheets:
 *    1. Add svgs to "images/svg/sprites/"
 *    2. Run `gulp svg-sprites` in your terminal
 *
 */

%svg-icon {
	display: inline-block;
}

.svg-arrow-down {
	@extend %svg-icon;
	height: 500px;
	width: 500px;
}

.svg-arrow-left {
	@extend %svg-icon;
	height: 500px;
	width: 500px;
}

.svg-arrow-right {
	@extend %svg-icon;
	height: 500px;
	width: 500px;
}

.svg-arrow-up {
	@extend %svg-icon;
	height: 500px;
	width: 500px;
}

.svg-calendar {
	@extend %svg-icon;
	height: 500px;
	width: 500px;
}

.svg-chevron-down {
	@extend %svg-icon;
	height: 500px;
	width: 500px;
}

.svg-chevron-left {
	@extend %svg-icon;
	height: 500px;
	width: 500px;
}

.svg-chevron-right {
	@extend %svg-icon;
	height: 500px;
	width: 500px;
}

.svg-chevron-up {
	@extend %svg-icon;
	height: 500px;
	width: 500px;
}

.svg-close {
	@extend %svg-icon;
	height: 500px;
	width: 500px;
}

.svg-donate {
	@extend %svg-icon;
	height: 500px;
	width: 500px;
}

.svg-envelope {
	@extend %svg-icon;
	height: 500px;
	width: 500px;
}

.svg-facebook {
	@extend %svg-icon;
	height: 500px;
	width: 500px;
}

.svg-google-plus {
	@extend %svg-icon;
	height: 500px;
	width: 500px;
}

.svg-instagram {
	@extend %svg-icon;
	height: 500px;
	width: 500px;
}

.svg-linkedin {
	@extend %svg-icon;
	height: 500px;
	width: 500px;
}

.svg-list {
	@extend %svg-icon;
	height: 500px;
	width: 500px;
}

.svg-magnify {
	@extend %svg-icon;
	height: 500px;
	width: 500px;
}

.svg-magnify-small {
	@extend %svg-icon;
	height: 500px;
	width: 500px;
}

.svg-messenger {
	@extend %svg-icon;
	height: 500px;
	width: 500px;
}

.svg-more {
	@extend %svg-icon;
	height: 500px;
	width: 500px;
}

.svg-pinterest {
	@extend %svg-icon;
	height: 500px;
	width: 500px;
}

.svg-settings {
	@extend %svg-icon;
	height: 1420px;
	width: 1420px;
}

.svg-share {
	@extend %svg-icon;
	height: 500px;
	width: 500px;
}

.svg-tick {
	@extend %svg-icon;
	height: 500px;
	width: 500px;
}

.svg-ticket {
	@extend %svg-icon;
	height: 500px;
	width: 500px;
}

.svg-twitter {
	@extend %svg-icon;
	height: 500px;
	width: 500px;
}

.svg-user {
	@extend %svg-icon;
	height: 500px;
	width: 500px;
}

.svg-vimeo {
	@extend %svg-icon;
	height: 500px;
	width: 500px;
}

.svg-youtube {
	@extend %svg-icon;
	height: 500px;
	width: 500px;
}

