.o-narrow {
	margin: 0 auto;
	max-width: toRems(730px);
	padding: 0;
	position: relative;

	@include breakpoint($bp-medium) {
		@include span-columns(8);
		max-width: none;
	}

	@include breakpoint($bp-xlarge) {
		@include span-columns(8);
	}

	&--center {
		float: none;
		margin: $spacing-large auto 0;

		@include breakpoint($bp-medium) {
			margin-top: toRems(80px);
		}

		@include breakpoint($bp-large) {
			margin-top: toRems(130px);
		}

		&:last-child {
			margin-left: auto;
			margin-right: auto;
		}
  }
  
	&--small-v-spacing {
		margin-bottom: $spacing-medlarge;
		margin-top: $spacing-medlarge;

		@include breakpoint($bp-medium) {
			margin-bottom: $spacing-medlarge;
			margin-top: $spacing-medlarge;
		}

		@include breakpoint($bp-large) {
			margin-bottom: $spacing-medlarge;
			margin-top: $spacing-medlarge;
		}
	}

	&--border-top {
		margin-bottom: $spacing-xlarge;
		margin-top: $spacing-medlarge;
		padding-top: $spacing-medlarge;
		position: relative;

		&:before {
			background-color: $c-black;
			content: "";
			display: block;
			height: toRems(4px);
			left: 0;
			margin-left: -$spacing-base;
			position: absolute;
			top: 0;
			width: 100%;
			width: calc(100% + #{$spacing-base * 2});

			@include breakpoint($bp-medium) {
				margin-left: -(((5.85586% + 2.7027%) * 2) / .657657658); // column width + column gutter width / divided by ratio of narrow grid to normal grid size
				margin-right: -(((5.85586% + 2.7027%) * 2) / .657657658);
				width: calc(
					100% + #{(((5.85586% + 2.7027%) * 2) / .657657658) * 2}
				);
			}
		}
	}
}
