@mixin no-breadcrumbs-title-adjust() {
	.c-hero-title-container {
		@supports (clip-path: inset(0 50% 0 0)) {
			&:before {
				@include breakpoint($bp-small) {
					top: calc(50% - #{toRems(22.5px)});
				}
			}
		}
	}
}

@mixin c-hero-wrapper-pseudo {
	background-color: palette(yellow);
	content: "";
	display: block;
	height: 100%;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: $z-tile-thumb;
}

.c-hero {
	opacity: 1;
	overflow: hidden;

	@include breakpoint($bp-small) {
		margin-bottom: toRems(40px);
		overflow: hidden;
		overflow-x: hidden;
		overflow-y: visible;
	}

	@include breakpoint($bp-medium) {
		margin-bottom: toRems(45px);
	}

	&__wrapper {
		background-color: palette(yellow);
		position: relative;
		z-index: $z-tile-background;

		@include breakpoint($bp-small) {
      min-height: toRems(290px);
		}

		&:before {
			@include c-hero-wrapper-pseudo;
			left: toRems(-20px);

			@include breakpoint($bp-small) {
				left: -100%;
			}
		}

		&:after {
			@include c-hero-wrapper-pseudo;
			right: 0;

			@include breakpoint($bp-small) {
				right: 0;
			}

			@include breakpoint($bp-medium) {
				right: 0;
			}

			@include breakpoint($bp-xlarge) {
				right: toRems(-125px);
			}
		}
	}

	.o-grid {
		opacity: 1;
	}

	&__content {
		align-items: flex-start;
		color: $c-font;
		display: flex;
		flex-flow: column nowrap;
		justify-content: flex-start;
		padding: $spacing-large $spacing-medium $spacing-large 0;
		padding-top: toRems(75px);
		position: relative;
		z-index: $z-tile-content;

		@include breakpoint($bp-small) {
			justify-content: center;
			padding-bottom: $spacing-medlarge;
			padding-right: toRems(95px);
			padding-top: toRems(75px);
		}

		@include breakpoint($bp-medium) {
			max-width: 69%; // approx 8 column width
			padding-bottom: $spacing-large;
			padding-right: $spacing-medium;
		}

		@include breakpoint($bp-large) {
			padding-bottom: toRems(90px);
		}
	}

	&__title {
		@include hero-title;
		letter-spacing: -.01em; // Gets iOS type matching designs more closely
		margin-top: $spacing-xsmall;
		position: relative;
		width: 100%; // This needs to be set for IE 11 so text wraps

		@include breakpoint($bp-small) {
			font-size: $font-size-2;
		}

		@include breakpoint($bp-large) {
			letter-spacing: 0;
		}

		a {
			&,
			&:link,
			&:visited {
				color: $c-black;
				text-decoration: none;
			}

			&:hover,
			&:active,
			&:focus {
				text-decoration: underline;
			}
		}
	}

	&__title-container {
		display: table;
		height: 100%;
		overflow: visible;

		@include breakpoint($bp-medium) {
			max-height: toRems(280px);
		}

		@include breakpoint($bp-large) {
			max-height: toRems(300px);
		}

		// If the element is a link element
		@at-root {
			a#{&} {
				&,
				&:link,
				&:visited {
					color: $c-black;
					text-decoration: none;

					.c-hero__title-text {
						@include text-underline-font-size-3(false);
						display: inline;
						line-height: 1.3;
					}

					// Only apply these styles if clip-path is supported
					@supports (clip-path: inset(0 50% 0 0)) {
						&:before,
						&:after {
							text-decoration: none;
						}
					}
				}

				&:hover,
				&:active,
				&:focus {
					text-decoration: none;

					.c-hero__title-text {
						display: inline;
						@include text-underline-font-size-3(true);
					}

					// Only apply these styles if clip-path is supported
					@supports (clip-path: inset(0 50% 0 0)) {
						&:before,
						&:after {
							text-decoration: underline;
						}
					}
				}
			}
		}

		// Only apply these styles if clip-path is supported
		@supports (clip-path: inset(0 50% 0 0)) {
			&:before,
			&:after {
				@include hero-title;
				padding: 0;

				@include breakpoint($bp-small) {
					content: attr(data-title);
					font-size: inherit;
					left: 0;
					padding: toRems(10px) 25% toRems(10px) 0; // Bottom padding is cause the font line-height sucks and the bottom of descenders get trimmed if clipping path is used
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					width: 100%;
					z-index: $z-tile-content + 2;
				}

				@include breakpoint($bp-medium) {
					padding-right: 25%;
				}

				@include breakpoint($bp-large) {
					font-size: inherit;
				}
			}

			&:before {
				@include breakpoint($bp-small) {
					clip-path: inset(0 50% 0 0);
					color: $c-font;
				}
			}

			&:after {
				@include breakpoint($bp-small) {
					clip-path: inset(0 0 0 50%);
					color: $c-white;
				}
			}
		}
	}

	&__title-text {
		display: table-cell;
		padding: 0;
		vertical-align: middle;

		@supports (clip-path: inset(0 50% 0 0)) {
			@include breakpoint($bp-small) {
				opacity: 0;
			}

			@include breakpoint($bp-large) {
				max-height: none;
			}
		}
	}

	&__label {
		@include hero-label;
		flex: 0 0 auto;
		height: toRems(20px);
		padding-right: $spacing-large;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%;

		@include breakpoint($bp-small) {
			margin-bottom: toRems(40px);
		}

		@include breakpoint($bp-large) {
			margin-bottom: toRems(60px);
		}
	}

	&__subtitle {
		@include hero-subtitle;
		min-height: toRems(25px);
		width: 100%;

		@include breakpoint($bp-small) {
			margin-bottom: toRems(40px);
		}

		@include breakpoint($bp-medium) {
			margin-bottom: toRems(60px);
		}
	}

	&__breadcrumb {
		left: 0;
		margin: 0;
		position: absolute;
		top: $spacing-base;
	}

	&__blurb {
		@include hero-blurb;
    width: 100%; // This needs to be set for IE 11 so text wraps

		@include breakpoint($bp-small) {
			padding-right: $spacing-large;
		}
	}


	&__venue {
		display: block;

		@include breakpoint($bp-large) {
			display: inline;
		}

		&:after {
			@include breakpoint($bp-large) {
				content: "/";
				margin: 0 toRems(5px) 0 toRems(10px);
			}
		}
	}

	&__date {
		display: block;

		@include breakpoint($bp-large) {
			display: inline;
		}
	}

	&__cta {
		&,
		&:link,
		&:visited {
			@include hero-subtitle;
			@include text-underline-font-size-7(true);
			color: $c-black;
			margin-bottom: toRems(30px);

			@include breakpoint($bp-small) {
				margin-bottom: 0;
				margin-top: toRems(20px);
			}

			@include breakpoint($bp-medium) {
				@include text-underline-font-size-5(true);
			}
		}

		&:hover,
		&:active,
		&:focus {
			@include text-underline-font-size-7(false);

			@include breakpoint($bp-medium) {
				@include text-underline-font-size-5(false);
			}
		}
	}

	/**
	 * Hero with No Breadcrumbs -
	 * Positioning of title is adjusted slighly.
	 */

	&--no-breadcrumbs {
		.c-hero__wrapper {
			align-items: center;
			display: flex;

			// IE fix to make vertical align center
			@include breakpoint($bp-small) {
				height: 200px;
				min-height: 290px;
			}
		}

		.c-hero__content {
			display: block;
			padding-bottom: toRems(40px);
			padding-top: toRems(40px);
		}

		.c-hero__title {
			margin-top: 0;
			padding-bottom: $spacing-xsmall;

			@include breakpoint($bp-small) {
				margin-bottom: $spacing-small;
				padding-bottom: 0;
				padding-top: 0;
			}
		}
	}


	/**
	 * Hero with Blurb -
	 * Bottom padding of content is adjusted
	 * Positioning of title is adjusted slighly.
	 */

	&--blurb {
    @include breakpoint($bp-medium) {
      margin-bottom: toRems(90px);
    }

		.c-hero__content {
			padding-bottom: toRems(40px);

			@include breakpoint($bp-small) {
				padding-bottom: toRems(30px);
      }
		}

		.c-hero__title {
			padding-bottom: $spacing-xsmall;

			@include breakpoint($bp-small) {
				margin-bottom: $spacing-small;
				padding-bottom: 0;
				padding-top: 0;
			}
		}
	}

		/**
	 * Hero pink -
	 * Hero background colour changed to pink
	 */

	 &--pink {
		.c-hero__wrapper {
			background-color: palette(pink);

			&:before,
			&:after {
				background-color: palette(pink);
			}
		}
	}
}
