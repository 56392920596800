.c-figure {
	// Break out of grid (one column on left and right wider than grid) if in narrow wrapper
	.o-narrow &,
	.o-content-sidebar__main & {
		margin-left: -$spacing-base;
		margin-right: -$spacing-base;

		@include breakpoint($bp-medium) {
			margin-left: -((5.85586% + 2.7027%) / .657657658); // column width + column gutter width / divided by ratio of narrow grid to normal grid size
			margin-right: -((5.85586% + 2.7027%) / .657657658);
		}
	}

	&__wrapper {
		position: relative;

		&.is-playing {
			.c-figure__iframe {
				opacity: 1;
				transform: scale(1);
				visibility: visible;
			}
		}
	}

	&__image {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 0;
		margin: 0 auto;
		max-width: 100%;
		overflow: hidden;
		padding-bottom: 66.6667%; // 3:2 Ratio
		position: relative;
		width: 100%;

		img {
			display: none;
		}
	}

	&__caption {
		&,
		.s-cms-content & {
			@include description;
			background-color: transparent;
			color: palette(grey, mid-mid-light);
			font-size: $font-small;
			line-height: 1.875;
			padding: $spacing-small 0;

			@include breakpoint($bp-small) {
				font-size: $font-size-8;
			}
		}

		.o-narrow &,
		.o-content-sidebar__main & {
			// Offset the negative margin to keep the caption text in the grid
			padding-left: $spacing-base;
			padding-right: $spacing-base;

			@include breakpoint($bp-medium) {
				padding-left: (
					 (5.85586% + 2.7027%) * 1.206521739
				); // column width + column gutter width / divided by ratio of narrow grid to normal grid size
				padding-right: ((5.85586% + 2.7027%) * 1.206521739);
			}
		}
	}

	&--logo {
		display: block;
		max-width: toRems(150px);

		.o-narrow &,
		.o-content-sidebar__main & {
			margin-left: 0;
			margin-right: 0;
			
			@include breakpoint($bp-medium) {
				margin-left: 0;
				margin-right: 0;
			}
		}
	}
}
