@mixin form-text-input() {
	@include form-field-base;
	padding: toRems(11px) $spacing-small;
	transition: border 300ms $global-easing 0s;
	width: 100%;

	@include placeholder {
		color: palette(grey, mid-mid-light);
	}

	&:focus {
		outline: none;
	}

	&:disabled {
		background-color: alpha-palette(black, 15%);
		cursor: not-allowed;
		opacity: .5;
	}

	// Turn off number spinners
	&[type="number"] {
		-moz-appearance: textfield;

		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}

	// Overwrite normalize box-sizing
	&[type="search"] {
		box-sizing: border-box;
	}

	// Make sure text is left-aligned
	html:not([dir="rtl"]) & {
		text-align: left;
	}
}

@mixin form-text() {
	position: relative;
	z-index: $z-tile-background;

	// Exclamation point (displayed on validation error)
	&:after {
		content: "!";
		color: palette(errors);
		display: block;
		font-family: $circular;
		font-size: toRems(28px);
		font-weight: $font-weight-normal;
		height: toRems(60px);
		line-height: toRems(60px);
		opacity: 0;
		pointer-events: none;
		position: absolute;
		right: toRems(-20px);
		top: toRems(40px);
		transition: opacity 300ms $global-easing 0s;
		z-index: $z-tile-content;
	}

	input,
	textarea {
		@include form-text-input;
	}

	textarea {
		resize: vertical;
	}

	/**
	 * Validation Error State
	 */

	&.parsley-error {
		&:after {
			opacity: 1;
		}

		input,
		textarea {
			border-color: palette(errors);
		}
	}
}

.c-form-text {
	@include form-text;

	/**
	 * Dark
	 */

	&--dark {
		input,
		textarea {
			background-color: $c-black;
			border-color: $c-white;
			color: $c-white;

			@include placeholder {
				color: palette(grey, mid-mid-light);
			}

			&:disabled {
				background-color: alpha-palette(white, 20%);
			}
		}
	}

	/**
	 * Mid-Dark
	 */

	&--mid-dark {
		input,
		textarea {
			background-color: $c-black;
			border-color: palette(grey, mid-light);
			color: palette(grey, mid-light);

			@include placeholder {
				color: palette(grey, mid-mid-light);
			}

			&:disabled {
				background-color: alpha-palette(white, 20%);
			}
		}
	}
}
