@mixin form-error() {
	@include span-columns(10.286);
	color: palette(errors);
	display: block;
	font-family: $circular;
	font-size: $font-size-8;
	font-weight: $font-weight-normal;
	line-height: 1.25;
	margin: 0;
	text-align: left;
}

.c-form-error {
	@include form-error;

	li {
		padding: $spacing-xxsmall 0 $spacing-xsmall;
	}
}

// Parsley field validation errors
.parsley-errors-list {
	margin-top: $spacing-xsmall;

	li {
		@include form-error;
		margin-bottom: $spacing-base;

		&:last-child {
			margin-bottom: 0;
		}
	}
}
